import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateFavorites } from "../functionsBackEnd/functions";

export const useChangeFavorites = ({ user }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newFavorites) => {
      updateFavorites({ user, newFavorites });
    },
    onSuccess: (savedFavorites, newFavorites) => {
      queryClient.setQueryData(["favorites"], () => newFavorites);
    },

    onError: (error, newFavorite, context) => {
      if (!context) return;
      queryClient.setQueryData(["favorites"], () => context.previousFavorites);
    },
    onMutate: (newFavorites) => {
      queryClient.setQueryData(["favorites"], () => newFavorites);
    },
  });
};
