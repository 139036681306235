import React from "react";
import { MathJax } from "better-react-mathjax";
const EarningsYield = () => {
  return (
    <div className="screener-article">
      <h1>Earnings Yield</h1>

      <p>
        The Earnings Yield is a measure of how much a company earns relative to its Enterprise Value. It is defined as the Earnings Before Interest
        and Tax divided by the Enterprise Value.
      </p>

      <h2>Formula of the Earning Yield</h2>
      <p className="text-xl mb-10">
        <MathJax>{"\\( \\text{Earning Yield(%)}=\\frac{\\text{EBIT}}{\\text{EV}} \\)"}</MathJax>
      </p>
      <h2>Interpretation of Earnings Yield</h2>

      <p>
        As defined in the Little Book that Beats the Market, the earnings yield compares the profit generated with the market's valuation of the
        company. It is defined as operating profit divided by enterprise value. Many refer to it as the EBIT/EV (Earnings before interest and tax /
        Enterprise Value). You can read more about this here.
      </p>
      <p>
        This penalises companies that carry a lot of debt and little cash, and rewards firms with a lot of cash - something that is not reflected in a
        simple PE Ratio.
      </p>
      <p>
        In the case of negative Enterprise Value stocks, we replace the Enterprise Value with a 1 to avoid having a negative denominator (the
        alternative would be to print n/a but these stocks are obviously good from an Earnings Yield perspective).
      </p>
    </div>
  );
};

export default EarningsYield;
