import React from "react";
import { Link } from "react-router-dom";

const DataSource = () => {
  return (
    <div className="screener-article">
      <h1>Data Source</h1>
      <p>
        To work, GuruScreener need data about company and stock market. All of these data are the base of our solution, that's why the quality and
        integrity of data are a priority for us.
      </p>
      <h2>Which source do we use ?</h2>
      <p>We use third party supplier to provide raw data and prices (such as EOD, Morningstar and Yahoo)</p>

      <h2>Is data always up-to-date ?</h2>
      <p>
        Generally speaking, for companies that report their financials to the SEC, our data vendor will provide the data to us within 48 business
        hours after the companies publicly released their financial reports on SEC. For other companies that do not report to the SEC, the financial
        data is usually provided to us within 10 business days.
      </p>

      <h2>Integrity of the data</h2>
      <p>
        At first, all the data are filled by human. The third party supplier read press release, financial statement to create database. But the
        financial statements are not always the same and it could contain some errors. GuruScreener apply extra filter to verify the integrity of the
        data. Our filter is conservative, in other words, if we have any doubt, we don't include the company in our screener.
      </p>

      <p>
        Sometimes the data is fine, but the interpretation could be wrong. For example, the financial data for an ADR company uses a different
        currency than the market price. currency than the market price. It could generate incorrect ratios such as{" "}
        <Link to={`${process.env.PUBLIC_URL}/docs/pe-ratio`} className="link-content">
          PE Ratios
        </Link>
      </p>
    </div>
  );
};

export default DataSource;
