export const plans = [
  {
    name: "Free",
    desc: "Try it for free.",
    price: 0,
    realPlan: false,
    isMostPop: false,
    features: [
      "People and Guru Screener",
      "Limited View of Guru Screener",
      "Cover 100% of US Companies",
      // "Discover lot of Guru Screener",
      "100% Quality Stocks",
      "Favorites and Filters Screeners",
    ],
    isBonus: false,
  },
  {
    name: "Private Investor",
    desc: "Improve your personal portfolio.",
    price: 69,
    realPlan: true,
    isMostPop: true,
    features: [
      "Life-time Access",

      "Everything available for free AND",
      "See the full list of Stocks",
      // "Access to Specific Screener",
      "Fresh Data from the Market",
      "Access to the 'When to Sell' Section ",
      "Add +20 Financial Ratio to Screener",
      "Exclusive Access to New Screeners",
    ],
    isBonus: true,
    isGuarantee: true,
  },
  {
    name: "Enterprise",
    desc: "For intensive use.",
    price: "Custom",
    realPlan: false,
    isMostPop: false,
    features: [
      "Everything available for Private Investor",
      "Custom Screeners tailored to your Needs",
      "Screeners for Corporate Investments ",
      "OTC Screeners",
      "Meet our Financial Experts",
      "Financial Database via API",
      "Custom Integration in MT4 / MT5",
      "Live Mode",
    ],
    isBonus: false,
    isGuarantee: true,
  },
];
