import React from "react";

const Privacy = () => {
  return (
    <div className="card">
      <div className="card-body">
        <h1 className="text-xl ">Privacy</h1>

        <div>
          <p>
            Thank you for visiting{" "}
            <a href="https://guruscreener.io/">
              <span>https://guruscreener.io</span>
            </a>
            <span>
              , one of the Guru Screener websites, or using one of the apps or services, (together the "Site") operated by Guru Screener. We provide
              this privacy policy ("
            </span>
            <b>Privacy Policy</b>
            <span>
              ") explaining our online information practices and the choices you can make about the way your information is collected and used at the
              Site.{" "}
            </span>
          </p>

          <h4 className="text-xl mt-5">1. Introduction</h4>
          <p>
            Guru Screener is a crowd-sourced content service website for financial markets. Our services empower investors to make the best investing
            decisions by leveraging our independent and balanced stock research, fundamental analysis tools, reliable news and actionable market data.
          </p>
          <p>
            For more information about us, please see the “About Guru Screener” section of our Site at{" "}
            <a href="https://guruscreener.io/">
              <span>https://guruscreener.io/</span>
            </a>
            <span>.</span>
          </p>

          <h4 className="text-xl mt-5">2. Personal Information Guru Screener collects</h4>

          <p>
            When you visit our Site or register for our products and services (including as a subscriber or a contributor), Guru Screener collects
            personal information about you. We use this personal information for the business purpose of providing our products and services, as well
            as marketing our products and services to new and potential customers. This includes providing you with personalized recommendations and
            customized content on our Site.
          </p>
          <p>The categories of personal information we collect from users of and visitors to our Site are:</p>

          <ul>
            <li>
              <span>Identifiers</span>
              <span>
                : This includes, for example, your name, nickname, username, email address,, phone number, postal address (including ZIP code),
                Internet Protocol address, and cookies or other identifiers which we assigned to uniquely identify you or which others assigned to
                uniquely identify you. We obtain this information when you provide it to us or otherwise automatically in the course of your
                interactions with our products and services.
              </span>
            </li>
            <li>
              <span>Demographic information / Characteristics of protected classifications under California or federal law:</span>
              <span>
                {" "}
                This includes information such as age (including date of birth). We may obtain this information when you provide it to us, for
                identification purposes upon registering as a contributor
              </span>
              <span>.</span>
            </li>
            <li>
              <span>Commercial Information</span>
              <span>: This includes </span>
              <span>
                Stocks, ETFs and other securities in your investment portfolio, your trading pattern, list of authors you follow, investment style
                preference, broker information and payment information (including bank account details,
              </span>
              <span> to enable payments to you and from you when you register as a subscriber or contributors)</span>
              <span>. </span>
              <span>
                We obtain this information directly from you or otherwise automatically in the course of your interactions with our products and
                services.
              </span>
            </li>
            <li>
              <span>Internet or other electronic network activity information</span>
              <span>: This includes, for example, </span>
              <span>
                device screen size, device type (unique device identifiers), browser information, your interactions with our Site and preferred
                language used to display our Site
              </span>
              <span>. We obtain this information automatically in the course of your interactions with our products and services.</span>
            </li>
            <li>
              <span>Geo-location data:</span>
              <span>
                {" "}
                This includes your country location (based on your IP address). We obtain this information when you provide it to us or otherwise
                automatically in the course of your interactions with our products and services.
              </span>
            </li>
            <li>
              <span>Audio, Video and other Electronic Data</span>
              <span>: This includes audio, electronic, visual or similar information such as</span>{" "}
              <span>
                chat and any interaction that you may have with us (such as for customer service purposes) as well as your profile photo. It also
                includes any information you submit in forums, chat or message boards, and feedback or testimonials you provide about our products and
                services. We obtain this information when you provide it to us or otherwise automatically in the course of your interactions with our
                products and services.
              </span>
            </li>
            <li>
              <span>Professional or employment-related information</span>
              <span>: This includes your job title, education information, CFA status, and other biographical information including </span>
              <span>professional interests and areas of expertise.</span>
              <span> We obtain this information</span> <span>when you provide it to us upon registering as a subscriber or contributor.</span>
            </li>
            <li>
              <span>Profiles and Inferences</span>
              <span>
                : This includes inferences drawn from any of the information identified above to create a profile reflecting preferences,
                characteristics, predispositions, behaviour, and attitudes. We obtain this information automatically in the course of your
                interactions with our Services.
              </span>
            </li>
          </ul>
          <p>The sources from which we collect personal information are:</p>

          <ul>
            <li>
              <span>
                Directly from you: For example, when you create an account, make a purchase, apply for or accept an employment position with us, etc.
              </span>
            </li>
            <li>
              <span>Directly and indirectly from an activity on our Site and other services. </span>
            </li>
            <li>
              <span>
                Service providers who provide services on our behalf such as those used to fulfil orders, process your payments and requests, verify
                your information, monitor activity on our products and services, provide analysis and analytics, maintain databases, administer and
                monitor emails and marketing, administer and send mobile messages, serve ads on this and other services, and provide consulting
                services.{" "}
              </span>
            </li>
            <li>
              <span>Third party data providers. </span>
            </li>
          </ul>
          <p>
            From time to time, we may also receive personal information about you from third party sources but only where these third parties have
            confirmed to us that they either have your consent or are otherwise legally permitted or required to disclose your personal information to
            us. We use this information to maintain and improve the accuracy of the records we hold about you and to send you personalized content,
            such us showing you articles and news about your preferred stocks, or articles by authors you follow. The personal information we collect
            includes:{" "}
          </p>

          <ul>
            <li>
              <span>Contact information</span>
            </li>
            <li>
              <span>Investment style and market preferences</span>
            </li>
          </ul>

          <h4 className="text-xl mt-5">3. How Guru Screener Uses Personal Information </h4>

          <p>
            We use personal information collected through our products and services, both individually and combined together, for purposes described
            in this Privacy Policy or disclosed to you in connection with our products and services. For example, we may use your information to:
          </p>

          <ul>
            <li>
              <span>Create your account</span>
            </li>
            <li>
              <span>Provide and deliver products and services you request</span>
            </li>
            <li>
              <span>Send you service-related information updates, alerts, and support and administrative messages</span>
            </li>
            <li>
              <span>Send you marketing communications and personalized content</span>
              <span> such us showing you articles and news about your preferred stocks, or articles by authors you follow</span>
            </li>
            <li>
              <span>Respond to your enquiries</span>
            </li>
            <li>
              <span>Enable you to send and receive messages to other registered users and contributors, and post comments</span>
            </li>
            <li>
              <span>Carry out analytics and to improve the quality and relevance of our Site</span>
            </li>
            <li>
              <span>Survey your opinions through surveys or questionnaires</span>
            </li>
            <li>
              <span>Serve and measure the effectiveness of advertising</span>
            </li>
            <li>
              <span>Protect the integrity of our products and services and enforce our </span>
              <a href="https://guruscreener.io/page/terms-of-use">
                <span>Terms of Use</span>
              </a>
              <span>
                {" "}
                and other applicable terms, including to detect and prevent fraud and unauthorized activities, to secure our systems and the services,
                and prevent activities that may violate our policies or be illegal{" "}
              </span>
            </li>
            <li>
              <span>
                Detect security incidents, protect against malicious, deceptive, fraudulent or illegal activity, and prosecute those responsible for
                that activity
              </span>
            </li>
          </ul>
          <p>
            We may use personal information we collect from or about you to create aggregated or de-identified data that does not specifically
            identify you.{" "}
          </p>

          <h4 className="text-xl mt-5">4. Personal Information Guru Screener discloses </h4>

          <p>
            We share personal information with other Guru Screener companies who help us to provide you with products and services<span>. </span>
          </p>
          <p>
            We also share personal information with third parties for the business purposes of providing our products and services, and marketing our
            products and services to existing and potential customers. The categories of these third parties with whom we share personal information
            are:
          </p>

          <ul>
            <li>
              <span>Hosting and infrastructure providers;</span>
            </li>
            <li>
              <span>IT services;</span>
            </li>
            <li>
              <span>Email service providers;</span>
            </li>
            <li>
              <span>Advertising partners;</span>
            </li>
            <li>
              <span>Payment processors. </span>
            </li>
          </ul>
          <p>
            We share all of the categories of personal information with these third parties when we need to do so in order to provide you with Guru
            Screener products and services.
          </p>
          <p>We may also share personal information with the following:</p>

          <ul>
            <li>
              <span>
                Competent law enforcement bodies, regulators, government agencies, courts or other third parties where we believe disclosure is
                necessary (i) as a matter of applicable law or regulation, (ii) to exercise, establish or defend our legal rights, or (iii) to protect
                your vital interests or those of any other person;
              </span>
            </li>
            <li>
              <span>
                Actual or potential buyers (and their agents and advisers) in connection with any actual or proposed purchase, merger or acquisition
                of any part of our business, provided that we inform the buyer it must use your personal information only for the purposes disclosed
                in this Privacy Policy;
              </span>
            </li>
            <li>
              <span>Other persons where you have given your consent.</span>
            </li>
          </ul>

          <h4 className="text-xl mt-5">5. Cookies and other tracking technologies</h4>

          <p>
            We use cookies and similar tracking technology (collectively, “<b>Cookies</b>
            <span>
              ”) to collect and use personal information about you, including to serve interest-based advertising. For further information about the
              types of Cookies we use, why, and how you can control Cookies, please see our Cookie Policy at{" "}
            </span>
            <a href="https://guruscreener.io/page/cookie-notice">
              <span>https://guruscreener.io/page/cookie-notice</span>
            </a>
            <span>.</span>
          </p>

          <h4 className="text-xl mt-5">6. Children's Privacy</h4>

          <p>
            In accordance with our Terms of Use, the Site is not designed for use by individuals under the age of 18. Accordingly, we do not intend to
            or knowingly collect personal information from anyone we know to be under 18 years of age.<span> I</span>
            <span>f you believe we have collected personal data from anyone under 18, please contact us at the email address listed below.</span>
          </p>

          <h4 className="text-xl mt-5">7. Security</h4>

          <p>
            We have implemented various security policies, rules and technical and organizational measures to protect and safeguard the personal
            information that we collect and process about you. These measures are designed to provide a level of security appropriate to the risk of
            processing your personal information.{" "}
          </p>

          <h4 className="text-xl mt-5">8. California Privacy Information</h4>

          <p>
            If you are a California resident, the following information also applies to you and supplements the information contained in this Privacy
            Policy<span>.</span>
          </p>
          <p>
            <b>Information We Collect and Sources of Information</b>
          </p>
          <p>
            In the past 12 months, we have collected the information described in the section "Personal Information Guru Screener Collects" above,
            which also includes categories of sources from which we have collected that personal information.
          </p>
          <p>
            <b>How we use and share information</b>
          </p>
          <p>
            We collect and use personal information for the business and commercial purposes as described in the section "How Guru Screener Uses
            Personal Information?" above. Guru Screener does not share your personal information except as approved by you or as otherwise described
            in this Privacy Policy.{" "}
          </p>
          <p>
            In the preceding 12 months, we have disclosed the following categories of personal information described above for the business purposes
            described in this Privacy Policy:
          </p>

          <ul>
            <li>
              <span>Identifiers such as those set forth above;</span>
            </li>
            <li>
              <span>Internet or other electronic network activity information;</span>
            </li>
            <li>
              <span>Demographic information / Protected Classification Characteristics;</span>
            </li>
            <li>
              <span>Commercial Information;</span>
            </li>
            <li>
              <span>Geo-location data; </span>
            </li>
            <li>
              <span>Audio, Video and other Electronic Data</span>
            </li>
            <li>
              <span>Professional or employment-related information; and</span>
            </li>
            <li>
              <span>Profiles and Inferences.</span>
            </li>
          </ul>
          <p>
            Under the California Consumer Privacy Act (CCPA), "sale" is defined very broadly and includes a wide array of data sharing. As the term is
            defined by the CCPA, we “sold” the following categories of personal information in the last 12 months: identifiers/contact information,
            Internet or other electronic network activity information, and inferences drawn from the above. We “sold” such information to advertising
            networks, data analytics providers, and social networks. The business or commercial purposes of “selling” personal information is for
            third-party companies to perform services on our behalf, such as marketing, advertising, and audience measurement. We do not have any
            actual knowledge that we "sell" personal information of consumers under 16 years of age.
          </p>
          <p>
            Most modern web browsers give you the option to send a Do Not Track signal to the sites you visit, indicating that you do not wish to be
            tracked. However, there is no accepted standard for how a site should respond to this signal, and we do not take any action in response to
            this signal.
          </p>
          <p>
            <b>California Privacy Rights</b>
          </p>
          <p>If you are a California resident, you have the following consumer privacy rights:</p>

          <ul>
            <li>
              <span>Right to Know</span>
              <span>: </span>
              <span>
                You have the right to request that we disclose to you the personal information we have collected, used, disclosed and sold over the
                past 12 months, and information about our data practices.
              </span>
            </li>
            <li>
              <span>Right to request deletion</span>
              <span>
                : You can request that we delete personal information about you. Although you may request deletion, we are allowed, under California
                law, to keep certain personal information. You can submit a request to delete by{" "}
              </span>
              <a href="https://guruscreener.io/page/privacy-request">
                <span>submitting a form</span>
              </a>
              <span> here</span>
              <span>. </span>
            </li>
            <li>
              <span>Right to Opt-Out of "Sale" of personal information</span>
              <span>: You can opt out of the "sale" of your personal information to "third parties" </span>
              <a href="https://guruscreener.io/account/ccpa">
                <span>by clicking on this link</span>
              </a>
              <span> (as those terms are defined in the CCPA)</span>
              <span>. </span>
            </li>
            <li>
              <span>Right to be Notified of Financial Incentives</span>
              <span>: You</span>
              <span>
                {" "}
                have the right to be notified of any financial incentives offers and their material terms, the right to opt-out of such incentives at
                any time, and may not be included in such incentives without your prior informed opt-in consent. We do not offer any such incentives
                at this time.{" "}
              </span>
            </li>
            <li>
              <span>Right to Non-Discrimination</span>
              <span>: We will not discriminate against you for exercising any of these rights. </span>
            </li>
          </ul>
          <p></p>
          <p>
            California law also permits you to request certain information regarding the disclosure of your personal information by us and our related
            companies to third parties for the third parties’ direct marketing purposes. We do not disclose your personal information to third parties
            for their own direct marketing purposes.
          </p>
          <p>
            In addition to the specific forms listed above, <span>you can exercise any of these rights by contacting us at </span>
            <a href="about:blank">
              <span>privacy@guruscreener.io</span>
            </a>
            <span> and providing</span>{" "}
            <span>
              us with your name, email address and address (including zip code) or by submitting a request to us through your Guru Screener account.
              We may verify your identity by asking you to submit a request through your password protected Guru Screener account and by providing us
              with your California zip code or other proof of California residency.{" "}
            </span>
          </p>
          <p>
            You can also authorize an agent to submit a request on your behalf{" "}
            <span>
              . An authorized agent must have your signed permission to submit a request on your behalf or provide proof that they have power of
              attorney in accordance with California probate law. Authorized agents that are business entities must be registered with the California
              Secretary of State to conduct business in California. Where you use an agent, we will ask for verification of your authorization, namely
              a copy of your written authorization for the agent to submit the request on your behalf.{" "}
            </span>
          </p>
          <p>
            We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data
            protection laws. We do not discriminate against any consumer for exercising their consumer rights.
          </p>

          <h4 className="text-xl mt-5">9. European Privacy Information</h4>

          <p>
            If you are a visitor to our Site from the European Economic Area, United Kingdom and certain other territories in Europe, the following
            information also applies to you and supplements the information contained in this Privacy Policy.
          </p>
          <p>
            <b>Data Subject Rights</b>
          </p>
          <p>You have the following data protection rights:</p>

          <ul>
            <li>
              <span>Right to access, correct, or request deletion</span>
              <span>: You may be able to do some of this in your Guru Screener account or by contacting us.</span>
            </li>
            <li>
              <span>Right to object, or ask us to restrict processing</span>
              <b>:</b>
              <span> In certain circumstances, you can request that we stop processing your personal information.</span>
            </li>
            <li>
              <span>Right to request portability</span>
              <b>: </b>
              <span>
                In certain circumstances, you can request that we transfer personal information that you have provided to us in a structured, commonly
                used, machine-readable format.
              </span>
            </li>
            <li>
              <span>Right to opt-out of marketing communications</span>
              <b>:</b>
              <span>
                {" "}
                You can exercise this right by clicking on the “unsubscribe” or “opt-out” link in the marketing e-mails we send you or by contacting
                us.{" "}
              </span>
            </li>
            <li>
              <span>Right to withdraw your consent</span>
              <span>
                : You can withdraw your consent at any time if you previously gave us consent for processing of your personal information. Withdrawing
                your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of
                your personal information conducted in reliance on lawful processing grounds other than consent.
              </span>
            </li>
            <li>
              <span>Right to complain</span>
              <b>:</b>
              <span>
                {" "}
                You have the right to complain to your local data protection authority. For more information, please contact your local data
                protection authority.{" "}
              </span>
            </li>
          </ul>
          <p>
            We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data
            protection laws. If you would like to exercise any of these rights or would like further information please contact us using the contact
            details provided under the “How to contact us” heading below.{" "}
          </p>
          <p>
            <b>Legal Basis</b>
          </p>
          <p>Our legal basis for collecting and using the personal information is usually as follows:</p>

          <ul>
            <li>
              <span>Performance of a contract:</span>
              <span>
                {" "}
                where we need the personal information to perform a contract with you, for example setting up and administering your account and
                providing our products and services
              </span>
            </li>
            <li>
              <span>Legal obligation:</span>
              <span>
                {" "}
                where we have a legal obligation to collect personal information from you, for example to comply with financial, accounting and other
                legal obligations
              </span>
            </li>
            <li>
              <span>Legitimate interests:</span>
              <span>
                {" "}
                where the processing is in our legitimate interests and not overridden by your rights, for example to analyse and improve how our
                products and services are used, and to provide you with customer service
              </span>
            </li>
            <li>
              <span>Consent:</span>
              <span> where we have your consent to do so, for example in relation to certain types of marketing </span>
            </li>
          </ul>
          <p>
            <b>International Data Transfers</b>
          </p>
          <p>
            Your personal information may be transferred to, and processed in, countries other than the country in which you are resident.
            Specifically, our Site servers are located in the United States and Germany, and our group companies and third party service providers and
            partners operate around the world. This means that when we collect your personal information, we may process it in any of these countries.
          </p>
          <p>
            Although legal requirements may vary from country to country, Guru Screener has taken appropriate safeguards to require that your personal
            information will remain protected in accordance with this Privacy Policy.
          </p>
          <p>
            When we transfer your personal information to any other territories, we will take such measures as are appropriate to safeguard your
            information in accordance with applicable law.&nbsp;
          </p>
          <p>
            <b>Data retention</b>
          </p>
          <p>
            We retain personal information we collect from you where we have an ongoing legitimate business need to do so (for example, to provide you
            with a service you have requested or to comply with applicable legal, tax or accounting requirements).{" "}
          </p>
          <p>
            When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it or, if this
            is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your
            personal information and isolate it from any further processing until deletion is possible.
          </p>

          <li>
            <b> Updates to this Privacy Policy</b>
          </li>

          <p>
            We may update this Privacy Policy from time to time in response to changing legal, technical or business developments. When we update our
            Privacy Policy, we will take appropriate measures to inform you, consistent with the significance of the changes we make.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
