import { useQuery } from "@tanstack/react-query";

import scoreService from "../services/scoreService";

export const useScores = ({ symbol }) => {
  return useQuery({
    queryKey: ["scores", symbol],

    queryFn: () => scoreService.get({ params: { symbol: symbol } }),
    refetchOnWindowFocus: false,
    staleTime: 600000,
  });
};
