import React from "react";
import { MathJax } from "better-react-mathjax";
const ReturnOnEquity = () => {
  return (
    <div className="screener-article">
      <h1>Return On Equity (ROE)</h1>
      <p>
        The Return on Equity, or ROE, measures how efficiently a company uses Shareholders’ Equity to generate profits. It is calculated as the Net
        Profit for the year, divided by Average Book Value, or Equity, for the period.
      </p>

      <h2>Formula of the ROE</h2>
      <p className="text-xl mb-10 ">
        <MathJax>{"\\( \\text{ROE}=\\frac{\\text{Net Income}}{\\text{Book Value}} \\)"}</MathJax>
      </p>
      <p className="text-xl mb-10 overflow-x-auto w-full pb-3">
        <MathJax>{"\\( \\text{Book Value}=\\text{Total Assets}-\\text{Total Liabilities} \\)"}</MathJax>
      </p>

      <h2>Interpretation of ROE</h2>
      <p>This is defined as Income available to Common Shareholders (excluding Extraordinaries) divided by the Average Book Value over the period.</p>
      <p>
        The DuPont formula is a common way to break down ROE into three important components. Essentially, ROE will equal the Net Margin multiplied by
        Asset Turnover multiplied by Financial Leverage.
      </p>
    </div>
  );
};

export default ReturnOnEquity;
