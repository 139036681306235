import React from "react";
import Card from "./Card";

const LoadingCard = () => {
  return (
    <Card>
      <div className="text-center my-10">
        <span className="loading loading-dots loading-lg"></span>
        <p className="text-xl font-semibold">Loading...</p>
      </div>
    </Card>
  );
};

export default LoadingCard;
