import React, { useEffect, useState } from "react";
import useBacktestingStore from "../../../store/backtesting/store";

const randomPhrases = {
  easterEggs: [
    "Warren Buffet is thinking...",
    "Elon Musk tweeted something interesting...",
    "Is it a good day for Tesla stocks?",
    "Let's see what the Oracle has to offer today...",
    "Peter Lynch mentions 'invest in what you know' might not apply here...",
    "George Soros made a bet against this algorithm...",
    "Benjamin Graham would question our value metrics...",
  ],

  funEasterEggs: [
    "Someone just spilt coffee on the server...",
    "Our algorithm just learned a new trick!",
    "Hedge fund managers hate this one simple algorithm trick!",
    "We found a stock that goes up on Mondays, but we're not telling which!",
    "Our servers run on blockchain - just kidding",
    "Our backtesting uses quantum computers, or so our developer claims...",
    "This algorithm has more secrets than a Swiss bank.",
    "Inflation's up, but so are our simulated returns!",
    "Stock tip of the day: Invest in coffee – it powers this algorithm.",
  ],

  algorithmProcessing: [
    "Analyzing market patterns...",
    "Optimizing algorithm parameters...",
    "Checking the latest market trends...",
    "Hmmm, something looks promising...",
    "Optimizing algorithm parameters for maximum return on risk...",
  ],

  findings: [
    "Buy triggered.",
    "Shares acquired.",
    "Purchase completed.",
    "Acquisition successful.",
    "Shares bought.",
    "Buy order placed.",
    "Purchase confirmed.",
    "Automated buy initiated.",
    "Buy executed.",
    "Added to portfolio.",
    "Long position initiated.",
    "Purchase executed.",
    "Buying started.",
    "Shares secured.",
    "Position established.",
  ],
};

const randomText = [
  "Start Simulation - Guru Screener Backtesting",
  "",
  randomPhrases.easterEggs[Math.floor(Math.random() * randomPhrases.easterEggs.length)],
  "",
  "Extraction of 7895 Stocks",
  "Loading historical market data...",
  randomPhrases.algorithmProcessing[Math.floor(Math.random() * randomPhrases.algorithmProcessing.length)],
  randomPhrases.findings[Math.floor(Math.random() * randomPhrases.findings.length)],
  randomPhrases.findings[Math.floor(Math.random() * randomPhrases.findings.length)],
  randomPhrases.findings[Math.floor(Math.random() * randomPhrases.findings.length)],
  ,
  "Done!",
];

const Typewriter = ({ text, delay }) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (text) {
      if (currentIndex < text.length) {
        const timeout = setTimeout(() => {
          setCurrentText((prevText) => prevText + text[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }, delay);

        return () => clearTimeout(timeout);
      }
    }
  }, [currentIndex, delay, text]);

  return <span>{currentText}</span>;
};

const TypeLog = () => {
  const [currentLog, setCurrentLog] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    console.log(`Current index: ${currentIndex}, Length of randomText: ${randomText.length}`);
    if (currentIndex < randomText.length) {
      const timeout = setTimeout(() => {
        setCurrentLog((prevText) => [
          ...prevText,
          <pre data-prefix="$">
            <code>
              {/* {randomText[currentIndex]} */}
              <Typewriter text={randomText[currentIndex]} delay={10} />
            </code>
          </pre>,
        ]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex]);

  return currentLog;
};

const FakeLog = () => {
  const { results, years, startSimulation } = useBacktestingStore();

  const [log, setLog] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [hasBeenLaunched, setHasBeenLaunched] = useState(false);

  useEffect(() => {
    if (startSimulation === true) {
      setInProgress(true);
      setHasBeenLaunched(true);
    } else {
      if (Object.keys(results).length > 0) {
        setLog(log);
        setInProgress(false);
      }
    }
  }, [startSimulation, results]);

  if (hasBeenLaunched) {
    return (
      <div>
        <div className="mockup-code max-h-96 overflow-y-auto">
          {inProgress ? (
            <>
              <TypeLog />
            </>
          ) : (
            <>
              <pre data-prefix="$">
                <code>5895 stocks tested in 2000</code>
              </pre>
              <pre data-prefix="$">
                <code>6958 stocks tested in 2001</code>
              </pre>
              <pre data-prefix="$">
                <code>7050 stocks tested in 2002</code>
              </pre>
              <pre data-prefix="$">
                <code>Tested from 2000 to 2020</code>
              </pre>

              <pre data-prefix="$">Simulation End</pre>
            </>
          )}
        </div>
      </div>
    );
  }
};

export default FakeLog;
