import React from "react";

const Card = ({ children, extraClass }) => {
  return (
    <div
      className={
        "bg-base-100 dark:bg-slate-800 rounded-lg px-5 md:px-6 py-5 md:py-8 md:mx-5 mb-10 mt-5 ring-1 ring-slate-900/5 dark:ring-slate-900/60 shadow-xl shadow-slate-300/10 dark:shadow-xl " +
        extraClass
      }
    >
      {children}
    </div>
  );
};

export default Card;
