import React from "react";

const TermsOfUse = () => {
  return (
    <div className="card">
      <div className="card-body">
        <h1 className="text-xl ">Terms of Use</h1>
        <p>
          <em>PLEASE READ OUR TERMS OF USE CAREFULLY BEFORE YOU USE OUR WEB SITE</em>
        </p>
        <p>
          Guru Screener . ("Guru Screener" "we," or "us") provides its web site, Guru Screener, located at{" "}
          <a href="https://guruscreener.io/">https://guruscreener.io/</a> (together with all other websites, mobile applications and services operated
          by or on behalf of Guru Screener, the "Site"), to you, an individual user ("you") for your individual usage, subject to compliance with the
          terms and conditions set forth herein.
        </p>

        <h4 className="text-xl mt-5">1. Agreement</h4>
        <p>
          By using the Site, you agree to be bound by our Terms of Use (the "TOU"). If you do not agree to the terms and conditions contained in the
          TOU and <a href="privacy.html">Guru Screener's privacy policy</a>, please do not access or otherwise use the Site or any information
          contained herein.
        </p>
        <p>
          You affirm that you are over the age of 18, as the Site is not intended for children under 18. If it comes to Guru Screener's attention
          through reliable means that a registered user is a child under 18 years of age, Guru Screener will cancel that user's account.
        </p>

        <h4 className="text-xl mt-5">2. Changes to the TOU</h4>
        <p>We reserve the right at any time to:</p>

        <ul data-rte-list="default">
          <li>
            <p className="">Change the terms and conditions of the TOU;</p>
          </li>
          <li>
            <p className="">Change the Site, including eliminating or discontinuing any content or feature of the Site; or</p>
          </li>
          <li>
            <p>Impose fees, charges or other conditions for use of the Site or parts thereof (with reasonable notice).</p>
          </li>
        </ul>
        <p>
          Guru Screener may modify the Site at any time without prior notice, and you accept those modifications if you continue to use the Site. You
          should check the Site frequently to see recent changes.
        </p>

        <h4 className="text-xl mt-5">3. Important Securities Disclaimer</h4>
        <p>
          You understand that no content published on the Site constitutes a recommendation that any particular security, portfolio of securities,
          transaction or investment strategy is suitable for any specific person. You further understand that none of the bloggers, information
          providers, app providers, or their affiliates are advising you personally concerning the nature, potential, value or suitability of any
          particular security, portfolio of securities, transaction, investment strategy or other matter. To the extent that any of the content
          published on the Site may be deemed to be investment advice or recommendations in connection with a particular security, such information is
          impersonal and not tailored to the investment needs of any specific person. You understand that an investment in any security is subject to
          a number of risks, and that discussions of any security published on the Site will not contain a list or description of relevant risk
          factors. In addition, please note that some of the stocks about which content is published on the Site have a low market capitalization
          and/or insufficient public float. Such stocks are subject to more risk than stocks of larger companies, including greater volatility, lower
          liquidity and less publicly available information. Blogs, postings or content on the Site which may or may not be deemed by you to be
          recommendations may have an effect on their stock prices.
        </p>
        <p>
          You understand that the Site may contain opinions from time to time with regard to securities mentioned in other Guru Screener blogs or
          products, and that opinions in one blog or product may be different from those in another blog or product. Any views or opinions expressed
          may not reflect those of Guru Screener as a whole. We require all employees to disclose every stock in which they, their immediate family,
          or any entity under their control, have a personal interest, if such stock is mentioned in a blog, post, or content which they write.
          However, you understand and agree that non-employees, including outside bloggers or other content contributors or their affiliates, may
          write about securities in which they or their firms have a position, that they may trade for their own account, and that they may or may not
          be subject to a disclosure policy. In cases where Guru Screener becomes aware that one of its employees has violated his or her disclosure
          obligation, Guru Screener will take appropriate action. In addition, outside bloggers or content contributors may be subject to certain
          restrictions on trading for their own account. However, you understand and agree that at the time of any transaction that you make, one or
          more bloggers or content contributors or their affiliates may have a position in the securities they write about.
        </p>
        <p>
          You understand that performance data is supplied by sources believed to be reliable, that the calculations on our Site are made using such
          data, and that such calculations are not guaranteed by these sources, the information providers, or any other person or entity, and may not
          be complete.
        </p>
        <p>
          From time to time, reference may be made on our Site to prior articles and opinions we have published. These references may be selective,
          may reference only a portion of an article or opinion, and are likely not to be current. As markets change continuously, previously
          published information and data may not be current and should not be relied upon.
        </p>
        <p>
          All content on the Site is presented only as of the date published or indicated, and may be superseded by subsequent market events or for
          other reasons. In addition, you are responsible for setting the cache settings on your browser to ensure you are receiving the most recent
          data.
        </p>

        <h4 className="text-xl mt-5">4. No Investment Recommendations or Professional Advice</h4>
        <p>
          The content on the Site is being provided for information purposes only. The Site does not provide tax, legal, insurance or investment
          advice, and nothing on the Site should be construed as an offer to sell, a solicitation of an offer to buy, or a recommendation for any
          security by Guru Screener or any third party. You alone are solely responsible for determining whether any investment, security or strategy,
          or any other product or service, is appropriate or suitable for you based on your investment objectives and personal and financial situation
          and for evaluating the merits and risks associated with the use of the information on the Site before making any decisions based on such
          information or other content. You should consult an attorney or tax professional regarding your specific legal or tax situation.
        </p>
        <p>
          <strong>
            Past performance is no guarantee of future results. Therefore, you should not assume that the future performance of any specific
            investment or investment strategy will be profitable or equal to corresponding past performance levels. Inherent in any investment is the
            potential for loss.
          </strong>{" "}
          No recommendation or advice is being given as to whether any investment is suitable for a particular investor. It should not be assumed that
          any investments in securities, companies, sectors or markets identified and described were or will be profitable. Guru Screener is not a
          fiduciary by virtue of any person’s use of or access to the Site. Guru Screener is not a licensed securities dealer, broker or US investment
          adviser or investment bank.
        </p>

        <h4 className="text-xl mt-5">5. Copyright, Linking Policy and Trademarks</h4>
        <p>
          The Site and the content contained herein, as well as all copyrights, including without limitation, the text, documents, articles, products,
          software, graphics, photos, sounds, videos, interactive features, services, links, User Submissions (as defined below), third-party apps,
          and any other content on the Site ("Content") and the trademarks, service marks and logos contained therein are the property of Guru
          Screener and its third-party licencors or providers. You may access and use the Content, and download and/or print out copies of any content
          from the Site, solely for your personal, non-commercial use. If you download or print a copy of the Content for personal use, you must
          retain all copyright and other proprietary notices contained therein. You acknowledge that you do not acquire any ownership rights by using
          the Site. Guru Screener reserves all rights not expressly granted in and to the Site.
        </p>
        <p>
          The Site contains links to other Internet websites or links to Content created by third parties which is published on the Site. We neither
          control nor endorse such other websites or Content, nor have we reviewed or approved any Content that appears on such other websites or on
          our Site. Please read the terms of use and privacy policy of any such third party sites that you interact with before you engage in any
          activity. You are solely responsible and liable for your use of and linking to all third party sites. You acknowledge and agree that we
          shall not be held responsible for the legality, accuracy, or appropriateness of any Content, advertising, products, services, or information
          located on our Site or any other websites, nor for any loss or damages caused or alleged to have been caused by the use of or reliance on
          any such content. Similarly, while we endeavor to facilitate the provision of quality apps, we are not responsible for any loss or damages
          caused or alleged to have been caused by their use.
        </p>
        <p>
          You may link to any content on the Site. If you are interested in reprinting, republishing or distributing content from Guru Screener,
          please contact Guru Screener to obtain written consent. Guru Screener™ is a trademark and/or service mark of Guru Screener or an affiliate.
          All other trademarks, service marks, and logos used on our web sites are the trademarks, service marks, or logos of their respective owners.
        </p>
        <p>This section shall survive any termination of these TOU.</p>

        <h4 className="text-xl mt-5">6. User Conduct</h4>
        <p>
          You may not use, copy, display, sell, license, de-compile, republish, upload, post, transmit, distribute, create derivative works or
          otherwise exploit Content from the Site to online bulletin boards, message boards, newsgroups, chat rooms, or in other any manner, without
          our prior written permission. Modification of the Content or use of the Content for any purpose other than your own personal, noncommercial
          use is a violation of our copyright and other proprietary rights, and can subject you to legal liability.
        </p>
        <p>
          In addition, in connection with your use of the Site and its services (including by sending private messages to other registered users of
          the Site via your Guru Screener direct messaging account), you agree not to:
        </p>

        <ul data-rte-list="default">
          <li>
            <p>
              Restrict or inhibit any other visitor from using the Site, including, without limitation, by means of "hacking" or defacing any portion
              of the Site;
            </p>
          </li>
          <li>
            <p>Use the Site for any unlawful purpose;</p>
          </li>
          <li>
            <p>Express or imply that any statements you make are endorsed by us, without our prior written consent;</p>
          </li>
          <li>
            <p>Modify, adapt, sublicense, translate, sell, reverse engineer, decompile or disassemble any portion of the Site;</p>
          </li>
          <li>
            <p>Disable, damage or alter the functioning or appearance of the Site, including the presentation of advertising;</p>
          </li>
          <li>
            <p>"Frame" or "mirror" any part of the Site without our prior written authorization;</p>
          </li>
          <li>
            <p>
              Use any robot, spider, site search/retrieval application, or other manual or automatic device or process to download, retrieve, index,
              "data mine", "scrape", "harvest" or in any way reproduce or circumvent the navigational structure or presentation of the Site or its
              contents;
            </p>
          </li>
          <li>
            <p>Harvest or collect information about visitors to the Site without their express consent;</p>
          </li>
          <li>
            <p>Send unsolicited or unauthorized advertisements, spam, chain letters, etc to other users of the Site;</p>
          </li>
          <li>
            <p>Transmit any Content which contains software viruses, or other harmful computer code, files or programs.</p>
          </li>
        </ul>
        <p>
          You also agree to comply with all applicable laws, rules and regulations in connection with your use of the Site and the content made
          available therein. We aim to prevent, detect and not knowingly facilitate money laundering and terrorism financing activities. You may not
          use the Site in a manner which violates our anti-money laundering, counter terrorist financing or similar regulatory obligations.
        </p>
        <p>
          We reserve the right to close, suspend, or limit access to your Subscription, account and/or the Site in the event your use violates such
          obligations or if, after reasonable inquiry, we are unable to obtain information about you which is required to verify your identity. We may
          require you to provide additional information or documents to verify your identity, including your date of birth, physical address, taxpayer
          or other identification number, your physical address, government issued identification document or other information that will allow us to
          reasonably identify you.
        </p>
        <p>
          In order to access some of the services of the Site, you will have to create an account. By creating this account you agree to the
          following:
        </p>

        <ul data-rte-list="default">
          <li>
            <p>You may only maintain a single account;</p>
          </li>
          <li>
            <p>
              You may never share your account user name or password or knowingly provide or authorize access to your account (including without
              limitation a "Subscription", as defined below in Section 20);
            </p>
          </li>
          <li>
            <p>You may never use another user's account without permission;</p>
          </li>
          <li>
            <p>When creating your account, you must provide accurate and complete information;</p>
          </li>
          <li>
            <p>You are solely responsible for the activity that occurs on your account, and you must keep your account password secure;</p>
          </li>
          <li>
            <p>You must notify us immediately of any breach of security or unauthorized use of your account.</p>
          </li>
        </ul>
        <p>
          You will be liable for any use made of your account or password and the losses of Guru Screener or others due to such unauthorized use. We
          will not be liable for your losses caused by any unauthorized use of your account.
        </p>
        <p>Guru Screener has the right to terminate your access to the Site, in its sole discretion.</p>

        <h4 className="text-xl mt-5">7. Overview of Posting Content; Monitoring Content</h4>
        <p>
          The Site permits the submission of Content by users of the Site, including without limitation comments, articles, links, private messages
          sent to other registered users through the Site's messaging system,&nbsp; including from those who give permission to Guru Screener to post
          their Content ("User Submissions") and the hosting, sharing and publishing of such User Submissions on the Site. Guru Screener has the right
          in its sole discretion and without further notice to you, to monitor, censor, edit, move, delete, and/or remove any and all Content posted
          on its Site or any Content transmitted by direct messaging or by any other method to or from your Guru Screener user account at any time and
          for any reason. Without limiting the foregoing, Guru Screener has the right to delete any comment or Content that it believes, in its sole
          discretion, does or may violate the TOU of the Site by you.
        </p>

        <h4 className="text-xl mt-5">8. Post Content; Online Rules of Conduct</h4>
        <p>When you post any User Submission on the Site or give Guru Screener permission to post your Content, you agree to:</p>

        <ul data-rte-list="default">
          <li>
            <p>
              Post comments in both tone and content that contribute in a positive and high quality manner to the substantive exchange of information
              and the subject matter of the Site.
            </p>
          </li>
          <li>
            <p>
              Automatically grant Guru Screener a royalty-free, perpetual, worldwide, irrevocable, non-exclusive and fully transferable and
              sublicensable right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform
              and display any User Submission (in whole or in part) and/or to incorporate any of your User Submission in other works now or in the
              future and in any media formats and through any media channels, and you confirm and warrant to Guru Screener that you own the copyright
              in each of your User Submissions and have all the rights, power and authority necessary to grant the above license and rights.
            </p>
          </li>
        </ul>
        <p>Guru Screener will use commercially reasonable efforts to attribute material User Submissions to the author.</p>
        <p>
          If you provide any feedback or suggestions to Guru Screener regarding the Site or Guru Screener’s services, including without limitation in
          response to a survey or in connection with a particular User Submission (collectively, "Feedback"), Guru Screener may use such Feedback for
          any purpose, including without limitation to provide it to authors on their dashboard. In order that we may incorporate such Feedback into
          Market Advisor’s Site and/or services, Guru Screener alone will own all right, title and interest, including all related intellectual
          property rights, in and to all such Feedback and you hereby assign such Feedback to Market Advisor free of charge.
        </p>
        <p>You agree not to:</p>

        <ul data-rte-list="default">
          <li>
            <p>
              Choose an alias that is threatening, abusive, offensive, harassing, derisive, defamatory, vulgar, obscene, libelous, hatefully,
              racially, ethnically or otherwise or objectionable.
            </p>
          </li>
          <li>
            <p>
              Post or transmit any Content that you either know or should know is false, deceptive or misleading, or misrepresent or deceive others as
              to the source, accuracy, integrity or completeness of any comment you post.
            </p>
          </li>
          <li>
            <p>
              Post or transmit any Content that is unlawful, harmful or injurious to others, contains software viruses, or other harmful computer
              code, files or programs, threatening, abusive, offensive, harassing, derisive, defamatory, vulgar, obscene, libelous, hatefully,
              racially, ethnically or otherwise tortious or objectionable.
            </p>
          </li>
          <li>
            <p>
              Post or transmit any Content that does or may invade the privacy or violate or infringe on any rights of others, including, without
              limitation, copyrights and other intellectual property rights.
            </p>
          </li>
          <li>
            <p>
              By use of your alias or in any comment, impersonate any person or entity, falsely or deceptively state, infer or otherwise misrepresent
              your affiliation with or connection to any person or entity.
            </p>
          </li>
          <li>
            <p>
              Post or transmit any Content which, either the act of posting or the comment itself, you do not have a right to do under any law,
              regulation or order of any court, or as a result of an employment, contractual, fiduciary or other legal obligation or relationship.
            </p>
          </li>
          <li>
            <p>
              Post or transmit any advertising, promotional materials, so called "chain letters," "pyramid" or other schemes or invitations to
              participate in these or any other form of solicitation or promotion.
            </p>
          </li>
          <li>
            <p>Post or transmit any non-public or otherwise restricted, confidential or proprietary information without authorization.</p>
          </li>
          <li>
            <p>
              Violate any local, state, national or international law, regulation or order of any court, including but not limited to regulations of
              the U.S. Securities and Exchange Commission or any rules of any securities exchange, including without limitation, the New York Stock
              Exchange, the American Stock Exchange or The Nasdaq Stock Market.
            </p>
          </li>
        </ul>
        <h4 className="text-xl mt-5">9. Caveats</h4>
        <p>
          While we believe that the Site can and should be a positive environment for the exchange of information, you understand that the Site is
          open for posting to all users. Some individuals may post comments that may be offensive, indecent, objectionable, false, misleading or
          simply inappropriate.
        </p>

        <h4 className="text-xl mt-5">10. Responsibility for User Submission</h4>
        <p>
          Each individual who submits a User Submission, whether published on the Site or not, is solely responsible for her or his own acts,
          including the content, context or information in the User Submission he or she submits. This means that each individual, and not Guru
          Screener, is entirely responsible for anything and everything she or he posts on the Site including on any Blog, StockTalk, and Instablog.
          Except for the reasons set forth in Section 7, Guru Screener does not, and does not intend to, pre-screen any comments posted on its Site.
          Guru Screener cannot and does not guarantee the accuracy, integrity or quality of anything that may appear on its Site.
        </p>

        <h4 className="text-xl mt-5">11. Disclosure</h4>
        <p>
          We reserve the right to access, read, preserve, and disclose any User Submissions (whether published or not) or any other information we
          believe is reasonably necessary to (a) comply with any applicable law, regulation, legal process, subpoena or governmental or regulatory
          request, (b) enforce these TOU, including investigation of potential violations of it, (c) detect, prevent, or otherwise address fraud,
          security or technical issues, (d) respond to user support requests, or (e) protect the rights, property or safety of Guru Screener, its
          users, yourself or the public.
        </p>

        <h4 className="text-xl mt-5">12. Disclaimer of Warranties</h4>
        <p>
          THE SITE, AND ANY PRODUCT OR SERVICE OBTAINED OR ACCESSED THROUGH THE SITE, IS PROVIDED "AS IS" AND WITHOUT REPRESENTATIONS OR WARRANTIES OF
          ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, Guru Screener, ITS OFFICERS, DIRECTORS, EMPLOYEES,
          AFFILIATES, SUPPLIERS, ADVERTISERS, AGENTS, INFORMATION PROVIDERS AND ANY OTHER THIRD PARTY INVOLVED IN OR RELATED TO THE MAKING OR
          COMPILING OF THE SITE DISCLAIM ALL WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE AND
          NON-INFRINGEMENT, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ALL WARRANTIES RELATING TO THE
          ORIGINALITY, ADEQUACY, ACCURACY, TIMELINESS, MERCHANTABILITY OR COMPLETENESS OF ANY INFORMATION ON OUR SITE.
        </p>
        <p>Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusions may not apply to you.</p>
        <p>
          Guru Screener AND ITS AFFILIATES, SUPPLIERS, AGENTS, SPONSORS, INFORMATION PROVIDERS AND ANY OTHER THIRD PARTY INVOLVED IN OR RELATED TO THE
          MAKING OR COMPILING OF THE SITE DO NOT WARRANT THAT YOUR USE OF THE SITE WILL BE UNINTERRUPTED, ERROR-FREE OR SECURE, OR THAT THE SITE OR
          THE SERVER(S) ON WHICH THE SITE IS HOSTED ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR
          USE OF THE SITE AND YOUR RELIANCE THEREON. NO OPINION, ADVICE, OR STATEMENT OF Guru Screener OR ITS AFFILIATES, SUPPLIERS, AGENTS, MEMBERS,
          SPONSORS, INFORMATION PROVIDERS OR VISITORS, WHETHER MADE ON THE SITE OR OTHERWISE, SHALL CREATE ANY WARRANTY.
        </p>

        <h4 className="text-xl mt-5">13. Limitation of Liability</h4>
        <p>
          NEITHER Guru Screener NOR ITS AFFILIATES AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, SUPPLIERS, ADVERTISERS, AGENTS, SPONSORS,
          INFORMATION PROVIDERS NOR ANY OTHER THIRD PARTY INVOLVED IN OR RELATED TO THE MAKING OR COMPILING OF THE SITE ARE LIABLE FOR LOST PROFITS,
          LOST SAVINGS OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR OTHER DAMAGES UNDER ANY CONTRACT, NEGLIGENCE,
          STRICT LIABILITY OR OTHER THEORY ARISING OUT OF OR RELATING IN ANY WAY TO THE SITE AND/OR ANY CONTENT CONTAINED THEREIN, OR ANY PRODUCT OR
          SERVICE USED OR PURCHASED THROUGH Guru Screener. SUCH LIMITATION SHALL APPLY REGARDLESS OF THE FORM OF ACTION, EVEN IF Guru Screener, ITS
          INFORMATION PROVIDERS, OR ANY OTHER THIRD PARTY INVOLVED IN OR RELATED TO THE MAKING OR COMPILING OF ANY PART OF THE SITE HAS BEEN ADVISED
          OF OR OTHERWISE MIGHT HAVE ANTICIPATED THE POSSIBILITY OF SUCH DAMAGES. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SITE IS TO STOP USING
          IT. THE SOLE AND EXCLUSIVE MAXIMUM LIABILITY TO Guru Screener FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT
          (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE), OR OTHERWISE) SHALL NOT EXCEED THE TOTAL AMOUNT PAID TO US BY YOU, IF ANY, FOR ACCESS TO THE
          SITE OR ANY SERVICES, DURING THE PREVIOUS SIX (6) MONTHS PRIOR TO BRINGING THE CLAIM. IN NO EVENT SHALL Guru Screener, ITS INFORMATION
          PROVIDERS OR ANY THIRD PARTY INVOLVED IN OR RELATED TO THE MAKING OR COMPILING OF ANY OF THE GICS SERVICE (DEFINED BELOW), BE LIABLE TO YOU,
          OR ANY OTHER PERSON, FOR ANY DIRECT OR INDIRECT DAMAGES, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, LOST SAVINGS OR OTHER INCIDENTAL
          OR CONSEQUENTIAL DAMAGES ARISING OUT OF THE USE OR YOUR INABILITY TO USE THE GICS SERVICE.
        </p>
        <p>
          Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so the above limitations
          may not apply to you.
        </p>

        <h4 className="text-xl mt-5">14. Indemnification</h4>
        <p>
          As a condition of your use of the Site, you agree to indemnify, defend and hold us, our officers, directors, employees, agents,
          representatives, information providers, and any other third party involved in or related to the making or compiling of the Site harmless
          from and against any and all claims, damages, losses, costs (including reasonable attorneys' fees), or other expenses that arise directly or
          indirectly out of or from (a) your violation of the TOU; (b) your use or any third party’s use of, or inability to use, the Site; (c) your
          violation of the rights of any third party, or (d) any claim that one of your User Submissions caused damage to a third party. This defense
          and indemnification obligation will survive these TOU and your use of the Site.
        </p>

        <h4 className="text-xl mt-5">15. Termination</h4>
        <p>
          You understand and agree that Guru Screener may, under certain circumstances and without prior notice to you, terminate your access to and
          use of the Site. Cause for such termination shall include, but not be limited to, (i) breaches or violations of the TOU or other agreements
          or guidelines, (ii) requests by law enforcement or other government or regulatory authorities or (iii) repeat violators of third party
          copyrights or other intellectual property.
        </p>

        <h4 className="text-xl mt-5">16. Copyright Policy</h4>
        <h4 className="text-xl mt-5">
          Guru Screener respects the intellectual property of others, and we ask our users to do the same. Guru Screener may, in appropriate
          circumstances and at its discretion, terminate the account or access of users who infringe the intellectual property rights of others.
        </h4>
        <p>
          If you believe that your work has been copied in a way that constitutes copyright infringement, please provide our Copyright Agent the
          following information:
        </p>

        <ul data-rte-list="default">
          <li>
            <p>16.1: an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;</p>
          </li>
          <li>
            <p>
              16.2: a description of the copyrighted work that you claim has been infringed, including the URL (web page address) of the location
              where the copyrighted work exists or a copy of the copyrighted work;
            </p>
          </li>
          <li>
            <p>16.3: a description of where the material that you claim is infringing is located on the Site, including the URL;</p>
          </li>
          <li>
            <p>16.4: your address, telephone number, and email address;</p>
          </li>
          <li>
            <p>
              16.5: a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or
              the law; and
            </p>
          </li>
          <li>
            <p>
              16.6: a statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that you are the
              copyright owner or authorized to act on the copyright owner's behalf.
            </p>
          </li>
        </ul>
        <ul data-rte-list="default">
          <li>
            <p></p>
          </li>
        </ul>
        <h4 className="text-xl mt-5">17. Additional Terms that Apply to S&amp;P Services</h4>
        <ul data-rte-list="default">
          <li>
            <p>
              Neither S&amp;P Global Market Intelligence LLC, S&amp;P Global Inc., or any of S&amp;P Global Inc.’s subsidiaries (collectively,
              “S&amp;P) or its affiliates nor any of their suppliers shall have any liability for the accuracy or completeness of the Market
              Intelligence Consensus Estimates, Premium Financials/Fundamentals, ETF Classifications and Market Intelligence Data Ownership
              (collectively, the “S&amp;P Services”) furnished through the Site, or for delays, interruptions or omissions therein nor for any lost
              profits, indirect, special or consequential damages.
            </p>
          </li>
          <li>
            <p>
              The S&amp;P Services are not investment advice and a reference to a particular investment or security, a credit rating or any
              observation concerning a security or investment provided in the S&amp;P Services is not a recommendation to buy, sell or hold such
              investment or security or make any other investment decisions.
            </p>
          </li>
          <li>
            <p>
              S&amp;P, their affiliates or their suppliers have exclusive proprietary rights in the S&amp;P Services and any information and software
              received in connection therewith.
            </p>
          </li>
          <li>
            <p>You shall not use or permit anyone to use the S&amp;P Services for any unlawful or unauthorized purpose.</p>
          </li>
          <li>
            <p>
              The S&amp;P Services are being provided for your internal use only and you are not authorized or permitted to distribute or otherwise
              furnish such information or software to any third-party without prior written approval of S&amp;P.
            </p>
          </li>
          <li>
            <p>
              Access to the S&amp;P Services is subject to termination in the event that the corresponding agreement between Guru Screener and S&amp;P
              terminates for any reason.
            </p>
          </li>
          <li>
            <p>
              In the event that you use any portion of the S&amp;P Services in breach of the TOU, S&amp;P may enforce its rights against you as the
              third-party beneficiary of the TOU, even though S&amp;P is not a party to the TOU.
            </p>
          </li>
        </ul>
        <h4 className="text-xl mt-5">18. Additional Terms that Apply to Guru Screener Paid Subscriptions</h4>
        <ul data-rte-list="default">
          <li>
            <p>
              By subscribing to Guru Screener subscription products (the “Subscription”), you agree to pay the applicable Subscription fees set forth
              on the Site. Guru Screener reserves the right to revise Subscription fees upon reasonable notice.
            </p>
          </li>
          <li>
            <p>
              We remind you that all the rules applicable to setting up an account under Section 6 above (entitled, "User Conduct") apply to your
              Subscriptions.
            </p>
          </li>
          <li>
            <p>Unless we notify you in writing otherwise, you are not permitted to share Content available through your Subscriptions.</p>
          </li>
          <li>
            <p>
              Unless stated otherwise in writing, Subscription fees are nonrefundable. You may cancel purchases of Guru Screener products or
              complimentary services by updating your preferences in your Subscription Settings page, by contacting us by email at
              contact@guruscreener.io, or by another designated cancellation method.
            </p>
          </li>
          <li>
            <p>
              During any free trial portion of a Guru Screener subscription, you may cancel your trial subscription at any time (and not be billed) by
              notifying us as provided in the paragraph immediately above.{" "}
              <strong>
                You must cancel your trial subscription no later than one (1) day before the free trial period expires in order to avoid conversion to
                a paid subscription.
              </strong>
            </p>
          </li>
          <li>
            <p>
              For subscription products offering a <em>limited 30-day money back guarantee</em>,{" "}
              <strong>
                you must cancel your subscription no later than one (1) day before the 30-day limited money back guarantee period expires in order to
                receive a refund for the current month of your subscription. The refund must be explicitely request to contact@guruscreener.io
              </strong>
            </p>
          </li>
          <li>
            <p>
              For subscription products offering a <em>Performance Warranty</em>,{" "}
              <strong>
                you must submit your request to contact@guruscreener.io at least one (1) day after a 6-months period of continuous subscription in
                order to receive a free subscription up to 12 months. The request must be explicit and give all information explained in the
                documentation available at https://guruscreener.io/docs/warranty
              </strong>
            </p>
          </li>
          <li>
            <p>
              When your initial paid subscription commences (which occurs at the expiration of your free trial, or if you are not receiving a free
              trial, upon your registration for a subscription) your payment account will be billed the applicable subscription fee and your paid
              subscription will commence. Once your initial paid subscription commences, we do not provide refunds for initial orders or recurring
              payments.
            </p>
          </li>
          <li>
            <p>
              Guru Screener reserves the right to cancel a Subscription at any time. If we cancel a Subscription due to a breach of these Terms of Use
              you will not be eligible for any refund.
            </p>
          </li>
          <li>
            <p>
              You may download the S&amp;P Services to a desktop/computing device used solely for your individual use and not for any centralized,
              interdepartmental or shared use.
            </p>
          </li>
          <li>
            <p>
              Once downloaded to your computing device, the S&amp;P Services must remain on such computing device and may not be uploaded, copied or
              transferred to, or stored or managed in, any other desktop/computing device, including that of another user of the Site, or any device,
              directory, database or other repository that is shared or can be accessed by others (e.g. the download functionality may not be used to
              populate master security databases).
            </p>
          </li>
          <li>
            <p>
              You may download the S&amp;P Services only as a convenience in connection with your use of the Site and not as an alternative to
              obtaining the S&amp;P Services from and/or pursuant to an agreement with S&amp;P.
            </p>
          </li>
        </ul>

        <h4 className="text-xl mt-5">19. Additional Terms for Marketplace and Advisory services promoted by Guru Screener</h4>
        <p>
          Although we obtain information contained in our marketing promotions from sources we believe to be reliable, we cannot guarantee its
          accuracy. The opinions expressed in the promotions are those of Guru Screener, its editors, marketers and contributors, and may change
          without notice. Any views or opinions expressed in the promotion may not reflect those of the firm as a whole. The information in the
          promotion may become outdated and we have no obligation to update it. The information in the promotion is not intended to constitute
          individual investment advice and is not designed to meet your personal financial situation. It is provided for information purposes only and
          nothing herein constitutes investment, legal, accounting or tax advice, or a recommendation to buy, sell or hold a security. Furthermore,
          the marketing promotions of Marketplace and Advisory services are not recommendations to follow the investment research or trade ideas
          suggested by the authors of the services. No recommendation or advice is being given as to whether any investment is suitable for a
          particular investor or a group of investors. It should not be assumed that any investments in securities, companies, sectors or markets
          identified and described were or will be profitable. We strongly advise you to discuss your investment options with your financial adviser
          prior to making any investments, including whether any investment is suitable for your specific needs.
        </p>
        <p>
          The information provided in our promotions is private, privileged, and confidential information, licensed for your sole individual use as a
          subscriber. Guru Screener reserves all rights to the content of these services. Forwarding, copying, disseminating, or distributing the
          content in the Marketplace/Advisory service in whole or in part, including substantial quotation of any portion the publication or any
          release of specific investment recommendations, is strictly prohibited.
        </p>

        <h4 className="text-xl mt-5">20. Miscellaneous</h4>
        <p>
          The Site is directed solely to individuals residing in jurisdictions in which provision of the Site's content is legal. We make no
          representation that materials provided on the Site are appropriate or available for use in other locations. Those who choose to access the
          Site from other locations do so on their own initiative and at their own risk, and are responsible for compliance with local laws, if and to
          the extent applicable. We reserve the right to limit the availability of the Site to any person, geographic area, or jurisdiction we so
          desire, at any time and in our sole discretion, and to limit the quantities of any such service or product that we provide.
        </p>
        <p>
          The TOU, together with all Guru Screener policies referred to herein, constitutes the entire agreement between you and Guru Screener
          relating to your use of the Site and supersedes and any all prior or contemporaneous written or oral agreements on that subject between us.
          The TOU, privacy policy and the relationship between you and Guru Screener are governed by and construed in accordance with the laws of the
          State of New York, without regard to its principles of conflict of laws. You and Guru Screener agree to submit to the personal and exclusive
          jurisdiction of the federal and state courts located within New York County, New York, and waive any jurisdictional, venue, or inconvenient
          forum objections to such courts. If any provision of the TOU is found to be unlawful, void, or for any reason unenforceable, then that
          provision shall be deemed severable from the TOU and shall not affect the validity and enforceability of any remaining provisions. No waiver
          by either party of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. Any
          heading, caption or section title contained in the TOU is inserted only as a matter of convenience and in no way defines or explains any
          section or provision hereof. We reserve the right to require you to sign a non-electronic version of the TOU.
        </p>
      </div>
    </div>
  );
};

export default TermsOfUse;
