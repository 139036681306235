import React from "react";

const BacktestDocumentation = () => {
  return (
    <div className="screener-article">
      <h1>Everything you need to know about backtesting</h1>
      <h2> What is a backtest for a Stock Market Screener ?</h2>
      <p>
        Backtesting is the general method for seeing how well a strategy or model would have done in the past. Backtesting assesses the viability of a
        trading strategy by discovering how it would play out using historical data. If backtesting works, traders and analysts may have the
        confidence to employ it going forward. Unfortunately backtesting is not a proof and it must be used carefully.
      </p>

      <h2>What are the limitations of the backtest and how to avoid it ?</h2>
      <p className="mb-5">
        No backtest could replicate the futures results, but some backtesting give you interesting clue. It is hard to draw a line between a good and
        a bad backtest. Nevertheless, there are some question to know if a backtest is relevant or not :
      </p>
      <ul className="mb-5 ml-10">
        <li className="font-semibold">1. Is my database for test large enough.</li>
        <li className="font-semibold">2. Are my results large enough.</li>
        <li className="font-semibold">3. Does my data contains hints of future ? </li>
        <li className="font-semibold">4. Did I separate the training and validation part ? </li>
      </ul>

      <p className="mb-5">
        The 1. and 2. conditions are based on statistics. You need to have enough data to minimize randomness. For instance, if you test a strategy
        with thousands of conditions, and it gives you only one performing company to buy. Should you trust this backtest ?
      </p>
      <p className="mb-5">
        The 3. conditions try to know if a backtest is "repainted", it means that you use (by mistake) some datas you are not supposed to know in
        advance. So basically, you tried to predict the future by giving some hints of the future. This is the most common mistake when backtesting
        and it could be really hard to find. For instance, you want to test the Strategy X on the US Market, so you decide to backtest it from 1990 to
        2000. Amazing, your backtest shows that during this period, you found Apple Inc, Microsoft Inc. But, you forget that you use a current
        database from 2024, by doing so, you are already adding the following information in your backtest "The company is good enough to last from
        1990 to 2024".
      </p>
      <p className="mb-5">
        The 4. condition is something quite logic and you can find in all model creation (espacially neural network). It says that to have a correct
        model you need to have a separate dataset for the training and for the validation. For backtest, you should use a different period than the
        one you use to find your strategy.
      </p>

      <h2>How the backtesting is working at Guru Screener ? </h2>

      <p className="mb-5">
        At GuruScreener, we believe that users should have a complete access to data to test what they believe in. At the inverse, we want to avoid
        sharing performance review of strategy that may misrepresent the reality. Because everyone has a different investing style, you should create
        your own backtest.
      </p>

      <p className="mb-5">
        For every Strategy screener, a back test mode is available. This backtest mode is like a time-travel machine at a specific time in the past.
        GuruScreener give you all assets that matched the strategy at this period. By selecting the assets you want to put in the portfolio, you could
        plot the past performance.
      </p>
    </div>
  );
};

export default BacktestDocumentation;
