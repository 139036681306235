import React from "react";

const AboutUsPage = () => {
  return (
    <div>
      <h1>About Guru Screener</h1>
      <p>
        GuruScreener is developed by a team of engineers who are passionate about computer science and finance. After years of interest in both
        fields, we decided to merge these 2 great fields to create a solution for investors. The first time we read Benjamin Graham and listened to
        Warren Bufett interview when he said "Every day I read the financial report of a company", we asked ourselves: "Can an algorithm read this
        report for us?"
      </p>
      <p className="mt-5"> This is when Guru Screener was born.</p>
    </div>
  );
};

export default AboutUsPage;
