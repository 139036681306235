import React from "react";
import { MathJaxContext, MathJax } from "better-react-mathjax";
const GrahamDefensive = () => {
  return (
    <MathJaxContext>
      <div className="screener-article">
        <h3>7 Essential Rules to Master Graham’s Defensive Investment : The Complete Guide</h3>
        <p>
          When it comes to the world of investment, the name Benjamin Graham is widely recognized, particularly through the endorsement of Warren
          Buffet. Even though Graham’s books were written decades ago, they continue to serve as a valuable and insightful guide for selecting stocks.
          In his writings, Graham outlined an algorithm consisting of eight key points that can be utilized to identify what he referred to as
          “Defensive” Stocks.
        </p>
        <p>
          According to Graham, defensive stocks are considered to be the highest quality stocks and are strongly recommended by him. On the other
          hand, stocks with greater risks are classified as “Enterprising.” In order to qualify as a defensive stock, certain requirements need to be
          met.
        </p>

        <p>
          <b>These requirements include the following:</b>
        </p>

        <ul className="mb-5">
          <li>1. Not less than USD 100 million of annual sales. (Corrected to USD 500M with inflation)</li>
          <li>2-A. Current assets should be at least twice current liabilities.</li>
          <li>2-B. Long-term debt should not exceed the net current assets.</li>
          <li>3. Some earnings for the common stock in each of the past ten years.</li>
          <li>4. Uninterrupted [dividend] payments for at least the past 20 years.</li>
          <li>
            5. A minimum increase of at least one-third in per-share earnings in the past ten years using three-year averages at the beginning and
            end.
          </li>
          <li>6. Current price should not be more than 15 times average earnings of the past three years.</li>
          <li>7. Current price should not be more than 1½ times the book value.</li>
        </ul>
        <p>As a rule of thumb we suggest that the product of the multiplier times the ratio of price to book value should not exceed 22.5.</p>

        <h4 className="text-primary font-semibold">Graham’s Rule N°1 : Size in Sales</h4>
        <p>
          Choosing the perfect stock requires careful consideration of the company’s size. According to Graham, the size of a company plays a vital
          role in mitigating the risk of it vanishing, which, in turn, can have an impact on your investment. It’s worth noting that Graham’s book,
          when adjusted for inflation, now suggests a benchmark of USD 500 million in total revenue, as opposed to the original 100 million.
        </p>

        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">{"\\( \\text{Size in Sales}=\\frac{\\text{Total Revenu}}{\\text{500M}} \\)"}</MathJax>{" "}
        </p>
        {/* <p>Example for Apple (AAPL) :</p>
         <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
        <MathJax className="mb-5 mt-5 text-primary ml-5">
          {"\\( \\text{Size in Sales}=\\frac{\\text{Total Revenu}}{\\text{500M}}=\\text{78 865%} \\)"}
        </MathJax> </p> */}
        <p>
          The rules for different sectors like Utilities and Financials have been formulated in various ways. They put more emphasis on Assets rather
          than Sales.
        </p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">{"\\( \\text{Size in Sales}=\\frac{\\text{Total Assets}}{\\text{500M}} \\)"}</MathJax>{" "}
        </p>
        <h4 className="text-primary font-semibold">Graham’s Rule N°2 : Asset/Liabilities</h4>
        <p>
          Rule number 2 is closely connected to the Book Value, which determines whether the balance favors Assets or Liabilities. This rule has been
          formulated in two different ways, depending on the sector.
        </p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">
            {"\\( \\text{Rule 2A}=\\frac{\\text{Current Assets}}{2*\\text{Current Liabilities}} \\)"}
          </MathJax>{" "}
        </p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">
            {"\\( \\text{Rule 2B}=\\frac{\\text{Net Current Assets}}{\\text{Long-Term Debt}} \\)"}
          </MathJax>{" "}
        </p>
        {/* <p>Example for Apple (AAPL) : </p>
         <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
        <MathJax className="mb-5 mt-5 text-primary ml-5">{"\\( \\text{Size in Sales}=\\frac{\\text{Total Revenu}}{\\text{500M}} \\)"}</MathJax> </p>
         <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
        <MathJax className="mb-5 mt-5 text-primary ml-5">{"\\( \\text{Size in Sales}=\\frac{\\text{Total Revenu}}{\\text{500M}} \\)"}</MathJax> </p>
        */}

        <p>The alternative for Utilities and Financials is : </p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">{"\\( \\text{Rule 2}=\\frac{2*\\text{Equity}}{\\text{Debt}} \\)"}</MathJax>{" "}
        </p>
        <h4 className="text-primary font-semibold">Graham’s Rule N°3 : Earning Stability</h4>
        <p>
          When it comes to Defensive companies, Graham suggests that they should have a track record of consistently making profits over the last ten
          years. This means that they've managed to maintain a positive earnings trend over a significant period of time.
        </p>

        <p>
          By adhering to this criterion, Defensive companies demonstrate their ability to weather economic storms and remain stable in terms of
          financial performance. It's like having a strong shield against market uncertainties.
        </p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">
            {"\\( \\text{Earning Stability}=\\text{10%}*\\text{Continuous Years of Positive Earning} \\)"}
          </MathJax>{" "}
        </p>
        {/* <p>Example for Apple (AAPL) : For the last 12 years, Apple show a positive earning, so well that : </p>
         <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
        <MathJax className="mb-5 mt-5 text-primary ml-5">{"\\( \\text{Size in Sales}=\\frac{\\text{Total Revenu}}{\\text{500M}} \\)"}</MathJax> </p> */}

        <h4 className="text-primary font-semibold">Graham’s Rule N°4 : Dividend Record</h4>
        <p>
          Just like Rule N°3, another important criterion for a defensive company is its consistent payment of dividends over a period of at least 20
          years. This means that year after year, the company has been sharing its profits with its shareholders through dividend payments.
        </p>
        <p>
          By fulfilling this requirement, a defensive company showcases its commitment to providing a steady income stream to its investors. It
          demonstrates financial stability and reliability over an extended period of time.
        </p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">
            {"\\( \\text{Dividend Record}=\\text{5%}*\\text{Continuous Years of Paid Dividend} \\)"}
          </MathJax>{" "}
        </p>
        {/* <p>Example for Apple (AAPL) : For the last 11 years, Apple has paid dividends.</p>
         <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
        <MathJax className="mb-5 mt-5 text-primary ml-5">{"\\( \\text{Size in Sales}=\\frac{\\text{Total Revenu}}{\\text{500M}} \\)"}</MathJax> </p> */}

        <h4 className="text-primary font-semibold">Graham’s Rule N°5 : Earnings Growth :</h4>

        <p>
          In Graham's perspective, a defensive company is expected to demonstrate continuous improvements over time. Specifically, when it comes to
          growth, the company's current earnings should be at least 33% higher than its earnings from a decade ago. To ensure greater accuracy, Graham
          advises analyzing the three-year moving average of earnings.
        </p>

        <p>With E(T) the earnings at the Year T </p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">
            {"\\(  E_\\text{mean}(Today)=\\frac{  E(Today) + E(Today-1Y) +E(Today-2Y)  }{3} \\)"}
          </MathJax>{" "}
        </p>

        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">{"\\(  E_\\text{mean}(10Y)=\\frac{E(10Y) + E(11Y) +E(12Y)}{3} \\)"}</MathJax>{" "}
        </p>

        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">
            {"\\( \\text{Growth (%)}=\\frac{E_\\text{mean}(Today) - E_\\text{mean}(10Y) }{E_\\text{mean}(10Y)} \\)"}
          </MathJax>{" "}
        </p>

        <p>
          <strong>Remark </strong>: A growth of 100% means the company had 33% growth in earning in 10 years.
        </p>
        {/* <p>Example for Apple (AAPL) : Earning Today = 3.95 and Earning 10Y = 1.33</p>

         <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
        <MathJax className="mb-5 mt-5 text-primary ml-5">{"\\( \\text{Size in Sales}=\\frac{\\text{Total Revenu}}{\\text{500M}} \\)"}</MathJax> </p> */}

        <h4 className="text-primary font-semibold">Graham’s Rule N°6 & 7: The Graham Number</h4>
        <p>
          In addition to the criteria of financial stability and growth, Graham also emphasized the importance of considering the price of a company.
          He believed that a comprehensive analysis must take into account not only the company's fundamentals but also its valuation.
        </p>

        <p>
          According to Graham, when it comes to defensive companies, the price paid should adhere to certain guidelines. Firstly,{" "}
          <strong>the price should not exceed 15 times the average earnings of the past three years</strong>. This ensures that the price is
          reasonable and doesn't overvalue the company based on its recent earnings performance.
        </p>

        <p>
          Secondly, Graham suggests that the price paid should <strong>not exceed one half of the book value of the company</strong>. This criterion
          takes into account the company's net assets and provides a benchmark for evaluating the reasonableness of the price.
        </p>

        <p>
          Despite being presented in separate paragraphs, Graham advises <strong>combining Rule number 6 and Rule number 7</strong> to compensate for
          each other. Through mathematical factorization, we can express the following statement:
        </p>

        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">
            {"\\( \\text{Graham Number}=\\sqrt{22.5*\\text{EPS}_\\text{mean}*\\text{BVPS}} \\)"}
          </MathJax>{" "}
        </p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">
            {"\\( \\text{Graham Number (%)}=\\frac{\\text{Graham Number}}{\\text{Stock Price}} \\)"}
          </MathJax>{" "}
        </p>

        {/* <p>For Apple (AAPL), the Graham Number is the following : </p>

 <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">        
<MathJax className="mb-5 mt-5 text-primary ml-5">{"\\( \\text{Size in Sales}=\\frac{\\text{Total Revenu}}{\\text{500M}} \\)"}</MathJax> </p>
         <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
        <MathJax className="mb-5 mt-5 text-primary ml-5">{"\\( \\text{Size in Sales}=\\frac{\\text{Total Revenu}}{\\text{500M}} \\)"}</MathJax> </p> */}

        <p>
          The Graham Number holds an intriguing significance. It can be defined as the intrinsic value of a company, determined by considering both
          its book value (assets) and its earnings. In the case of Apple, Graham indicates an intrinsic value of 18.91 USD. If the stock price exceeds
          this value, it suggests the company may be overvalued
        </p>
        <h4 className="text-primary font-semibold">Common mistakes to Defensive Approach:</h4>
        <p>
          Navigating financial data algorithms can be prone to errors, potentially leading to incorrect conclusions that can significantly impact your
          investment decisions. As highlighted in various articles, it's crucial to be mindful of two common mistakes to steer clear of.
        </p>
        <p>
          While attempting to translate ideas into equations, simplifications are often made. However, when delving into the realm of mathematics, it
          is important to consider the rules that we already know.
        </p>
        <p>
          One such rule is that the multiplication and division of two negative numbers yield a positive result. Let's imagine a hypothetical company,
          X, trading at 20 USD on the stock market. If company X exhibits poor performance, such as an earnings per share of -5 USD and a book value
          per share of -10 USD, applying the Graham Number (%) formula will yield the following outcome:
        </p>

        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">{"\\( \\text{Graham Number}=\\sqrt{22.5*(-5)*(-10)}=33.5  \\)"}</MathJax>{" "}
        </p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">
            {"\\( \\text{Graham Number (%)}=\\frac{\\text{33}}{\\text{20}}=167.7\\text{%}\\)"}
          </MathJax>{" "}
        </p>

        <p>
          It's common for many private investors to overlook how the stock market operates. When purchasing company shares, they are bought through an
          exchange (such as NYSE, NASDAQ, or Euronext). However, the country and currency of the exchange may not necessarily align with the company's
          operations. For example, Apple shares are available on the Mexican Exchange and can be purchased in Mexican Pesos, but the company's
          financial statements are still expressed in US Dollars.
        </p>
        <p>
          Failing to consider the conversion rates when applying formulas can lead to false positives in investment algorithms. This becomes even more
          significant when dealing with American Depositary Receipts (ADRs) in the US market. Many brokers offer ADRs as a convenient way to invest in
          foreign companies. However, it's important to remember that you are making conclusions based on a US Dollar stock price with financial
          statements in foreign currencies like Yen or Euro.
        </p>

        <p>
          Therefore, it's crucial to consider currency conversions and ensure proper alignment between the stock price and the currency in which the
          financial statements are reported to avoid misleading conclusions and inaccuracies in investment evaluations.
        </p>
        {/* <h4 className="text-primary font-semibold">Conclusion and Graham Rating :</h4>
        <p>
          A stock is deemed defensive or, in other words, a promising investment opportunity{" "}
          <strong>if and only if it satisfies all the specified conditions</strong>. The formula outlined in this article indicates that all the
          values involved must be greater than or equal to 100%.
        </p> */}
      </div>
    </MathJaxContext>
  );
};

export default GrahamDefensive;
