import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import GoogleLogo from "../../../assets/logo/googleIcon.svg";
import { googleProvider, auth } from "../../../firebase/firebase";
import ImageScreener from "../../../assets/image/screener.png";
import ImageScreenerDark from "../../../assets/image/screener-dark.png";
import { createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";

const Signup = () => {
  const [currentUser, setCurrentUser] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [inApp, setInApp] = useState(false);

  const navigate = useNavigate();

  // Check if embedded in Facebook App
  // useEffect(() => {
  //   // return <Navigate to="/" />;
  // }, [currentUser]);

  useEffect(() => {
    setInApp(isFacebookApp());
  }, []);

  function isFacebookApp() {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1;
  }

  const notifyError = (text) =>
    toast.error(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const handleCreateAccount = (e) => {
    e.preventDefault();
    const { email, password } = e.target.elements;

    try {
      createUserWithEmailAndPassword(auth, email.value, password.value)
        .then((userCredential) => {
          userCredential.user.sendEmailVerification();
          setTimeout(() => {
            navigate("/");
          }, 200);
        })
        .catch((err) => {
          notifyError("The email address is already in use by another account.");
        });
    } catch (error) {
      notifyError("Something went wrong");
    }
  };

  const SignInWithGoogle = () => {
    if (inApp) {
      setTimeout(() => {
        toast.error("Authentification with Google not allowed within the Facebook/Messenger/Instagram app. Open the site in your default browser.");
      }, 200);
    } else {
      signInWithPopup(auth, googleProvider)
        .then(() => {
          setTimeout(() => {
            navigate("/");
          }, 200);

          setCurrentUser(true);
        })
        .catch((err) => {
          notifyError();
        });
    }
  };
  return (
    <>
      <div className="mx-auto max-w-screen-xl overflow-hidden bg-base-100 dark:bg-slate-800 rounded-lg lg:pb-10   ring-1 ring-slate-900/5 dark:ring-slate-900/60 shadow-xl shadow-slate-300/10 dark:shadow-xl  ">
        <div className="m-0 flex justify-center flex-1">
          <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
            <div className="lg:mt-12 flex flex-col items-center">
              <h1 className="text-2xl xl:text-3xl font-extrabold">Join Other Investors</h1>

              <div className="w-full flex-1 mt-8">
                <div className="flex flex-col items-center">
                  <button
                    className="btn w-full max-w-xs btn-primary-content  mt-5 tracking-wide font-semibold flex items-center justify-center"
                    onClick={SignInWithGoogle}
                  >
                    <div className="bg-base-100 p-2 rounded-full">
                      <svg className="w-4" viewBox="0 0 533.5 544.3">
                        <path
                          d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                          fill="#4285f4"
                        />
                        <path
                          d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                          fill="#34a853"
                        />
                        <path d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z" fill="#fbbc04" />
                        <path
                          d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                          fill="#ea4335"
                        />
                      </svg>
                    </div>
                    <span className="ml-4">Sign Up with Google</span>
                  </button>
                </div>

                <div className="my-12 divider tracking-wide font-medium text-base-content/50 ">Or Sign Up with e-mail</div>

                <form className="mx-auto max-w-xs" onSubmit={handleCreateAccount}>
                  <input className="input input-bordered w-full px-8 py-4 rounded-lg font-medium " type="email" name="email" placeholder="Email" />
                  <input
                    className="input input-bordered w-full px-8 py-4 rounded-lg font-medium mt-5"
                    type="password"
                    name="password"
                    placeholder="Password"
                  />

                  <button className="btn  btn-primary w-full mt-5 tracking-wide font-semibold flex items-center justify-center hover:shadow-lg hover:shadow-primary/40">
                    <svg className="w-6 h-6 -ml-2" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                      <circle cx="8.5" cy="7" r="4" />
                      <path d="M20 8v6M23 11h-6" />
                    </svg>
                    <span className="ml-3">Create an Account</span>
                  </button>

                  <span className="text-sm mt-5 flex items-center justify-center ">
                    Already have an account?{" "}
                    <Link to="/login" className="ml-2 border-b border-b-primary hover:border-b-2 font-semibold">
                      Log In
                    </Link>
                  </span>

                  <p className="mt-6 text-xs text-base-content/80  text-center">
                    By creating an account, I agree the{" "}
                    <Link
                      to={`${process.env.PUBLIC_URL}/termsofuse`}
                      className="border-b border-base-content/80 border-dotted hover:border-primary hover:text-primary"
                    >
                      Terms of Use
                    </Link>{" "}
                    and its{" "}
                    <Link
                      to={`${process.env.PUBLIC_URL}/privacy`}
                      className="border-b border-base-content/80 border-dotted hover:border-primary hover:text-primary "
                    >
                      Privacy Policy
                    </Link>{" "}
                  </p>
                </form>
              </div>
            </div>
          </div>
          <div className="flex-1 text-center hidden lg:flex -mt-10 -mr-10 -mb-10">
            <div className="w-full headerscreener1 dark:headerscreener1-dark justify-content-center flex">
              <div className="overflow-hidden gap-8 items-center xl:gap-16 md:flex  lg:px-6">
                <div className="mt-8 md:mt-0 basis-1/4">
                  <h2 className="mb-4 text-4xl tracking-tight font-extrabold min-w-60">Don't miss the next Apple or NVDIA</h2>
                  <p className="mt-2 text-lg font-semibold text-sky-500 dark:text-base-content">Easy-to-use.</p>
                  <p className="mt-2 text-lg font-semibold text-pink-500 dark:text-pink-400">Famous Strategies.</p>
                  <p className="mt-2 text-lg	font-semibold text-indigo-500 dark:text-indigo-400">Backtested Strategies.</p>
                  <p className="py-0 mb-6 text-base-content/90">
                    <br />
                    <br />
                  </p>
                </div>

                <img className=" shadow-lg rounded-lg border dark:hidden " src={ImageScreener} alt="image" />
                <img className="w-full shadow-lg rounded-lg  hidden dark:flex" src={ImageScreenerDark} alt="image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
