import { useQuery } from "@tanstack/react-query";
import { getScreener } from "../functionsBackEnd/functions";
import screenerService from "../services/screenerService";
import peopleService from "../services/peopleService";

export const useScreener = ({ screenerName, user, subscription, typeOfScreener }) => {
  return useQuery({
    queryKey: ["screener", screenerName],
    // queryFn: () => getScreener({ screenerName, user, subscription, typeOfScreener }),

    queryFn: () => {
      if (typeOfScreener === "book") {
        return screenerService.get({
          params: {
            name: screenerName,
          },
        });
      }

      if (typeOfScreener === "people") {
        return peopleService.get({
          params: {
            name: screenerName,
          },
        });
      }
    },
    refetchOnWindowFocus: false,
    staleTime: 600000,
  });
};
