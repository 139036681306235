import React from "react";
import { auth, firebase_app } from "../../../firebase/firebase";
// import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { sendPasswordResetEmail } from "firebase/auth";

const PasswordReset = () => {
  const handleResetPassword = (e) => {
    e.preventDefault();
    const { email } = e.target.elements;
    if (email.value) {
      sendPasswordResetEmail(auth, email.value)
        .then(() => {
          toast.success("An email has been sent to your adress", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch(() => {
          toast.error("Error, check your email", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      toast.error("Error, check your email", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <div>
      <div className="mx-auto max-w-screen-md overflow-hidden bg-base-100 dark:bg-slate-800 rounded-lg lg:pb-10   ring-1 ring-slate-900/5 dark:ring-slate-900/60 shadow-xl shadow-slate-300/10 dark:shadow-xl  ">
        <div className="m-0 flex justify-center flex-1">
          <div className="my-12 flex flex-col items-center">
            <h1 className="text-2xl xl:text-3xl font-extrabold">Reset my password</h1>

            <div className="w-full flex-1 mt-8">
              <form className="mx-auto max-w-xs" onSubmit={handleResetPassword}>
                <input className="input input-bordered w-full px-8 py-4 rounded-lg font-medium " type="email" name="email" placeholder="Email" />

                <button className="btn btn-primary w-full mt-5 tracking-wide font-semibold flex items-center justify-center">
                  <FontAwesomeIcon icon={faEnvelope} className="" />
                  <span className="ml-3">Reset my password</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="relative flex flex-col items-center justify-center h-screen overflow-hidden">
        <div className="w-full p-6  bg-white dark:bg-slate-800 rounded-lg px-6 py-8 ring-1 ring-slate-900/5 shadow-xl lg:max-w-lg">
          <h1 className="text-3xl font-semibold text-center text-base-700">Reset my password</h1>

          <form className="space-y-4" onSubmit={handleResetPassword}>
            <div>
              <label className="label">
                <span className="text-base label-text">Email</span>
              </label>
              <input type="text" placeholder="Email Address" name="email" className="w-full input input-bordered" />
            </div>

            <div>
              <button className="btn btn-block btn-secondary">Reset my password</button>
            </div>
          </form>
        </div>
      </div> */}
    </div>
  );
};

export default PasswordReset;
