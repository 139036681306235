import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAtAa0keqYfm1nqlwcuk-d_WvnmKEb9Fic",
  authDomain: "guruscreener-io.firebaseapp.com",
  databaseURL: "https://guruscreener-io-default-rtdb.firebaseio.com",
  projectId: "guruscreener-io",
  storageBucket: "guruscreener-io.appspot.com",
  messagingSenderId: "1065037220248",
  appId: "1:1065037220248:web:b346f0dcba6542a8bb43ed",
  measurementId: "G-NWKEGF4K7Q",
};

// Initialize Firebase
export const firebase_app = initializeApp(firebaseConfig);
export const db = getFirestore(firebase_app);
export const auth = getAuth(firebase_app);
export const googleProvider = new GoogleAuthProvider();
const analytics = getAnalytics(firebase_app);

// getFirestore
