import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faFish, faLaptop, faMagnifyingGlassChart, faServer, faStopwatch, faTableList } from "@fortawesome/free-solid-svg-icons";

const Specifications = () => {
  return (
    <>
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className="max-w-screen-md mb-8 lg:mb-16">
          <p className="mt-8 text-lg	font-semibold text-indigo-500 dark:text-indigo-400">Features</p>
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold">More than just a Stock Screener</h2>
          {/* <p className="">Discover a World of Winning Strategies</p> */}
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
          <div className="group">
            <div className="flex justify-center items-center mb-4 w-10 h-10 lg:h-12 lg:w-12 group">
              <FontAwesomeIcon icon={faBook} className="text-primary text-4xl group-hover:text-accent  transition-all"></FontAwesomeIcon>
            </div>
            <h3 className="mb-2 text-xl font-bold">Fit Your Style</h3>
            <p>Choose from Risk, Performance or Category. There is always a screen for you.</p>
          </div>
          <div className="group">
            <div className="flex justify-center items-center mb-4 w-10 h-10 lg:h-12 lg:w-12 ">
              <FontAwesomeIcon icon={faServer} className="text-primary text-4xl group-hover:text-accent transition-all"></FontAwesomeIcon>
            </div>
            <h3 className="mb-2 text-xl font-bold">100% Quality Stocks</h3>
            <p>We filter our data to propose only quality stocks that are available to retail investors through top exchanges and brokers.</p>
          </div>
          <div className="group">
            <div className="flex justify-center items-center mb-4 w-10 h-10 lg:h-12 lg:w-12 ">
              <FontAwesomeIcon icon={faFish} className="text-primary text-4xl group-hover:text-accent transition-all"></FontAwesomeIcon>
            </div>
            <h3 className="mb-2 text-xl font-bold">Fresh Data</h3>
            <p>We continuously scan the market to propose the last opportunities. Get fresh data every time the market opens.</p>
          </div>
          <div className="group">
            <div className="flex justify-center items-center mb-4 w-10 h-10 lg:h-12 lg:w-12 ">
              <FontAwesomeIcon icon={faStopwatch} className="text-primary text-4xl group-hover:text-accent transition-all"></FontAwesomeIcon>
            </div>
            <h3 className="mb-2 text-xl font-bold">Know When to Sell</h3>
            <p>For each stock screener, we develop specific key insights. These insights let you know when to sell.</p>
          </div>
          <div className="group">
            <div className="flex justify-center items-center mb-4 w-10 h-10 lg:h-12 lg:w-12 ">
              <FontAwesomeIcon icon={faLaptop} className="text-primary text-4xl group-hover:text-accent transition-all"></FontAwesomeIcon>
            </div>
            <h3 className="mb-2 text-xl font-bold ">Customizable With Financial Metrics</h3>
            <p>Missed ROE, PE Ratio? Add financial ratios to the screener to get a custom view.</p>
          </div>
          <div className="group">
            <div className="flex justify-center items-center mb-4 w-10 h-10 lg:h-12 lg:w-12 ">
              <FontAwesomeIcon
                icon={faMagnifyingGlassChart}
                className="text-primary text-4xl group-hover:text-accent transition-all"></FontAwesomeIcon>
            </div>
            <h3 className="mb-2 text-xl font-bold">Understand What You Invest In</h3>
            <p>Get important information about the investment you're about to make.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Specifications;
