import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";

import React from "react";

const Standard = () => {
  return (
    <>
      <h3 className="text-white">Technical Sell Signs </h3>
      <div className="grid md:grid-cols-2  gap-3 my-3 lg:my-5 ">
        <div className="flex hover:ring hover:ring-slate-500/60 rounded-lg duration-300 py-5 px-5 group">
          <div className="text-white/70   flex  mx-auto  lg:mb-5 group-hover:text-white duration-300">
            <FontAwesomeIcon className="fa-2x mt-1" icon={faHashtag} /> <span className="text-4xl ml-2 "> 1</span>
          </div>
          <div className="ml-4 ">
            <h4 className="text-xl tracking-tight font-extrabold text-white py-0">Increase in consecutive down days</h4>
            <div className="group-hover:text-white duration-300">
              For most stocks, the number of consecutive down days in price relative to up days in price will probably increase when the stock starts
              down from its top
            </div>
          </div>
        </div>

        <div className="flex hover:ring hover:ring-slate-500/60 rounded-lg duration-300 py-5 px-5 group">
          <div className="text-white/70   flex  mx-auto  lg:mb-5 group-hover:text-white duration-300">
            <FontAwesomeIcon className="fa-2x mt-1" icon={faHashtag} /> <span className="text-4xl ml-2 "> 2</span>
          </div>
          <div className="ml-4 ">
            <h4 className="text-xl tracking-tight font-extrabold text-white py-0">200-day moving average line</h4>
            <div className="group-hover:text-white duration-300">
              When some stocks are 70% to 100% or more above their 200-day moving average price line, you should sold.
            </div>
          </div>
        </div>

        <div className="flex  hover:ring hover:ring-slate-500/60 rounded-lg duration-300 py-5 px-5 group">
          <div className="text-white/70   flex  mx-auto  lg:mb-5 group-hover:text-white duration-300">
            <FontAwesomeIcon className="fa-2x mt-1" icon={faHashtag} /> <span className="text-4xl ml-2 "> 3</span>
          </div>
          <div className="ml-4 ">
            <h4 className="text-xl tracking-tight font-extrabold text-white py-0">New high on low volume</h4>
            <div className="group-hover:text-white duration-300">
              Some stocks will make new highs on lower or poor volume. As the stocks goes higher, volume trends lower, suggesting that big investor
              have lost their appetite for the stock.
            </div>
          </div>
        </div>

        <div className="flex  hover:ring hover:ring-slate-500/60 rounded-lg duration-300 py-5 px-5 group">
          <div className="text-white/70   flex  mx-auto  lg:mb-5 group-hover:text-white duration-300">
            <FontAwesomeIcon className="fa-2x mt-1" icon={faHashtag} /> <span className="text-4xl ml-2 "> 4</span>
          </div>
          <div className="ml-4 ">
            <h4 className="text-xl tracking-tight font-extrabold text-white py-0">Decline from the peaks</h4>
            <div className="group-hover:text-white duration-300">You may sell if a decline from the peak exceed 12% or 15%.</div>
          </div>
        </div>

        <div className="flex  hover:ring hover:ring-slate-500/60 rounded-lg duration-300 py-5 px-5 group">
          <div className="text-white/70   flex  mx-auto  lg:mb-5 group-hover:text-white duration-300">
            <FontAwesomeIcon className="fa-2x mt-1" icon={faHashtag} /> <span className="text-4xl ml-2 "> 5</span>
          </div>
          <div className="ml-4 ">
            <h4 className="text-xl tracking-tight font-extrabold text-white py-0">Living below the 10-week moving average</h4>
            <div className="group-hover:text-white duration-300">
              Consider selling if a stocks has a long advance, then closes below it's 10-week moving average and lives below that average for at least
              8 consecutive weeks.
            </div>
          </div>
        </div>
      </div>
      <div className="mx-4 mt-2">
        If you don't sell early, you'll be late. Your object is to make and take significant gains and not get excited, optimistic, greedy, or
        emotionally carried away as your stock's advance gets stronger.
        <br />
        Keep in mind the old saying :<b>" Bulls make money and bears make money, but pigs get slaughtered."</b>
      </div>
    </>
  );
};

export default Standard;
