import React, { useContext, useEffect, useState } from "react";
import { dataScreener } from "./data/dataScreenerV2";
import { Helmet } from "react-helmet";
import Faq from "../../components/FaqLanding";
import CardScreener from "../../common/CardScreener";
import { useSearchParams } from "react-router-dom";

import { UserContext } from "../../context/usercontext";
import { useFavorites } from "./../../react-query/hooks/useFavorites";

const ScreenerPageHome = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [filterTrue, setFilterTrue] = useState([]);
  const [filterFalse, setFilterFalse] = useState([]);
  const { user } = useContext(UserContext);
  const queryFavorites = useFavorites(user);

  const params = {};
  for (let [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  const typeOfScreenerFilter = params["q1"] || "";
  const styleInvestingFilter = params["q2"] || "";

  const handleSelectPeopleBook = (event) => {
    const filter = event.target.value;
    setSearchParams({ q1: filter, q2: styleInvestingFilter });
  };

  const handleSelectInvesting = (event) => {
    const filter = event.target.value;
    setSearchParams({ q1: typeOfScreenerFilter, q2: filter });
  };
  const cleanFilter = () => {
    setSearchParams({});
  };

  useEffect(() => {
    filterScreener();
  }, [typeOfScreenerFilter, styleInvestingFilter, queryFavorites.isPending]);

  const filterScreener = () => {
    let filterTrue = [];
    let filterFalse = [];
    let favorites = [];
    // Sort the one in favorties
    if (!queryFavorites.isPending) {
      if (queryFavorites?.data) {
        favorites = queryFavorites.data;
      }
    }

    Object.keys(dataScreener).forEach((key) => {
      if (favorites.includes(key)) {
        let isFilter = false;
        if (typeOfScreenerFilter === "" || dataScreener[key].filters.includes(typeOfScreenerFilter)) {
          if (styleInvestingFilter === "" || dataScreener[key].filters.includes(styleInvestingFilter)) {
            isFilter = true;
          }
        }
        if (isFilter) {
          filterTrue.push(<CardScreener key={key} screenerName={key} favorites={queryFavorites} />);
        } else {
          filterFalse.push(<CardScreener key={key} screenerName={key} favorites={queryFavorites} />);
        }
      }
    });

    // Sort the one not in favorites

    Object.keys(dataScreener).forEach((key) => {
      if (!favorites.includes(key)) {
        let isFilter = false;
        if (typeOfScreenerFilter === "" || dataScreener[key].filters.includes(typeOfScreenerFilter)) {
          if (styleInvestingFilter === "" || dataScreener[key].filters.includes(styleInvestingFilter)) {
            isFilter = true;
          }
        }
        if (isFilter) {
          filterTrue.push(<CardScreener key={key} screenerName={key} favorites={queryFavorites} />);
        } else {
          filterFalse.push(<CardScreener key={key} screenerName={key} favorites={queryFavorites} />);
        }
      }
    });

    setFilterTrue(filterTrue);
    setFilterFalse(filterFalse);
  };

  return (
    <>
      {/* SEO Optimisation  */}
      <Helmet>
        <title>{`Discover the list of screener on GuruScreener.io`}</title>‍
        <meta name="description" content={`Discover the list of screener on GuruScreener.io`} />
        {/* <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@user" />
        <meta name="twitter:creator" content="@user" />
        <meta name="twitter:title" content="Pets - Products" />
        <meta name="twitter:description" content="Best Products for your pet" />
        <meta name="twitter:image" content="url_to_image" /> */}
        <meta property="og:title" content={`Discover the list of screener on GuruScreener.io`} />
        <meta property="og:description" content={`Discover the list of screener on GuruScreener.io`} />
        <meta property="og:image" content="url_to_image" />
        <meta property="og:url" content="guruscreener.io" />
        <meta property="og:site_name" content="GuruScreener.io" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        {/* <meta property="fb:app_id" content="ID_APP_FACEBOOK" /> */}
      </Helmet>

      <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#4cd964] to-[#34aadc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>

      <div className="mx-auto max-w-2xl pb-20 pt-5  lg:pb-40 lg:pt-20">
        <div className="text-center">
          <h1 className="text-3xl font-bold tracking-tight sm:text-3xl">Discover Guru Screeners</h1>
          <p className="mt-6 text-xl leading-8 text-base-content">Don't take time to read complicated books, take time to invest!</p>
        </div>
      </div>
      <div className="mx-auto">
        <div className="text-center">
          <p className="mx-auto">
            Filter:
            <select className="select select-bordered max-w-xs mx-2" value={typeOfScreenerFilter} onChange={handleSelectPeopleBook}>
              <option value="">Source</option>
              <option value="people">People</option>
              <option value="book">Book</option>
            </select>
            <select className="select select-bordered max-w-xs mx-2" value={styleInvestingFilter} onChange={handleSelectInvesting}>
              <option value="">Investing Style</option>
              <option value="value">Value</option>
              <option value="yield">Yield</option>
              <option value="quality">Quality</option>
              <option value="growth">Growth</option>
              <option value="momentum">Momentum</option>
              <option value="eToro">eToro</option>
              <option value="Robinhood">Robinhood</option>
            </select>
            <button className="btn btn-primary" onClick={cleanFilter} aria-label="clean filter">
              Delete Filter
            </button>
            {/* <select className="select select-bordered max-w-xs mx-2">
              <option>Latest</option>
              <option>Oldest</option>
              <option>Most Performing</option>
              <option>Less Risky</option>
            </select> */}
          </p>
        </div>
      </div>
      {(typeOfScreenerFilter !== "" || styleInvestingFilter !== "") && (
        <div className="mx-auto   pt-5 ">
          <div className="text-center">
            <p className="mt-6 text-lg text-base-content">Screener by selected Criteria: </p>
            {filterTrue.length === 0 && <p className="mt-6 text-lg font-light text-base-content">No Result </p>}
          </div>
        </div>
      )}
      {filterTrue.length > 0 && (
        <div className="flex justify-center mt-10 ">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 pb-6 gap-8 max-w-screen-xl auto-rows-auto">
            {filterTrue.map((e) => e)}
          </div>
        </div>
      )}

      {(typeOfScreenerFilter !== "" || styleInvestingFilter !== "") && (
        <div className="mx-auto max-w-2xl  pt-5 ">
          <div className="text-center">
            <p className="mt-6 text-lg leading-8 text-base-content">Discover All other Screeners : </p>
          </div>
        </div>
      )}
      <div className="flex justify-center mt-10 ">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 pb-6 gap-8 max-w-screen-xl auto-rows-auto">
          {filterFalse.map((e) => e)}
        </div>
      </div>
      <Faq />
    </>
  );
};

export default ScreenerPageHome;
