import React from "react";
import { Link } from "react-router-dom";
import { MathJax } from "better-react-mathjax";
const PEratio = () => {
  return (
    <div className="screener-article">
      <h1>P/E Ratio - Price To Earnings</h1>
      <h2>Short Definition</h2>
      <p>
        The P/E ratio is the primary valuation metric used by most equity investors. It is the price per share divided by the earnings per share. This
        is measured on a 1-year rolling basis, and earnings are diluted and normalized.
      </p>

      <h2>Formula of the Price to Earning (PE) Ratio</h2>
      <p className="text-xl mb-10">
        <MathJax>{"\\( \\text{PE}=\\frac{\\text{Stock Price}}{\\text{Earnings Per Share (EPS)}} \\)"}</MathJax>
      </p>
      <h2>Interpretation</h2>
      <p>
        A high P/E ratio means that investors are paying more for each unit of earnings, making the stock more expensive than one with a lower P/E
        ratio. The P/E ratio can be thought of as being expressed in years, in the sense that it shows the number of years of earnings that would be
        required to pay back the purchase price, ignoring inflation.
      </p>

      <p>
        Investors tend to overreact by getting excited about glamour stocks (making their P/Es too high) and disappointed by value stocks (making
        their P/Es too low). Research has shown that low P/E stocks tend to outperform high P/E stocks over the long term. On the other hand, there
        are many investors who believe that you should "pay for quality" in the same way that people pay for jewelry - so the best growth stocks
        rarely trade at cheap PE multiples. Unlike the EV/EBITDA multiple, which is capital structure neutral, the P/E ratio reflects the capital
        structure of the company. The inverse of the PE ratio is known as the
        <Link className="link-content" to={`${process.env.PUBLIC_URL}/docs/earnings-yield`}>
          {" earnings yield"}
        </Link>
        .
      </p>
    </div>
  );
};

export default PEratio;
