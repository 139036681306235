import { create } from "zustand";

const modalBugStore = create((set) => ({
  open: false,
  symbol: { symbol: "" },
  openAndClose: (symbol) => set((store) => ({ open: !store.open, symbol })),
}));

// mountStoreDevtool("Information Project Store", informationProjectStore);

export default modalBugStore;
