import { faChartLine, faListUl, faMugHot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Suspense, lazy, useContext } from "react";
import { Helmet } from "react-helmet";
import { useParams, useSearchParams } from "react-router-dom";
import Card from "../../common/Card";
import LoadingCard from "../../common/LoadingCard";
import TitleCard from "../../common/TitleCard";
import TitleScreenerCard from "../../common/TitleScreenerCard";
import { UserContext } from "../../context/usercontext";
import InANutshell from "./components/InANutshell";
import { dataScreener } from "./data/dataScreenerV2";
import WhenToSellComponent from "./whenToSell/WhenToSellComponent";
import { useFavorites } from "./../../react-query/hooks/useFavorites";
import { usePerformances } from "./../../react-query/hooks/usePerformances";
import { useScreener } from "./../../react-query/hooks/useScreener";
import ModalSymbol from "./modalSymbol/ModalSymbol";

const LazyScreenerCurve = lazy(() => import("./components/ScreenerCurveV2"));
const LazySimilarScreener = lazy(() => import("./components/SimilarScreener"));
const LazyLoadingTableBook = lazy(() => import("./components/ScreenerTable"));
const LazyLoadingTablePeople = lazy(() => import("./components/ScreenerTableGuru"));

const ScreenerPage = () => {
  const { screenerName } = useParams();

  const { name, author, description, article, whentosell, aboutTheBook, typeOfScreener, SEODescription, dataEndPoint, performances } =
    dataScreener[screenerName];

  const { user, hasSubscription } = useContext(UserContext);
  let [searchParams, setSearchParams] = useSearchParams();

  const filters = {};
  let extraTitle = "";
  for (let [key, value] of searchParams.entries()) {
    filters[key] = value;
    extraTitle = ` applied to ${value} sector`;
  }

  // Query
  const performancesEndPoint = dataEndPoint["perf"];
  const screenerEndPoint = dataEndPoint["screener"];
  const queryScreener = useScreener({ screenerName: screenerEndPoint, user, subscription: hasSubscription, typeOfScreener });
  const queryPerformance = usePerformances({ screenerName: performancesEndPoint, user, subscription: hasSubscription, typeOfScreener });
  const queryFavorites = useFavorites(user);

  return (
    <>
      {/* SEO Optimisation  */}
      <Helmet>
        <title>{`${name}${extraTitle} by ${author} - Stock Screener - GuruScreener`}</title>‍
        <meta name="description" content={`${SEODescription}`} />
        <meta property="og:title" content={`GuruScreener | ${name} screener`} />
        <meta property="og:description" content={`${SEODescription}`} />
        <meta property="og:url" content={`https://guruscreener.io/screener/${screenerName}`} />
        <meta property="og:site_name" content="GuruScreener.io" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta name="twitter:title" content={`GuruScreener | ${name} screener`} />
        <meta name="twitter:description" content={`${SEODescription}`} />
      </Helmet>

      <ModalSymbol />
      <div className="container ">
        <div className="lg:grid lg:grid-cols-12">
          {/* HEADER  */}
          <div className="col-start-1 col-end-13">
            <TitleScreenerCard screenerName={screenerName} user={user} favorites={queryFavorites} extra={extraTitle} />
          </div>

          {/* BODY  */}
          <div className="col-start-1 col-end-13  ">
            {typeOfScreener === "book" && (
              <>
                <div className="lg:grid lg:grid-cols-12">
                  <div className="col-start-1 col-end-10">
                    <TitleCard>
                      <FontAwesomeIcon className="text-lg mr-1" icon={faChartLine} /> Performance
                    </TitleCard>
                    <Suspense fallback={<LoadingCard />}>
                      <LazyScreenerCurve backtest={queryPerformance} performances={performances} />
                    </Suspense>
                  </div>
                  <div className="lg:col-span-3 lg:col-end-13">
                    <InANutshell data={{ screenerName, aboutTheBook, description, typeOfScreener }} />
                  </div>
                </div>
                <TitleCard>
                  <FontAwesomeIcon className="text-lg mr-1" icon={faListUl} /> Stock Picks
                </TitleCard>
                <Suspense fallback={<LoadingCard />}>
                  <LazyLoadingTableBook screenerName={screenerName} queryData={queryScreener} filters={filters} />
                </Suspense>
              </>
            )}

            {typeOfScreener === "people" && (
              <>
                <div className="lg:grid lg:grid-cols-12">
                  <div className="col-start-1 col-end-13">
                    <div className="lg:col-span-3 lg:col-end-13">
                      <InANutshell data={{ screenerName, aboutTheBook, description, typeOfScreener }} />
                    </div>
                    <TitleCard>Holdings of {name}</TitleCard>
                    <Suspense fallback={<LoadingCard />}>
                      <LazyLoadingTablePeople queryData={queryScreener} />
                    </Suspense>
                  </div>
                </div>
              </>
            )}
            {typeOfScreener === "financialRatio" && <></>}
          </div>

          {/* SECTION BASSE  */}

          <div className="col-start-1 col-end-13">
            {/* When to sell  */}
            <WhenToSellComponent hasSubscription={hasSubscription} whentosell={whentosell} />

            {/* Article sur le screener  */}
            <TitleCard>
              <FontAwesomeIcon className="text-lg mr-1" icon={faMugHot} /> {`All you need to know about ${name}`}
            </TitleCard>
            <Card>
              <div className="mx-2 lg:mx-10">{article}</div>
            </Card>
          </div>

          {/* Similar Screener en Lazy Loading  */}
          <Suspense fallback={<LoadingCard />}>
            <LazySimilarScreener data={{ dataScreener, screenerName }} />
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default ScreenerPage;
