import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BarPerformance from "./BarPerformance";
import TitleCard from "./../../../common/TitleCard";
import Card from "./../../../common/Card";
import { faChartLine, faCompass, faListUl, faMoneyBillWave, faMugHot } from "@fortawesome/free-solid-svg-icons";
import { faSourcetree } from "@fortawesome/free-brands-svg-icons";

const InANutshell = ({ data }) => {
  const { screenerName, aboutTheBook, description, typeOfScreener } = data;
  return (
    <>
      <TitleCard>
        <FontAwesomeIcon className="text-lg mr-1" icon={faCompass} /> In a Nutshell
      </TitleCard>

      <Card>
        {description}
        {typeOfScreener === "book" && <BarPerformance screenerName={screenerName} />}
      </Card>
      {/* 
      <TitleCard>
        <FontAwesomeIcon className="text-lg mr-1" icon={faSourcetree} /> About
      </TitleCard>

      <Card>
        <p>{aboutTheBook}</p>
      </Card> */}
    </>
  );
};

export default InANutshell;
