import { useQuery } from "@tanstack/react-query";
import { getPerformances } from "../functionsBackEnd/functions";
import performancesService from "../services/performancesService";

export const usePerformances = ({ screenerName }) => {
  return useQuery({
    queryKey: ["performances", screenerName],
    // queryFn: () => getPerformances({ screenerName }),
    queryFn: () => performancesService.get({ params: { name: screenerName } }),
    refetchOnWindowFocus: false,
    staleTime: 600000,
  });
};
