import React, { useEffect, useState } from "react";
import useBacktestingStore from "../../../store/backtesting/store";
import TableResultSimulation from "./TableResultSimulation";
import WinAndLossPosition from "./WinAndLossPosition";
import { useBacktest } from "../../../react-query/hooks/useBacktest";
import ChartResultBacktest from "./ChartResultBacktest";
import TitleCard from "./../../../common/TitleCard";
import LogBuyAndSell from "./LogBuyAndSell";
import Card from "../../../common/Card";

const ResultSimulation = () => {
  const { startSimulation, computeDone, setResults, conditions } = useBacktestingStore();
  const { data, mutate: mutateBacktest, isSuccess } = useBacktest();
  const [hasBeenCalculated, setHasBeenCalculated] = useState(false);

  useEffect(() => {
    if (startSimulation === true) {
      computeDone();
      setResults([]);
      mutateBacktest({ data: conditions });
    }
  }, [startSimulation]);

  useEffect(() => {
    if (isSuccess) {
      console.log(data);
      setResults(data);
      setHasBeenCalculated(true);
    }
  }, [data, isSuccess]);

  if (hasBeenCalculated) {
    return (
      <div className="grid  grid-cols-12">
        <div className="">
          <div className="">
            <TitleCard>Results of the Backtesting</TitleCard>
          </div>
        </div>
        {/* <div className="md:grid md:grid-cols-4"> */}
        <div className="md:col-span-1">
          <Card>
            <WinAndLossPosition />
          </Card>
        </div>
        <div className="md:col-span-3 grid-cols-12">
          <ChartResultBacktest />
        </div>
        {/* </div> */}

        <div className="grid-cols-12">
          <TitleCard>Logging Simulation</TitleCard>

          <TableResultSimulation />

          <LogBuyAndSell />
        </div>
      </div>
    );
  }
};

export default ResultSimulation;
