import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://guruscreener.io/api",
});

class APIClient {
  endpoint;
  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  get = (data) => {
    return axiosInstance.get(this.endpoint, data).then((res) => {
      return res.data;
    });
  };

  post = (data) => {
    return axiosInstance.post(this.endpoint, data).then((res) => res.data);
  };
}

export default APIClient;
