import React, { useEffect, useState } from "react";
import useBacktestingStore from "../../../store/backtesting/store";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownLong, faUpLong } from "@fortawesome/free-solid-svg-icons";
import Card from "../../../common/Card";

function add(accumulator, a) {
  return accumulator + a;
}

const TableResultSimulation = () => {
  const { results, years } = useBacktestingStore();
  const [performances, setPerformances] = useState([]);
  // Calculate Performance

  useEffect(() => {
    let performances = [];
    if (Object.keys(results).length > 0) {
      performances = years.map((year) => {
        const total = results[year].map((element) => {
          const prices = element["price"];
          return parseInt((10000 * (prices[prices.length - 1] - prices[0])) / prices[0]) / 100;
        });

        return total.reduce(add, 0) / total.length;
      });
    }
    setPerformances(performances);
  }, [results]);

  return (
    <Card className="">
      <div className="overflow-x-scroll">
        <table className="table">
          {/* head */}
          <thead>
            <tr>
              {years.map((year) => (
                <th className="text-center">{`Year ${year}`}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* row 1 */}
            {[...Array(5)].map((n, index) => {
              return (
                <tr>
                  {years.map((year) => {
                    const symbol = results[year]?.[index];

                    if (symbol) {
                      const prices = results[year][index]["price"];

                      const percent = parseInt((1000 * (prices[prices.length - 1] - prices[0])) / prices[0]) / 10;
                      return (
                        <td className="">
                          <div className="flex flex-row">
                            {percent < 0 ? (
                              <div className="text-secondary  mx-1 text-center">
                                <FontAwesomeIcon icon={faDownLong} />
                                <div className="text-sm">{percent}%</div>
                              </div>
                            ) : (
                              <div className="text-primary  mx-1 text-center">
                                <FontAwesomeIcon icon={faUpLong} />
                                <div className="text-sm">+{percent}%</div>
                              </div>
                            )}

                            <div>
                              <div className="font-semibold line-clamp-1">{symbol.name}</div>
                              <div className="text-sm opacity-50">{symbol.document_id}</div>
                            </div>
                          </div>
                        </td>
                      );
                    }
                    return <td></td>;
                  })}
                </tr>
              );
            })}
          </tbody>

          <tfoot>
            <tr>
              {performances.map((perf) => {
                return (
                  <td>
                    <div className="flex flex-row">
                      {perf < 0 ? (
                        <div className=" text-secondary text-lg mx-auto">
                          <FontAwesomeIcon icon={faDownLong} />
                          {` ${perf.toFixed(2)}%`}
                        </div>
                      ) : (
                        <div className="text-primary text-lg mx-auto">
                          <FontAwesomeIcon icon={faUpLong} />
                          {` ${perf.toFixed(2)}%`}
                        </div>
                      )}
                    </div>
                  </td>
                );
              })}
            </tr>
          </tfoot>
        </table>
      </div>
    </Card>
  );
};

export default TableResultSimulation;
