import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import React from "react";

const CardDark = ({ children }) => {
  return (
    <div className="relative isolate overflow-hidden bg-slate-800 rounded-lg px-5 md:px-6 py-5 md:py-8 md:mx-5 mb-10 mt-5 ring-1 ring-slate-900/60  shadow-slate-700/10 shadow-xl dark:shadow-xl text-slate-400">
      {children}

      <div
        className="absolute inset-0 max-w-md mx-auto h-72 blur-[118px] -z-10 "
        style={{
          background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)",
        }}
      ></div>
    </div>
  );
};

export default CardDark;
