import React from "react";
import { useRef, useState } from "react";

const FaqsCard = (props) => {
  const answerElRef = useRef();
  const [state, setState] = useState(false);
  const [answerH, setAnswerH] = useState("0px");
  const { faq, idx } = props;

  const handleOpenAnswer = () => {
    const answerElH = answerElRef.current.childNodes[0].offsetHeight;
    setState(!state);
    setAnswerH(`${answerElH + 20}px`);
  };

  return (
    <div className="group space-y-1 mt-4 overflow-hidden border-b" key={idx} onClick={handleOpenAnswer}>
      <h4 className="cursor-pointer pb-5 flex items-center justify-between text-lg text-base-content font-medium hover:text-primary duration-300">
        {faq.title}
        {state ? (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-primary ml-2 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-base-content-80 ml-2 group-hover:text-primary"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
        )}
      </h4>
      <div ref={answerElRef} className="duration-300" style={state ? { height: answerH } : { height: "0px" }}>
        <div>
          <p className="text-gray-500">{faq.text}</p>
        </div>
      </div>
    </div>
  );
};

const FAQ1 = () => {
  const faq = [
    {
      title: "What is GuruScreener.io and how does it work?",
      text: "GuruScreener.io is designed to screen stocks using the proven strategies of Wall Street legends such as Warren Buffett, Peter Lynch, and Benjamin Graham. Our platform analyzes various financial metrics and indicators to identify stocks that align with these time-tested investment philosophies.",
    },
    {
      title: "How can GuruScreener.io benefit me?",
      text: "GuruScreener.io solves the problem of finding promising stocks for investors who want to follow in the footsteps of successful Wall Street legends. If you suffer from the challenge of identifying quality stocks without the pain of extensive research or the need for specialized financial knowledge, our platform is the solution. It streamlines the stock screening process, making it accessible and effective for all investors.",
    },
    {
      title: "What sets GuruScreener.io apart from other stock screening tools?",
      text: "We differentiate ourselves by focusing on the proven strategies of renowned investors like Warren Buffett, Peter Lynch, and Benjamin Graham. GuruScreener.io's algorithms are tailored to their time-tested principles, giving you a unique edge in stock selection. Unlike other tools, we prioritize simplicity and effectiveness, ensuring that you can confidently make informed investment decisions.",
    },
    {
      title: "Is GuruScreener.io suitable for beginners in stock investing?",
      text: "Absolutely! GuruScreener.io is designed to be user-friendly and accessible to investors of all levels. Whether you're a seasoned trader or just starting your investment journey, our platform provides valuable insights and recommendations. You don't need to be an expert in finance to benefit from the wisdom of Wall Street legends.",
    },
    {
      title: "Can I trust the stock recommendations from GuruScreener.io?",
      text: "Yes, you can. GuruScreener.io is built on a foundation of proven investment strategies employed by Wall Street legends. We prioritize accuracy and reliability in our stock screening, ensuring that you receive recommendations based on solid financial principles. However, as with any investment, we recommend doing your due diligence and consulting with a financial advisor.",
    },
    {
      title: "How can you help improve my investment decisions?",
      text: "By relying on strategies from respected financial literature, GuruScreener.io brings high-return investment possibilities. These aren't just any strategies - they are proven methods that have outperformed indexes like the S&P 500 time and time again. ",
    },
    {
      title: "Can I cancel at any time?",
      text: "You pay for lifetime access, there is no need to cancel. If you change your mind, come back from time to time to see new improvements.",
    },
    {
      title: "Is GuruScreener.io easy to use?",
      text: "Yes, GuruScreener.io is designed with user experience in mind. You don’t need to be an expert in using financial technology to get the most out of it. You no longer follow the market - you're ready to beat it",
    },
    {
      title: "How do I get started with GuruScreener.io?",
      text: "Getting started is quick and easy. Simply sign up for a free trial on our website, and you'll gain instant access to the powerful features of our platform. Follow the on-screen prompts to set up your account, and you'll be on your way to making informed investment decisions inspired by Wall Street legends.",
    },
  ];

  return (
    <div className="py-16">
      <div className="container m-auto px-6 md:px-12 xl:px-6">
        <div className="mb-20 space-y-4 px-6 md:px-0">
          <h2 className="text-center text-2xl font-bold md:text-4xl">
            Got Questions? <p /> We've got the answers.
          </h2>
        </div>
        <div className="mt-14 max-w-3xl mx-auto">
          {faq.map((item, idx) => (
            <FaqsCard idx={idx} faq={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ1;
