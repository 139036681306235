import DocIntroduction from "../documentation/general/Introduction";
import EarningsYield from "../documentation/financialRatio/EarningsYield";
import PEratio from "../documentation/financialRatio/PEratio";
import ScreenerDocumentation from "../documentation/general/ScreenerDocumentation";
import NothingToSee from "../documentation/NothingToSee";
import ReturnOnEquity from "../documentation/financialRatio/ReturnOnEquity";
import ReturnOnInvestedCapital from "../documentation/financialRatio/ReturnOnInvestedCapital";
import CurrentRatio from "../documentation/financialRatio/CurrentRatio";
import QuickRatio from "../documentation/financialRatio/QuickRatio";
import AltmanZScore from "../documentation/financialRatio/AltmanZScore";
import GrossMargin from "../documentation/financialRatio/GrossMargin";
import NetMagin from "../documentation/financialRatio/NetMagin";
import OperatingMargin from "../documentation/financialRatio/OperatingMargin";
import DataSource from "../documentation/general/DataSource";
import BacktestDocumentation from "../documentation/general/BacktestDocumentation";
import AlternativeGuruFocus from "./../documentation/comparison/AlternativeGuruFocus";
import AlternativeFinVizz from "../documentation/comparison/AlternativeFinVizz";
import GuaranteePage from "../documentation/general/GuaranteePage";

export const menuDocumentation = [
  {
    name: "General",
    content: [
      { id: 1, name: "Introduction", url: "introduction", component: <DocIntroduction /> },
      { id: 2, name: "Screener", url: "screener", component: <ScreenerDocumentation /> },
      { id: 3, name: "Data Source", url: "data", component: <DataSource /> },
      { id: 4, name: "Backtest", url: "backtest", component: <BacktestDocumentation /> },
      { id: 5, name: "Warranty", url: "warranty", component: <GuaranteePage /> },
    ],
  },
  {
    name: "Financial Ratio (Profitability)",
    content: [
      { id: 1, name: "Gross Margin", url: "gross-margin", component: <GrossMargin /> },
      { id: 2, name: "Operating Margin", url: "operating-margin", component: <OperatingMargin /> },
      { id: 3, name: "Net Margin", url: "net-margin", component: <NetMagin /> },
      { id: 4, name: "Altman Z-Score", url: "altman-z-score", component: <AltmanZScore /> },
      { id: 6, name: "Return on Equity", url: "return-on-equity", component: <ReturnOnEquity /> },
      { id: 7, name: "Return on Invested Capital", url: "return-on-invested-capital", component: <ReturnOnInvestedCapital /> },
      { id: 8, name: "Current Ratio", url: "current-ratio", component: <CurrentRatio /> },
      { id: 9, name: "Quick Ratio", url: "quick-ratio", component: <QuickRatio /> },

      // Return on Equity https://www.stockopedia.com/ratios/return-on-equity-ttm-817/
      // Return on Invested Capital https://www.stockopedia.com/ratios/return-on-invested-capital-ttm-824/
      // Altman Z-Score https://www.stockopedia.com/ratios/altman-z-score-ttm-39/
      // Current Ratio https://www.stockopedia.com/ratios/current-ratio-ttm-198/
      // Quick Ratio  https://www.stockopedia.com/ratios/quick-ratio-last-year-759/
    ],
  },
  {
    name: "Financial Ratio (Value)",
    content: [
      { id: 1, name: "PE Ratios", url: "pe-ratio", component: <PEratio /> },
      { id: 2, name: "Earnings Yield", url: "earnings-yield", component: <EarningsYield /> },
    ],
  },
  {
    name: "Technical",
    content: [
      { id: 1, name: "Moving Average", url: "moving-average", component: <NothingToSee /> },

      // Return on Equity https://www.stockopedia.com/ratios/return-on-equity-ttm-817/
      // Return on Invested Capital https://www.stockopedia.com/ratios/return-on-invested-capital-ttm-824/
      // Altman Z-Score https://www.stockopedia.com/ratios/altman-z-score-ttm-39/
      // Current Ratio https://www.stockopedia.com/ratios/current-ratio-ttm-198/
      // Quick Ratio  https://www.stockopedia.com/ratios/quick-ratio-last-year-759/
    ],
  },
  {
    name: "Test & Review",
    content: [
      { id: 1, name: "GuruFocus.com", url: "test-review-guru-focus", component: <AlternativeGuruFocus /> },
      { id: 2, name: "FinViz.com", url: "test-review-finviz", component: <AlternativeFinVizz /> },

      // AAII SI PRO
      // http://www.datahelper.com/mi/search.phtml?nofool=youbet&mid=26775200
    ],
  },
];
