import React from "react";

const OShaugnessyTinyTitansArticle = () => {
  return (
    <div className="screener-article">
      <h3>Understand the approaches of James O'Shaugnessy</h3>
      <p>
        Predicting the future of the market is nearly impossible, but that doesn't stop investors from trying. In his latest book, "Predicting the
        Markets of Tomorrow: A Contrarian Investment Strategy for the Next Twenty Years," James O'Shaughnessy argues that investors can predict market
        direction simply by looking at long-term historical trends.
      </p>
      <p>
        O'Shaughnessy has developed four stock-picking approaches for individual investors that attempt to take maximum advantage of market trends by
        examining the history of the stock market. He focuses on finding the stocks among the various market capitalizations that, based on his
        research, are most likely to do well in the market.
      </p>
      <p>
        O'Shaughnessy found that stock markets tend to move in roughly 20-year trends, based on data dating back to the late 1790s. According to this
        pattern, a 20-year trend began in early 2000 in which small- and mid-cap stocks and large-cap value stocks generate higher returns. The
        previous 20-year cycle favored large-cap growth stocks. Small- and mid-cap stocks and large-cap value stocks underperformed the market on
        average.
      </p>

      <h3>Asset Allocation</h3>
      <p>
        Even when a group of stocks is out of favor, O'Shaughnessy recommends investing in them to stay diversified. For the conservative investor who
        simply wants to mimic the market's diversification, he recommends 25% in micro-, small- and mid-cap stocks and the remaining 75% in large-cap
        stocks. For the more aggressive investor, he recommends 35% in micro-, small- and mid-cap stocks, 50% in large-cap value stocks and 15% in
        large-cap growth stocks.
      </p>

      <h3>Portfolio size</h3>
      <p>
        O'Shaughnessy recommends buying 25 stocks for a micro-, small- or mid-cap portfolio and 10 stocks for a large-cap portfolio. Because small-
        and mid-cap stocks are more volatile, additional stocks are needed to diversify some of the risk. d
      </p>
      <h3>Tiny Titans - How to ? </h3>
      <p>
        For the more aggressive investor, the Tiny Titans screen looks for cheap micro-cap stocks with rising momentum. According to O'Shaughnessy,
        there are a lot of advantages to investing in micro-cap stocks. Few analysts cover these small stocks, and this lack of coverage leaves room
        for upside when quality stocks go unnoticed.
      </p>
      <p>
        In addition, micro-cap stocks have a low correlation to other market-cap strategies, including the S&P 500. The S&P 500 is composed primarily
        of mid- and large-cap stocks. That is, the performance of the S&P 500 is less likely to affect the performance of small-cap securities. For
        example, when the overall market (as measured by the S&P 500) is declining, a portfolio of micro-cap stocks is more likely to outperform.
        However, these tiny stocks are highly volatile. They are best suited for investors who can handle the dramatic swings that a portfolio of
        these stocks will produce.
      </p>

      <h4 className="text-primary font-semibold">Rule #1 Market Capitalization</h4>
      <p>The Tiny Titans screen looks for stocks with a market capitalization between $25 and $250 million.</p>

      <p className="font-semibold ml-10">{"$25 million < Market Cap.< $250 million"}</p>

      <h4 className="text-primary font-semibold">Rule #2 Prices To Sales </h4>
      <p className="font-semibold ml-10">The stocks must also have a price-to-sales ratio that is less than one.</p>

      <h4 className="text-primary font-semibold">Rule #3 Momentum </h4>
      <p className="font-semibold ml-10"> The 25 stocks with the greatest 52-week relative strength are picked for the portfolio.</p>
    </div>
  );
};

export default OShaugnessyTinyTitansArticle;
