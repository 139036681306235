import React, { useEffect, useState } from "react";
import Card from "../../../common/Card";
import useBacktestingStore from "../../../store/backtesting/store";
import { listVariable } from "./listVariable";

const FormVariable = () => {
  const [value, setValue] = useState("");
  const [operation, setOperation] = useState("");
  const [variable, setVariable] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const { conditions, addConditions, deleteConditions, startSimulation } = useBacktestingStore();

  const test = [
    { name: "Gross Margin (%)", variable: "grossMargin", type: "number" },
    { name: "Net Margin (%)", variable: "netMargin", type: "number" },
    { name: "Operating Margin (%)", variable: "operatingMargin", type: "number" },
  ];

  useEffect(() => {
    let disabled = false;

    if (operation === "") {
      disabled = true;
    }
    if (value === "") {
      disabled = true;
    }
    if (Object.keys(variable).length === 0) {
      disabled = true;
    }
    console.log(disabled);
    setIsDisabled(disabled);
  }, [value, operation, variable]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(variable, operation, value);
    addConditions({
      id: (Math.random() + 1).toString(36).substring(7),
      variable,
      operation,
      value,
    });

    setIsDisabled(true);
  };
  return (
    <Card>
      <form onSubmit={handleSubmit}>
        <label className="form-control w-full">
          <div className="label">
            <span className="label-text font-semibold text-primary">Variable</span>
          </div>

          <select
            className="select select-bordered"
            onChange={(e) => {
              const selectedVariable = listVariable.filter((v) => v.variable === e.target.value)[0];
              setVariable(selectedVariable);
              setValue("");
              setOperation("");
            }}
          >
            <option disabled selected>
              Pick one
            </option>

            <optgroup label="Earning Ratio">
              {listVariable.map((variable) => {
                if (variable.group === "earning")
                  return (
                    <option key={variable.variable} value={variable.variable}>
                      {variable.name}
                    </option>
                  );
              })}
            </optgroup>
            <optgroup label="Valuation">
              {listVariable.map((variable) => {
                if (variable.group === "valuation")
                  return (
                    <option key={variable.variable} value={variable.variable}>
                      {variable.name}
                    </option>
                  );
              })}
            </optgroup>
            <optgroup label="Price Ratio">
              {listVariable.map((variable) => {
                if (variable.group === "price")
                  return (
                    <option key={variable.variable} value={variable.variable}>
                      {variable.name}
                    </option>
                  );
              })}
            </optgroup>
            <optgroup label="General">
              {listVariable.map((variable) => {
                if (variable.group === "general")
                  return (
                    <option key={variable.variable} value={variable.variable}>
                      {variable.name}
                    </option>
                  );
              })}
            </optgroup>
          </select>
        </label>

        {/* Operation  */}
        <label className="form-control w-full ">
          <div className="label">
            <span className="label-text font-semibold text-primary">Operation</span>
          </div>

          {Object.keys(variable).length > 0 ? (
            <select
              className="select select-bordered "
              value={operation}
              onChange={(e) => {
                setOperation(e.target.value);
              }}
            >
              <option value="" disabled selected>
                Pick one
              </option>
              {variable?.["condition"]?.["type"] === "input" && <option value=">=">Greater Than</option>}
              {variable?.["condition"]?.["type"] === "input" && <option value="<=">Less Than</option>}
              {variable?.["condition"]?.["type"] === "select" && <option value="==">Equals</option>}
              {variable?.["condition"]?.["type"] === "select" && <option value="<>">Not Equals</option>}
            </select>
          ) : (
            <select className="select select-bordered ">
              <option disabled selected>
                Pick one
              </option>
            </select>
          )}
        </label>
        {/* Value  */}
        <label className="form-control w-full ">
          <div className="label">
            <span className="label-text font-semibold text-primary">Value</span>
          </div>

          {Object.keys(variable).length === 0 && (
            <select className="select select-bordered ">
              <option disabled selected>
                Pick one
              </option>
            </select>
          )}

          {variable?.["condition"]?.["type"] === "input" && (
            <input
              type="text"
              placeholder="Type here"
              className="input input-bordered w-full "
              name="value"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          )}

          {variable?.["condition"]?.["type"] === "select" && (
            <select
              className="select select-bordered "
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            >
              <option value="" disabled selected>
                Pick one
              </option>
              {variable.condition.option.map((e) => {
                return <option>{e}</option>;
              })}
            </select>
          )}
        </label>
        <div className="text-center pt-5">
          <button className="btn btn-primary px-10" type="submit" disabled={isDisabled || startSimulation}>
            Add
          </button>
        </div>
      </form>
    </Card>
  );
};

export default FormVariable;
