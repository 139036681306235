import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Card from "../../../common/Card";
import useBacktestingStore from "../../../store/backtesting/store";

const ChartResultBacktest = () => {
	const [isDarkTheme, setIsDarkTheme] = useState(localStorage.getItem("theme") === "dark");
	const [update, setUpdate] = useState(0);

	useEffect(() => {
		window.addEventListener("theme", handleEvent, false);
	}, []);

	function handleEvent() {
		const localTheme = localStorage.getItem("theme");
		setIsDarkTheme(localTheme === "dark");
		// Apply color to chart wia State
	}

	const { results, years } = useBacktestingStore();
	const [curve, setCurve] = useState({ xAxis: [], yAxis: [] });

	useEffect(() => {
		let perf = { xAxis: [], yAxis: [] };
		let start = 100;
		let curve = [start];
		let dates = [];

		if (Object.keys(results).length > 0) {
			years.forEach((year, index) => {
				let perfYear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

				results[year].forEach((element) => {
					const prices = element["price"];
					const lastPoint = curve[curve.length - 1];
					const quantity = lastPoint / parseFloat(prices[0]) / results[year].length;
					const delta = parseInt(prices.length / 12) - 1;
					console.log(delta);
					perfYear[0] = parseFloat(prices[0]) * quantity + perfYear[0];

					for (let i = 1; i < 12; i++) {
						perfYear[i] = parseFloat(prices[i * delta - 1]) * quantity + perfYear[i];
					}
					//   perfYear[1] = parseFloat(prices[parseInt(prices.length / 2)]) * quantity + perfYear[1];
					//   perfYear[2] = parseFloat(prices[prices.length - 1]) * quantity + perfYear[2];
				});

				dates.push(String(year) + "-01-01");
				dates.push(String(year) + "-02-01");
				dates.push(String(year) + "-03-01");
				dates.push(String(year) + "-04-01");
				dates.push(String(year) + "-05-01");
				dates.push(String(year) + "-06-01");
				dates.push(String(year) + "-07-01");
				dates.push(String(year) + "-08-01");
				dates.push(String(year) + "-09-01");
				dates.push(String(year) + "-10-01");
				dates.push(String(year) + "-11-01");
				dates.push(String(year) + "-12-01");

				console.log("lastPoint", curve);
				curve.push(...perfYear.map((e) => e));
			});

			perf = {
				xAxis: dates,
				yAxis: curve,
			};
			console.log(perf);
			setCurve(perf);
		}
	}, [results]);

	const state = {
		series: [
			{
				name: "Screener",
				data: curve.yAxis,
			},
		],
		options: {
			theme: { mode: isDarkTheme ? "dark" : "light" },

			yaxis: {
				decimalsInFloat: 1,
				labels: {
					formatter: function (value) {
						return value.toFixed(1) - 100 + "%";
					},
				},
			},
			chart: {
				foreColor: isDarkTheme ? "#a6adba" : "#373d3f",
				height: 350,
				type: "area",
				background: "none",
			},
			dataLabels: {
				enabled: false,
			},
			grid: {
				borderColor: isDarkTheme ? "#475569" : "#e5e7eb",
			},
			stroke: {
				curve: "smooth",
				width: 1,
			},
			xaxis: {
				type: "datetime",
				categories: curve.xAxis,
			},
			tooltip: {
				theme: true,
				x: {
					format: "dd/MM/yy HH:mm",
				},

				y: {
					formatter: function (value) {
						return value.toFixed(1) + "%";
					},
				},
			},
		},
	};

	return (
		<>
			<Card>
				<div id="chart" key={update}>
					<Chart options={state.options} series={state.series} type="area" height={350} />
				</div>
			</Card>
		</>
	);
};

export default ChartResultBacktest;
