import { db } from "../../firebase/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export const getFavorites = (user) => {
  const docRef = doc(db, "users", user.uid);
  return getDoc(docRef)
    .then((doc) => {
      if (doc.exists) {
        const res = doc.data();
        return res.favorites;
      }
      return [];
    })
    .catch(() => {
      return [];
    });
};

export const updateFavorites = ({ user, newFavorites }) => {
  const docFavorites = doc(db, "users", user.uid);
  return updateDoc(docFavorites, { favorites: newFavorites });
};
