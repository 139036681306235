import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const ToolTip = ({ text }) => {
  return (
    <div className="tooltip tooltip-right md:tooltip-top  mt-1 before:w-[15rem] before:content-[attr(data-tip)] md:before:w-[20rem]" data-tip={text}>
      <FontAwesomeIcon className="text-xs" icon={faQuestionCircle} />{" "}
    </div>
  );
};

export default ToolTip;
