import React, { useContext, useState } from "react";
import { UserContext } from "../context/usercontext";
import { firebase_app, auth } from "../firebase/firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark, faArrowRight, faRightToBracket, faRightFromBracket, faHippo } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { faUser } from "@fortawesome/free-regular-svg-icons";

const NavBar = () => {
  const { user } = useContext(UserContext);

  const chooseTheme = () => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      return storedTheme;
    }
    const hour = new Date().getHours();
    return hour < 6 || hour >= 18 ? "dark" : "light";
  };

  const [theme, setTheme] = useState(chooseTheme());
  const [navColor, setnavColor] = useState("transparent");
  const listenScrollEvent = () => {
    window.scrollY > 100 ? setnavColor("bg-base-100/60 backdrop-blur duration-500 border-b dark:border-b-neutral ") : setnavColor("");
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
    const localTheme = localStorage.getItem("theme");
    document.querySelector("html").setAttribute("data-theme", localTheme);
    document.querySelector("html").classList.remove("dark");
    document.querySelector("html").classList.add(localTheme);
    window.dispatchEvent(new Event("theme"));
  }, [theme]);

  const handleToggle = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  const ThemeToogle = () => {
    if (theme === "light") {
      return (
        <svg className="w-6 h-6 hover:rotate-[360deg] duration-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" />
        </svg>
      );
    }

    return (
      <svg className="fill-white w-6 h-6 hover:rotate-[360deg] duration-500 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" />
      </svg>
    );
  };

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const logout = () => {
    auth.signOut();
  };

  const navigation = [
    { name: "Home", to: "/" },
    { name: "Screener", to: "/screener" },
    { name: "Pricing", to: "/pricing" },
    // { name: "Backtesting", to: "/backtest" },
    { name: "Docs", to: "/docs" },
  ];

  return (
    // <header className={"inset-x-0 top-0 z-50 sticky " + navColor}>

    // </header>
    <>
      {/* Menu  */}
      <div className={"inset-x-0 top-0 z-[5] lg:sticky " + navColor}>
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <Link to={`${process.env.PUBLIC_URL}`} className="-m-1.5 p-1.5">
              <FontAwesomeIcon className="h-8 mt-[10px] ms-[8px] w-auto dark:text-blue1 text-primary" icon={faHippo} aria-hidden="true" />{" "}
              <span className="dark:text-blue1 text-primary ms-2 tracking-tighter font-semibold text-xl">GuruScreener</span>
            </Link>
          </div>

          <div className="flex lg:hidden">
            {user && (
              <div className="mr-5">
                <div className="dropdown dropdown-end">
                  <div className="avatar placeholder online ">
                    <div tabIndex={0} role="button" className="bg-neutral text-neutral-content rounded-full w-10 uppercase" aria-label="open menu">
                      <span>{user.email.charAt(0)}</span>
                    </div>
                  </div>
                  <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                    <li>
                      <Link className="text-sm font-semibold leading-6  btn-ghost pr-3 pl-3 rounded-md" to={`${process.env.PUBLIC_URL}/profile`}>
                        <FontAwesomeIcon icon={faUser} size={"xl"} /> My Profile
                      </Link>
                    </li>
                    <li>
                      <button onClick={logout} className="text-sm font-semibold leading-6  btn-ghost pr-3 pl-3 rounded-md  " aria-label="logout">
                        <FontAwesomeIcon icon={faRightFromBracket} size={"xl"} />
                        {" Log Out"}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-neutral"
              onClick={() => setMobileMenuOpen(true)}
              aria-label="open menu"
            >
              <FontAwesomeIcon icon={faBars} className="h-6 w-6 dark:text-base-content" aria-hidden="true" />
            </button>
          </div>

          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.to}
                className="text-sm font-semibold leading-6  relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-primary after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left "
              >
                {item.name}
              </Link>
            ))}
          </div>

          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <button className="btn btn-square btn-ghost mr-5 " title="Dark Mode" onClick={handleToggle} aria-label="theme selection">
              <ThemeToogle />
            </button>

            {user === null ? (
              <div>
                <Link to="/signup" className="btn btn-primary text-sm font-semibold leading-6 mr-5 hover:shadow-lg hover:shadow-primary/40">
                  {" "}
                  <FontAwesomeIcon icon={faArrowRight} /> Create Account{" "}
                </Link>
                <Link to="/login" className="btn text-sm font-semibold leading-6 ">
                  <FontAwesomeIcon icon={faRightToBracket} />
                  Log In
                </Link>{" "}
              </div>
            ) : (
              <div>
                <div className="dropdown dropdown-end">
                  <div className="avatar online  placeholder">
                    <div
                      tabIndex={0}
                      role="button"
                      className="bg-neutral text-neutral-content rounded-full w-12 uppercase text-xl"
                      aria-label="open menu"
                    >
                      <span>{user.email.charAt(0)}</span>
                    </div>
                  </div>
                  <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/profile`} className="text-sm font-semibold leading-6  btn-ghost pr-3 pl-3 rounded-md  ">
                        <FontAwesomeIcon icon={faUser} size={"xl"} /> My Profile
                      </Link>
                    </li>
                    <li>
                      <button onClick={logout} className="text-sm font-semibold leading-6  btn-ghost pr-3 pl-3 rounded-md  " aria-label="logout">
                        <FontAwesomeIcon icon={faRightFromBracket} size={"xl"} />
                        {" Log Out"}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </nav>
      </div>

      <div
        className={`lg:hidden  top-0 right-0 w-[100vw] p-10 pl-20 fixed h-full z-40 transition-transform ease-in-out duration-500 transform ${
          mobileMenuOpen ? "translate-x-0 " : "translate-x-full"
        }`}
      >
        <div className="fixed inset-0 z-40" />
        <div className="fixed inset-y-0 right-0 z-40 w-full overflow-y-auto bg-base-100 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-neutral">
          <div className="flex items-center justify-between">
            <Link to={`${process.env.PUBLIC_URL}`} className="-m-1.5 p-1.5">
              <span className="ms-3 w-auto dark:text-blue1 text-primary tracking-tighter font-semibold text-xl">GuruScreener</span>
            </Link>

            <button type="button" className=" -m-2.5 rounded-md p-2.5 text-neutral" onClick={() => setMobileMenuOpen(false)} aria-label="close menu">
              <FontAwesomeIcon icon={faXmark} className="h-6 w-6 dark:text-base-content" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="divide-y divide-gray-500/10">
              <div className="space-y-2 py-6 px-4 sm:px-2">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.to}
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-base-content hover:bg-primary-content duration-200"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>

              <div className="py-6 px-3 sm:px-2">
                <button
                  className="btn text-sm font-semibold leading-6 mb-5 mr-5"
                  title="Dark Mode"
                  onClick={handleToggle}
                  aria-label="theme selection"
                >
                  <ThemeToogle />
                </button>
                {user === null ? (
                  <div>
                    <Link
                      to="/signup"
                      className="btn btn-primary text-sm font-semibold leading-6 mr-5 hover:shadow-lg hover:shadow-primary/40"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <FontAwesomeIcon icon={faArrowRight} /> Create Account{" "}
                    </Link>
                    <Link to="/login" className="btn text-sm font-semibold leading-6 " onClick={() => setMobileMenuOpen(false)}>
                      <FontAwesomeIcon icon={faRightToBracket} />
                      Log In
                    </Link>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              {/* <div className="py-6 px-3 sm:px-2">
                <Link to={`${process.env.PUBLIC_URL}/login`} className="btn btn-primary">
                  Log in
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
