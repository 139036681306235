import React from "react";
import imageCTA from "../assets/image/screener-dark.png";
import { Link } from "react-router-dom";

const CTASection = () => {
  return (
    <div className="">
      <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true">
            <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#7775D6" />
                <stop offset="1" stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl text-white">Get the Top Ten Rated Stocks Using Any of Our Gurus</h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              We believe the best way to outperform the market is to learn from those who have consistently done so in the past.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <Link to={`${process.env.PUBLIC_URL}/screener`} className="btn btn-accent" aria-label="Link to screener">
                Get started
              </Link>
              <Link to={`${process.env.PUBLIC_URL}/docs`} className="btn btn-outline btn-accent " aria-label="Link to documentation">
                Learn more <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
          <div className="relative mt-16 h-80 lg:mt-8 flex justify-center">
            <img className="absolute lg:left-0 lg:top-10 max-w-sm  md:max-w-lg lg:max-w-none rounded-md   " src={imageCTA} alt="App screenshot" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTASection;
