import React from "react";

const MagicFormulaArticle = () => {
  return (
    <div className="screener-article">
      <h3>Who is Joel Greenblatt?</h3>

      <p>
        Joel Greenblatt, an American money manager, suggests buying 30 "good companies": cheap stocks with high earnings and high returns on capital.
        He describes it as a simplified version of the strategy used by Warren Buffett and Charlie Munger of Berkshire Hathaway. He touts the success
        of his magic formula in his book "The Little Book that Beats the Market," claiming it has produced an average annual return of 30.8% over 17
        years.
      </p>

      <h3>How to use the Magic Formula ? </h3>

      <ul>
        <li>1. Establish a minimum market capitalization (usually greater than $50 million).</li>
        <li>2. Exclude utility and financial stocks. </li>
        <li>3. Exclude foreign companies (American Depositary Receipts).</li>
        <li>4. Determine company's earnings yield = EBIT / enterprise value.</li>
        <li>5. Determine company's return on capital = EBIT /(net fixed assets + working capital). </li>
        <li>
          6. Rank all companies above chosen market capitalization by highest earnings yield and highest return on capital (ranked as percentages).
        </li>
        <li>7. Invest in 20–30 highest ranked companies, accumulating 2–3 positions per month over a 12-month period.</li>
        <li>8. Re-balance portfolio once per year, selling losers one week before the year-mark and winners one week after the year mark.</li>
        <li>9. Continue over a long-term (5–10+ year) period.</li>
      </ul>

      <h3>Performances and Study around the Magic Formula</h3>

      <p>
        <b> A 2009 study of the Nordic stock markets</b> from 1998 to 2008 found that Greenblatt's formula led to outperformance of the market
        averages. averages. However, the authors advised that the formula is best used as a screening tool and should not be applied dogmatically, as
        the outperformance associated with Greenblatt's formula could be explained by data from the Capital Asset Pricing Model and the Fama-French
        three-factor model.
      </p>

      <p>
        A 2017 study of Swedish markets found that using the Greenblatt formula resulted in long-term outperformance of market averages in 2005 to
        2015 and 2007 to 2017. The authors also found that the "magic formula" was also associated with short-term underperformance in some periods
        and periods and significantly increased volatility.
      </p>

      <p>
        Independent Robert Andrew Martin published a backtest analysis of Greenblatt's magic investing formula for the US market in June 2020.He found
        that from 2003 to 2015 application of Greenblatt's formula to U.S. stocks returned an annualized average 11.4%.{" "}
        <b>This outperformed by a significant margin the S&P 500's</b> annualized return of 8.7%. However, Martin also found Greenblat's formula
        under-performed the S&P 500 slightly during the 2007-2011 period and actually went negative for a time, and over the entire 2003-2015 period
        was more volatile overall than the S&P 500. In conclusion, Martin found that the almost 3% outperformance was "surprising", but not as great
        as the 30% returns Greenblatt's book claimed (though their respective analyses used different years). Martin also noted the strategy had
        "significant psychological risk" associated with under-performance during the aftermath of the 2007-2008 financial crisis.
      </p>

      <h3>Source : </h3>
      <a href="https://en.wikipedia.org/wiki/Magic_formula_investing">Wikipedia - Magic Formula Investing</a>
      <p>The Little Book That Beat the Marker - Joel Greenblatt - 2005</p>
    </div>
  );
};

export default MagicFormulaArticle;
