import React, { useContext, useEffect } from "react";
import Card from "../../common/Card";
import { UserContext } from "../../context/usercontext";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CardGradient from "../../common/CardGradient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";

const SubCard = () => {
  return (
    <div className="md:flex md:flex-row md:justify-center ">
      <div className=" basis-1/2 ">
        <div className="max-w-xs ">
          <Card>
            <div className=" text-center">
              <h1 className="text-xl mb-5">You have an active plan.</h1>
              {/* <a className="btn btn-primary" href={"https://billing.stripe.com/p/login/5kAdRbaMFcCJ2mk4gg"} target="_blank" rel="noreferrer noopener">
                Manage Subscription
              </a> */}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

const NoSubCard = () => {
  return (
    <div className="md:flex md:flex-row md:justify-center ">
      <div className=" basis-1/2 ">
        <Card>
          <div className="text-center">
            <h1 className="text-xl mb-5">No active plan ! </h1>
            <Link className="btn btn-primary" to={"/pricing"}>
              Select a plan
            </Link>
          </div>
        </Card>
      </div>
    </div>
  );
};

const ProfilePage = () => {
  const { user, hasSubscription } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate(`/`);
    }
  }, [user]);

  const sendVerification = () => {
    user
      .sendEmailVerification({
        url: "https://guruscreener.io/",
      })
      .then(() => {
        toast.success(`A verification link has been sent to your email`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <div>
      <div className="mx-auto max-w-2xl pb-20 pt-5  lg:pb-20 ">
        <div className="text-center">
          <h1 className="text-3xl font-bold tracking-tight sm:text-3xl">
            {" "}
            <FontAwesomeIcon icon={faUser} className="mr-3" />
            Profile
          </h1>
          <p className="mt-6 text-lg leading-8 text-base-content">Manage your account and subscription</p>
        </div>
      </div>

      {user && (
        <div className="md:flex md:flex-row md:justify-center ">
          <div className=" basis-1/2 ">
            <CardGradient gradient={1}>
              <div className="text-center">
                <h2 className="text-xl mb-5">{`You are logged as: ${user.email}`}</h2>
                <div className="mb-5 font-bold"> {user.emailVerified ? "Account Verified" : "Account Not Verified"}</div>
                <div>
                  {!user.emailVerified && (
                    <button className="btn btn-primary" onClick={sendVerification}>
                      Send Verification
                    </button>
                  )}
                </div>
              </div>
            </CardGradient>
          </div>
        </div>
      )}
      {/* <button>Manage Subscription</button> */}
      {hasSubscription ? <SubCard /> : <NoSubCard />}
    </div>
  );
};

export default ProfilePage;
