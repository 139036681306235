import React from "react";
import modalBugStore from "../../../store/storeModal";
import TradingViewV2 from "./components/TradingViewV2";
import { useScores } from "../../../react-query/hooks/useScore";
import GaugeScore from "./components/GaugeScore";
import CardSymbolModal from "./components/CardSymbolModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useIndicators } from "../../../react-query/hooks/useIndicators";
import RadarSymbol from "./components/RadarSymbol";

const ModalSymbol = () => {
  const openAndClose = modalBugStore((s) => s.openAndClose);
  const openModal = modalBugStore((s) => s.open);
  const symbol = modalBugStore((s) => s.symbol);

  let symbolExtract = "";
  if (symbol?.symbol) {
    symbolExtract = symbol.symbol.split(".")[0];
  }

  const queryScores = useScores({ symbol: symbolExtract });
  const queryIndicators = useIndicators({ symbol: symbolExtract });

  // https://guruscreener.io/api/indicators?symbol=AAPL&indicators[0]=p2lynchvalue

  const description =
    "Embecta Corp., a medical device company, focuses on the provision of various solutions to enhance the health and wellbeing of people living with diabetes. Its products include pen needles, syringes, and safety injection devices, as well as digital applications to assist people with managing patient's diabetes. The company primarily sells its products to wholesalers and distributors in the United States and internationally. Embecta Corp. was founded in 1924 and is headquartered in Parsippany, New Jersey.";

  const logoPath = symbol.logo;

  if (openModal) {
    return (
      <div className="max-w-screen-xl mx-auto ">
        <div className="fixed z-40   m-auto inset-x-0 inset-y-0 p-4   blur-lg backdrop-blur-lg " onClick={openAndClose}></div>
        <div className="fixed top-[120px] z-50   right-40 left-40  bg-base-100 border  p-10  h-[80%] overflow-y-auto rounded-lg ">
          <div className="rounded-full text-2xl absolute right-5 top-5 md:right-5 md:top-5 cursor-pointer flex justify-center items-center h-10 w-10 bg-base-100 ">
            <button className="btn btn-circle" onClick={openAndClose}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          {/* Header du Symbol */}
          <div className="mb-10">
            <div className="border rounded-r-full py-2 pr-10 pl-10 ml-[-2.5rem] w-fit mb-10">
              <div className="flex flex-row">
                <div className="w-16  h-16 rounded mr-5 my-auto">
                  <img src={logoPath} alt="symbol company" />
                </div>
                <div>
                  <h2 className="text-3xl py-1">{`${symbol.name} - ${symbol.exchange}:${symbol.symbol.split(".US")[0]}`}</h2>
                  <h3 className="py-1">{`${symbol.sector} `}</h3>
                </div>
              </div>
            </div>

            {/* Body Symbol */}
            <div className="mx-20">
              <div className="grid grid-cols-5 ">
                <div className="col-span-2">
                  {" "}
                  <CardSymbolModal>
                    <h3 className="py-1 font-semibold">Presentation of the Company</h3>
                    <div>{symbol.description}</div>
                  </CardSymbolModal>
                  <CardSymbolModal extraClass="">
                    {queryScores.isSuccess && (
                      <div>
                        <h3 className="py-1 font-semibold">Key Indicators</h3>

                        <div className="mt-10 ">
                          <div>
                            <span className="font-semibold">Piotroski F-Score</span>
                            <div
                              className="tooltip"
                              data-tip="The Piotroski F-Score is a a score (0-9) measuring the financial strength of a company."
                            >
                              <FontAwesomeIcon className="ml-2 opacity-70" icon={faInfoCircle} />
                            </div>
                            <GaugeScore value={queryScores.data.fScore} minvalue={0} maxvalue={9} />
                          </div>
                          <div>
                            <span className="font-semibold">Beneish M-Score</span>
                            <div
                              className="tooltip"
                              data-tip="The Beneish M-Score is a model to check if it is likely (high probability) that the reported earnings of the company have been manipulated."
                            >
                              <FontAwesomeIcon className="ml-2 opacity-70" icon={faInfoCircle} />
                            </div>
                            <GaugeScore value={queryScores.data.mScore} minvalue={-1.74} maxvalue={-1.84} />
                          </div>{" "}
                          <div>
                            <span className="font-semibold">Altman Z-Score</span>
                            <div className="tooltip" data-tip="The Altman Z score determines the chances of bankruptcy for a company">
                              <FontAwesomeIcon className="ml-2 opacity-70" icon={faInfoCircle} />
                            </div>
                            <GaugeScore value={queryScores.data.zScore} minvalue={0} maxvalue={3} />
                          </div>
                        </div>
                      </div>
                    )}
                  </CardSymbolModal>
                </div>

                <div className="col-span-3">
                  <CardSymbolModal>
                    <h3 className="py-1 font-semibold">GuruScreener Score</h3>
                    {queryIndicators.isSuccess && <RadarSymbol data={queryIndicators.data} />}

                    <div>
                      The GuruScreener is closely correlated with the long-term performance of stocks. It ranges from 0 to 100, with 100 being the
                      highest.
                    </div>
                  </CardSymbolModal>
                </div>

                <div className="col-span-5">
                  {" "}
                  <CardSymbolModal extraClass="mt-5">
                    <h3 className="py-1 font-semibold mb-5">Chart & Technical Analysis</h3>
                    <TradingViewV2 symbol={`${symbol.exchange}:${symbol.symbol.split(".US")[0]}`} />
                  </CardSymbolModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default ModalSymbol;
