import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faBrain, faCoffee, faMagnet, faVialCircleCheck } from "@fortawesome/free-solid-svg-icons";
import React from "react";

const HowWeWork = () => {
  return (
    <div className="relative isolate overflow-hidden bg-gray-900 px-6 shadow-2xl rounded-3xl sm:px-16 lg:px-24 ">
      <div className="items-center  px-4 mx-auto max-w-screen-xl py-10 lg:px-6">
        <div className="flex flex-col text-center ">
          <h2 className=" text-3xl font-extrabold leading-tight text-white lg:text-4xl">How We Work?</h2>
        </div>
        <div className="grid lg:grid-cols-4 justify-items-center gap-7 mt-5 lg:mt-10 ">
          <div className="text-center group">
            <div className="w-20 h-20 text-white/70 group-hover:text-sky-500 group-hover:bg-indigo-50 rounded-lg flex items-center justify-center mx-auto duration-300 lg:mb-5">
              <FontAwesomeIcon className="fa-3x" icon={faBook} />
            </div>
            <div className="font-semibold text-sky-500 items-center justify-center">Step 1</div>
            <h3 className="mb-4 text-xl tracking-tight font-extrabold text-white">In-Depth Analysis</h3>
            <div className="text-white/90">
              Our team of experts analyse the methodologies and strategies of leading investment books and publications.
            </div>
          </div>

          <div className="text-center group">
            <div className="w-20 h-20 text-white/70 group-hover:text-indigo-500 group-hover:bg-indigo-50 rounded-lg flex items-center justify-center mx-auto duration-300 lg:mb-5">
              <FontAwesomeIcon className="fa-3x" icon={faMagnet} />
            </div>
            <div className="font-semibold text-indigo-500 items-center justify-center">Step 2</div>
            <h3 className="mb-4 text-xl tracking-tight font-extrabold text-white">Strategy Extraction</h3>
            <div className="text-white/90">We carefully extract the core investment principles that have proven to be successful over time.</div>
          </div>

          <div className="text-center group">
            <div className="w-20 h-20 text-white/70 group-hover:text-pink-500 group-hover:bg-indigo-50 rounded-lg flex items-center justify-center mx-auto duration-300 lg:mb-5">
              <FontAwesomeIcon className="fa-3x" icon={faBrain} />
            </div>
            <div className="font-semibold text-pink-500 items-center justify-center">Step 3</div>
            <h3 className="mb-4 text-xl tracking-tight font-extrabold text-white">Algorithmic Development</h3>
            <div className="text-white/90">We transforms the strategies into advanced algorithms.</div>
          </div>

          <div className="text-center group">
            <div className="w-20 h-20 text-white/70 group-hover:text-accent group-hover:bg-indigo-50 rounded-lg flex items-center justify-center mx-auto duration-300 lg:mb-5">
              <FontAwesomeIcon className="fa-3x" icon={faVialCircleCheck} />
            </div>
            <div className="font-semibold text-accent items-center justify-center">Step 4</div>
            <h3 className="mb-4 text-xl tracking-tight font-extrabold text-white">Tests & Implementation</h3>
            <div className="text-white/90">We run several tests to validate the algorithm and implement it online.</div>
          </div>
        </div>
      </div>
      <div
        className="absolute inset-0 max-w-md mx-auto h-72 blur-[118px] -z-10 "
        style={{
          background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)",
        }}></div>
    </div>
  );
};

export default HowWeWork;
