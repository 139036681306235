import React from "react";
import Card from "../../../common/Card";
import FormVariable from "./FormVariable";
import useBacktestingStore from "../../../store/backtesting/store";
import FakeLog from "./FakeLog";
import TitleCard from "./../../../common/TitleCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEquals, faGreaterThanEqual, faLessThanEqual, faNotEqual, faTrashCan } from "@fortawesome/free-solid-svg-icons";

const BacktestChoice = () => {
	const { conditions, deleteConditions, startSimulation, compute } = useBacktestingStore();

	return (
		<>
			<TitleCard>Setup : Choose Parameters</TitleCard>
			<span>
				Select the parameters to be applied to the backtest. Each parameter is added to the previously selected parameter. The more parameters you
				have, the more accurate you will be.
			</span>
			<div className="grid grid-cols-4">
				<div className="col-span-1">
					<FormVariable />
				</div>
				<div className="col-span-3">
					<Card>
						<div className="overflow-x-auto">
							{/* <table className="table">
								
								<thead>
									<tr>
										<th></th>
										<th>Name</th>
										<th>Operation</th>
										<th>Value</th>
									</tr>
								</thead>
								<tbody>
									

									{conditions.map((e, index) => {
										console.log(e);
										return (
											<tr>
												<th>{index}</th>
												<td>{e.variable.name}</td>
												<td>{e.operation}</td>
												<td>{e.value}</td>
												<td>
													<button className="btn btn-secondary btn-sm" onClick={() => deleteConditions(e.id)} disabled={startSimulation}>
														Delete
													</button>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table> */}
							<div class=" flex  flex-wrap justify-center">
								{conditions.map((e, index) => {
									console.log(e);
									let symbol;
									switch (e.operation) {
										case "==":
											symbol = <FontAwesomeIcon icon={faEquals} className="text-xs text-primary"></FontAwesomeIcon>;
											break;
										case "<>":
											symbol = <FontAwesomeIcon icon={faNotEqual} className="text-xs "></FontAwesomeIcon>;
											break;
										case ">=":
											symbol = <FontAwesomeIcon icon={faGreaterThanEqual} className="text-xs text-accent"></FontAwesomeIcon>;
											break;
										case "<=":
											symbol = <FontAwesomeIcon icon={faLessThanEqual} className="text-xs text-secondary"></FontAwesomeIcon>;
											break;
									}
									return (
										<>
											<div class="flex justify-center gap-1 my-1 mx-1 items-center ">
												<kbd class="kbd kbd-sm gap-2 px-2">
													{e.variable.name}
													{symbol}
													{e.value}
												</kbd>

												<button className="btn btn-xs" onClick={() => deleteConditions(e.id)} disabled={startSimulation}>
													<FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>
												</button>
											</div>

											<td></td>
										</>
									);
								})}
							</div>
						</div>
						{conditions.length === 0 && <div className="text-center my-5 opacity-70">Please add some parameters</div>}
						{!startSimulation && (
							<div className="text-center my-5">
								<button className="btn btn-primary px-10  " onClick={compute} disabled={startSimulation || conditions.length === 0}>
									Start Backtesting
								</button>
							</div>
						)}
					</Card>
				</div>
			</div>
			<FakeLog />
		</>
	);
};

export default BacktestChoice;
