import React, { useState } from "react";
import { dataScreener } from "../routes/ScreenerPage/data/dataScreenerV2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarOutline } from "@fortawesome/free-regular-svg-icons";
import badgeColor from "../utils/badgeColor";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { firebase_app, db } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { doc, collection, updateDoc } from "firebase/firestore";

// import { UserContext } from "../context/usercontext";
import { useChangeFavorites } from "./../react-query/hooks/useChangeFavorites";

// correction className badge-outline plusieurs fois
// passer le h1 style dans le css global

const TitleScreenerCard = ({ screenerName, user, favorites, extra }) => {
  const { name, author } = dataScreener[screenerName];
  const { filters } = dataScreener[screenerName];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const addFavorite = useChangeFavorites({ user });

  const addAndDeleteToFavorite = () => {
    if (!user) {
      navigate(`${process.env.PUBLIC_URL}/signup`);
    } else {
      if (favorites.data) {
        if (favorites.data.includes(screenerName)) {
          const newFavorites = (favorites?.data || []).filter((e) => e !== screenerName);
          addFavorite.mutate(newFavorites);
        } else {
          const newFavorites = [screenerName, ...(favorites?.data || [])];
          addFavorite.mutate(newFavorites);
        }
      }
    }
  };

  let isFavorite = false;
  if (!favorites.isPending) {
    if (favorites.data) {
      if (favorites.data.includes(screenerName)) isFavorite = true;
    }
  }

  return (
    <div className="mb-6  headerscreener1 dark:headerscreener1-dark lg:grid lg:grid-cols-12 rounded-xl">
      <div className="xl:col-span-10 lg:col-span-9 pb-5">
        <h1 className="px-5 md:px-6 md:mx-5 text-2xl sm:text-3xl font-bold tracking-tighter">
          {name}
          {extra}
        </h1>
        <div className="px-5 md:px-6 md:mx-5 pt-2 font-semibold tracking-tighter text-base-content/70">by {author}</div>
        <div className="text-xs text-base-content px-5 md:px-6 md:mx-5 pt-3  ">
          {filters.map((key, index) => (
            <div key={index} className={`badge mr-2 ${badgeColor(key)}`}>
              {key}
            </div>
          ))}
        </div>
      </div>
      <div className="xl:col-span-2 lg:col-span-3 lg:my-auto px-5 mt-5 pb-5 md:px-6 md:mx-5 lg:mx-0 lg:px-0 lg:pb-0">
        <button
          disabled={addFavorite.isLoading}
          className="btn btn-outline hover:bg-white hover:text-slate-900 lg:text-white dark:lg:text-base-content"
          onClick={addAndDeleteToFavorite}
        >
          <FontAwesomeIcon icon={isFavorite ? faStar : faStarOutline} />
          {isFavorite ? "Delete from Favorite" : "Add to Favorite"}
        </button>
      </div>
    </div>
  );
};

export default TitleScreenerCard;
