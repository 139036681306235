import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";

const KirkpatrickSell = () => {
  return (
    <>
      <h3 className="text-white">Kirkpatrick's Sell Signal </h3>
      <div className="grid md:grid-cols-2  gap-3 my-3 lg:my-5 ">
        <div className="flex hover:ring hover:ring-slate-500/60 rounded-lg duration-300 py-5 px-5 group">
          <div className="text-white/70   flex  mx-auto  lg:mb-5 group-hover:text-white duration-300">
            <FontAwesomeIcon className="fa-2x mt-1" icon={faHashtag} /> <span className="text-4xl ml-2 "> 1</span>
          </div>
          <div className="ml-4 ">
            <h4 className="text-xl tracking-tight font-extrabold text-white py-0">Lower Price Strength</h4>
            <div className="group-hover:text-white duration-300">
              The 26-week relative price strength percentile of the stocks is no more in the top 30%.
            </div>
          </div>
        </div>

        <div className="flex hover:ring hover:ring-slate-500/60 rounded-lg duration-300 py-5 px-5 group">
          <div className="text-white/70   flex  mx-auto  lg:mb-5 group-hover:text-white duration-300">
            <FontAwesomeIcon className="fa-2x mt-1" icon={faHashtag} /> <span className="text-4xl ml-2 "> 2</span>
          </div>
          <div className="ml-4 ">
            <h4 className="text-xl tracking-tight font-extrabold text-white py-0">Lower Relative Valuation</h4>
            <div className="group-hover:text-white duration-300">
              The relative reported earnings growth percentile of the stock is no more in the top 50%
            </div>
          </div>
        </div>

        <div className="flex  hover:ring hover:ring-slate-500/60 rounded-lg duration-300 py-5 px-5 group">
          <div className="text-white/70   flex  mx-auto  lg:mb-5 group-hover:text-white duration-300">
            <FontAwesomeIcon className="fa-2x mt-1" icon={faHashtag} /> <span className="text-4xl ml-2 "> 3</span>
          </div>
          <div className="ml-4 ">
            <h4 className="text-xl tracking-tight font-extrabold text-white py-0">DO NOT</h4>
            <div className="group-hover:text-white duration-300">Don't removed a stock for extraordinarily high relative price-to-sales ratios</div>
          </div>
        </div>
      </div>
      <div className="mx-4 mt-2">
        Basically, Kirkpatrick's approach is to sell when a stock no longer has a competitive advantage, but a high price-to-sales ratio is not a
        reason to sell.
      </div>
    </>
  );
};

export default KirkpatrickSell;
