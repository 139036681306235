import React from "react";

const CardGradient = ({ children, gradient }) => {
  const getGradientClass = (gradient) => {
    switch (gradient) {
      case 1:
        return "bg-gradient1-light dark:bg-gradient1-dark";
      case 2:
        return "bg-gradient2-light dark:bg-gradient2-dark";
      case 3:
        return "bg-gradient3-light dark:bg-gradient3-dark";
      default:
        return "bg-gradient1-light dark:bg-gradient1-dark";
    }
  };

  const gradientClass = getGradientClass(gradient);

  return (
    <div
      className={`${gradientClass}  rounded-lg px-5 md:px-6 py-5 md:py-8 md:mx-5 mb-10 mt-5 ring-1 ring-slate-900/5 dark:ring-slate-900/60 shadow-xl shadow-slate-300/10 dark:shadow-xl`}>
      {children}
    </div>
  );
};

export default CardGradient;
