import React from "react";

const GaugeScore = ({ value, minvalue, maxvalue }) => {
  const commonClass = "w-full h-2.5 flex flex-col justify-center overflow-hidden whitespace-nowrap transition duration-500";
  const valueAbs = (100 * (value - minvalue)) / (maxvalue - minvalue);

  let positionCursor = Math.min(parseInt(valueAbs), 100);
  console.log(positionCursor);
  positionCursor = Math.max(positionCursor, 0);

  positionCursor = parseInt(positionCursor / 8.3);

  if (positionCursor * 8.3 < 95) {
    if (positionCursor * 8.3 > 8) {
      positionCursor = positionCursor * 8.3 + 5;
    } else {
      positionCursor = positionCursor * 8.3 + 10;
    }
  } else {
    positionCursor = positionCursor * 8.3 - 2;
  }
  positionCursor = String(positionCursor) + "%";

  return (
    <div className="flex flex-row">
      <div className=" w-80 flex items-center gap-x-0">
        <div className={"relative  min-w-5 min-h-5 border-2 rounded-full bg-white"} style={{ left: positionCursor }}></div>
        {/* // red */}
        <div className={`rounded-l ${commonClass} bg-red-500`}></div>
        <div className={`${commonClass} ${valueAbs >= 8.3 ? `bg-red-500` : `bg-red-200`} dark:bg-red-500/30`}></div>
        <div className={`${commonClass} ${valueAbs >= 16.6 ? `bg-red-500` : `bg-red-200`} dark:bg-red-500/30`}></div>

        {/* blue */}
        <div
          className={`${commonClass} bg-gradient-to-r ${valueAbs >= 24.9 ? `from-red-500` : `from-red-200`} ${
            valueAbs >= 24.9 ? `to-orange-300` : `to-orange-100`
          } dark:bg-red-500/30`}
        ></div>
        <div className={`${commonClass} ${valueAbs >= 33.2 ? `bg-orange-300` : `bg-orange-100`} dark:bg-red-500/30`}></div>

        <div className={`${commonClass} ${valueAbs >= 41.5 ? `bg-orange-300` : `bg-orange-100`} dark:bg-red-500/30`}></div>
        <div className={`${commonClass} ${valueAbs >= 49.8 ? `bg-orange-300` : `bg-orange-100`} dark:bg-red-500/30`}></div>
        <div className={`${commonClass} ${valueAbs >= 58.1 ? `bg-orange-300` : `bg-orange-100`} dark:bg-red-500/30`}></div>
        <div
          className={`${commonClass} bg-gradient-to-r ${valueAbs >= 66.4 ? `from-orange-300` : `from-orange-100`} ${
            valueAbs >= 66.4 ? `to-emerald-500` : `to-emerald-200`
          } dark:bg-red-500/30`}
        ></div>

        {/* emerald */}
        <div className={`${commonClass} ${valueAbs >= 74.7 ? `bg-emerald-500` : `bg-emerald-200`} dark:bg-emerald-500/30`}></div>
        <div className={`${commonClass} ${valueAbs >= 83 ? `bg-emerald-500` : `bg-emerald-200`} dark:bg-emerald-500/30`}></div>
        <div className={`rounded-r ${commonClass} ${valueAbs >= 91.3 ? `bg-emerald-500` : `bg-emerald-200`} dark:bg-emerald-500/30`}></div>
      </div>
      <div>
        <div className=" ml-10">
          <span className="text-sm font-semibold ">{value}</span>
        </div>
      </div>
    </div>
  );
};

export default GaugeScore;
