import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";

const MagicFormulaSell = () => {
  return (
    <>
      <h3 className="text-white">Magic Formula Sell Strategy </h3>
      <div className="grid md:grid-cols-2  gap-3 my-3 lg:my-5 ">
        <div className="flex hover:ring hover:ring-slate-500/60 rounded-lg duration-300 py-5 px-5 group">
          <div className="text-white/70   flex  mx-auto  lg:mb-5 group-hover:text-white duration-300">
            <FontAwesomeIcon className="fa-2x mt-1" icon={faHashtag} /> <span className="text-4xl ml-2 "> 1</span>
          </div>
          <div className="ml-4 ">
            <h4 className="text-xl tracking-tight font-extrabold text-white py-0">Limit of 20-30 Companies in Portfolio</h4>
            <div className="group-hover:text-white duration-300">
              The magic formula is a balance between 20-30 companies. Start thinking about rebalancing your portfolio when you have more companies.
            </div>
          </div>
        </div>

        <div className="flex hover:ring hover:ring-slate-500/60 rounded-lg duration-300 py-5 px-5 group">
          <div className="text-white/70   flex  mx-auto  lg:mb-5 group-hover:text-white duration-300">
            <FontAwesomeIcon className="fa-2x mt-1" icon={faHashtag} /> <span className="text-4xl ml-2 "> 2</span>
          </div>
          <div className="ml-4 ">
            <h4 className="text-xl tracking-tight font-extrabold text-white py-0">Re-Balance Every Year</h4>
            <div className="group-hover:text-white duration-300">
              Re-balance portfolio once per year, selling losers one week before the year-mark and winners one week after the year mark.
            </div>
          </div>
        </div>
      </div>
      <div className="mx-4 mt-2">
        The Magic Formula is a long-term approach with annual rebalancing. The idea is simple: always have the top 20-30 companies in your portfolio.
        The Magic Formula doesn't leave room for technical analysis and the selling part is easy. Maybe too simple for bear markets.
      </div>
    </>
  );
};

export default MagicFormulaSell;
