export const listVariable = [
  // MarketAdvisor

  {
    group: "valuation",
    variable: "earningYield",
    name: "Earning Yield(%)",
    operators: [">=", "<=", "display"],
    unit: "%",
    condition: { type: "input", format: "number", option: [] },
  },
  {
    group: "valuation",
    variable: "EV",
    name: "Entreprise Value",
    operators: [">=", "<=", "display"],
    unit: "",
    condition: { type: "input", format: "number", option: [] },
  },
  {
    group: "valuation",
    variable: "fScore",
    name: "F-Score",
    operators: [">=", "<=", "display"],
    unit: "",
    condition: { type: "input", format: "number", option: [] },
  },
  {
    group: "valuation",
    variable: "marketCap",
    name: "Market Cap",
    operators: [">=", "<=", "display"],
    unit: "",
    condition: { type: "input", format: "number", option: [] },
  },
  {
    group: "valuation",
    variable: "PayoutRatio",
    name: "Payout Ratio",
    operators: [">=", "<=", "display"],
    unit: "",
    condition: { type: "input", format: "number", option: [] },
  },
  {
    group: "valuation",
    variable: "ROC",
    name: "ROC (%)",
    operators: [">=", "<=", "display"],
    unit: "%",
    condition: { type: "input", format: "number", option: [] },
  },
  {
    group: "valuation",
    variable: "ROIC",
    name: "ROIC (%)",
    operators: [">=", "<=", "display"],
    unit: "%",
    condition: { type: "input", format: "number", option: [] },
  },

  {
    group: "earning",
    variable: "altmanZScore",
    name: "Altman Z-Score",
    operators: [">=", "<=", "display"],
    unit: "",
    condition: { type: "input", format: "number", option: [] },
  },
  {
    group: "earning",
    variable: "eqAssetRatio",
    name: "Equity/Asset Ratio (%)",
    operators: [">=", "<=", "display"],
    unit: "",
    condition: { type: "input", format: "number", option: [] },
  },
  {
    group: "earning",
    variable: "grossMargin",
    name: "Gross Margin (%)",
    operators: [">=", "<=", "display"],
    unit: "%",
    condition: { type: "input", format: "number", option: [] },
  },
  {
    group: "earning",
    variable: "interestCoverageRatio",
    name: "Interest Ratio (%)",
    operators: [">=", "<=", "display"],
    unit: "",
    condition: { type: "input", format: "number", option: [] },
  },
  {
    group: "earning",
    variable: "netMargin",
    name: "Net Margin (%)",
    operators: [">=", "<=", "display"],
    unit: "%",
    condition: { type: "input", format: "number", option: [] },
  },
  {
    group: "earning",
    variable: "operatingMargin",
    name: "Operating Margin (%)",
    operators: [">=", "<=", "display"],
    unit: "%",
    condition: { type: "input", format: "number", option: [] },
  },
  {
    group: "earning",
    variable: "quickRatio",
    name: "Quick Ratio",
    operators: [">=", "<=", "display"],
    unit: "",
    condition: { type: "input", format: "number", option: [] },
  },
  {
    group: "earning",
    variable: "ROA",
    name: "Return on Asset (%)",
    operators: [">=", "<=", "display"],
    unit: "",
    condition: { type: "input", format: "number", option: [] },
  },
  {
    group: "earning",
    variable: "ROE",
    name: "Return on Equity (%)",
    operators: [">=", "<=", "display"],
    unit: "",
    condition: { type: "input", format: "number", option: [] },
  },

  {
    group: "price",
    variable: "50DayMA",
    name: "50-D MA",
    operators: ["display"],
    unit: "",
    condition: { type: "input", format: "number", option: [] },
  },
  {
    group: "price",
    variable: "200DayMA",
    name: "200-D MA",
    operators: ["display"],
    unit: "",
    condition: { type: "input", format: "number", option: [] },
  },
  {
    group: "price",
    variable: "52WeekHigh",
    name: "52 Week High",
    operators: ["display"],
    unit: "",
    condition: { type: "input", format: "number", option: [] },
  },
  {
    group: "price",
    variable: "52WeekLow",
    name: "52 Week Low",
    operators: ["display"],
    unit: "",
    condition: { type: "input", format: "number", option: [] },
  },
  { group: "price", variable: "Beta", name: "Beta", operators: ["display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  {
    group: "price",
    variable: "PE",
    name: "Price/Earning (PE)",
    operators: [">=", "<=", "display"],
    unit: "",
    condition: { type: "input", format: "number", option: [] },
  },
  {
    group: "price",
    variable: "priceEquity",
    name: "Price/Equity",
    operators: [">=", "<=", "display"],
    unit: "",
    condition: { type: "input", format: "number", option: [] },
  },

  {
    group: "price",
    variable: "priceSales",
    name: "Price/Sales",
    operators: [">=", "<=", "display"],
    unit: "",
    condition: { type: "input", format: "number", option: [] },
  },

  {
    group: "general",
    variable: "DividendYield",
    name: " Dividend Yield (%)",
    operators: [">=", "<=", "display"],
    unit: "",
    condition: { type: "input", format: "number", option: [] },
  },

  {
    group: "general",
    variable: "Sector",
    name: "sector",
    operators: ["=", "<>"],
    condition: {
      type: "select",
      format: "text",
      option: [
        "Academic & Educational Services",
        "Basic Materials",
        "Communication Services",
        "Consumer Cyclical",
        "Consumer Defensive",
        "Consumer Goods",
        "Consumer Non-Cyclicals",
        "Energy",
        "Financial",
        "Financial Services",
        "Financials",
        "Healthcare",
        "Industrials",
        "Other",
        "Real Estate",
        "Services",
        "Technology",
        "Utilities",
      ],
    },
  },
];
