import React from "react";

const ScreenerDocumentation = () => {
  return (
    <div className="screener-article">
      <h1>Financial Screener</h1>

      <h2>What is a Stocks Screener ? </h2>
      <p>
        A financial screener is a powerful tool designed to help investors analyze and filter through a vast array of financial instruments, such as
        stocks or bonds, based on specific criteria. It acts as a virtual sieve, allowing users to screen and identify investment opportunities that
        align with their unique preferences and goals.
      </p>
      <p className="text-primary font-bold ml-5"> STOCKS SCREENER = FINANCIAL DATABASE + ALGORITHM </p>
      <p>
        With GuruScreener, our financial screener is tailored to incorporate the insights of renowned financial gurus and timeless principles, making
        it a comprehensive solution for investors seeking guidance from proven strategies. This tool empowers users to efficiently narrow down their
        investment choices by applying filters related to financial metrics, market trends, and the principles advocated by legendary financial
        thinkers.
      </p>

      <h2>What is the efficiency of a screener ? </h2>
      <p>
        A screener is essentially a practical representation of financial theories conceived by thinkers. We implement these theories across our large
        database, covering over 40,000 unique tickers. This means that our screener consistently applies established theories to real-world data. Our
        screener excels in translating these financial theories into tangible results, showcasing its efficiency in transforming concepts into list of
        potential investments.
      </p>

      <h2>How the screener is build ? </h2>
      <p>
        When constructing a financial screener, there are three key components to take into account. The initial step involves translating a financial
        theory into an algorithm. It may seem simple when you look at financial ratios like gross margin or return on equity. But when the concept are
        more abstract, such as the board's involvement in the business, we need to find a numerical concept to describe the same phenomena.
      </p>

      <p>
        The second component is about obtaining data from companies globally and ensuring its integrity. It asks to get several sources of data and
        and reconcile the results. Another sub-step is to check for inconsistencies in the financial data. If there is any doubt, better be safe than
        sorry by deleting the asset.
      </p>
      <p>
        Lastly, the process includes computing the algorithm across a large database. Every day, our supercomputers calculate millions of financial
        ratios and apply filters and logic from financial theory.
      </p>
      <p className="text-secondary font-bold ml-5"> STEP 0 : Translate Theory into Algorithm</p>
      <p className="text-primary font-bold ml-5"> STEP 1 : Extract Financial Data </p>
      <p className="text-primary font-bold ml-5"> STEP 2 : Clean Financial Data </p>
      <p className="text-primary font-bold ml-5"> STEP 3 : Applied Algorithm with large calculator</p>
      <p className="text-primary font-bold ml-5"> STEP 4 : Loop to Step 1 </p>
    </div>
  );
};

export default ScreenerDocumentation;
