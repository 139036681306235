import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const BadgeFavorite = ({ name, favorites }) => {
  if (favorites.isPending) {
    return "";
  }

  if (favorites?.data) {
    if (favorites.data.includes(name)) {
      return (
        <span className=" z-20 w-8 h-8 absolute items-center -top-4 -right-4 rounded-full text-base font-medium ring-1 ring-yellow-200 bg-white dark:bg-slate-700 dark:ring-slate-900  ">
          <FontAwesomeIcon className=" mt-[7px] ms-[7px] text-yellow-300 dark:text-amber-400" icon={faStar} />
        </span>
      );
    }
  }

  return "";
};

export default BadgeFavorite;
