import React from "react";

const BillAckmanArticle = () => {
  return (
    <div className="screener-article">
      William Albert Ackman (born May 11, 1966) is an American billionaire hedge fund manager who is the founder and chief executive officer of
      Pershing Square Capital Management, a hedge fund management firm. His investment approach has made him an activist investor. As of January 2024,
      Ackman's net worth was estimated by Forbes to be $4 billion.
      <h3>Early life and education</h3>
      <p>
        Ackman grew up in Chappaqua, New York, the son of Ronnie I. (née Posner) and Lawrence David Ackman, the former chairman of a New York real
        estate financing firm, Ackman-Ziff Real Estate Group. He is of Ashkenazi Jewish descent. In 1988, he received a Bachelor of Arts degree, magna
        cum laude, in social studies from Harvard College. His senior thesis was entitled Scaling the Ivy Wall: The Jewish and Asian American
        Experience in Harvard Admissions." In 1992, he received a Master of Business Administration degree from Harvard Business School.
      </p>
      <h3>Professional career - Gotham Partners </h3>
      <p>
        In 1992, Ackman and fellow Harvard graduate David P. Berkowitz founded the investment firm Gotham Partners, which made small investments in
        public companies. In 1995, Ackman partnered with insurance and real estate firm Leucadia National to bid on Rockefeller Center. Although they
        did not win the deal, the bid sparked investor interest in Gotham: three years later, Gotham had $500 million in assets under management
        (AUM). By 2002, Gotham was embroiled in litigation with various outside shareholders who also owned stakes in the companies in which Gotham
        invested.
      </p>
      <p>
        In 2002, Ackman researched MBIA to challenge Standard & Poor's AAA rating on its bonds. He was charged fees for copying 725,000 pages of
        statements about the financial services company as part of his law firm's compliance with a subpoena. Ackman called for a separation between
        MBIA's structured finance business and its municipal bond insurance business.
      </p>
      <p>
        He argued that MBIA was legally restricted from trading billions of dollars of credit default swap (CDS) protection that MBIA had sold on
        various mortgage-backed collateralized debt obligations (CDOs), and was using a second company, LaCrosse Financial Products, which MBIA
        described as an "orphan transformer". Ackman bought credit default swaps on MBIA corporate debt and sold them for a large profit during the
        financial crisis of 2007-2008. He reported covering his short position in MBIA on January 16, 2009, according to the 13D filed with the SEC.
      </p>
      <p>
        In 2003, a feud developed between Ackman and Carl Icahn over a deal involving Hallwood Realty. They agreed to a "shmuck insurance" arrangement
        under which if Icahn sold the shares within 3 years and made a profit of 10% or more, he and Ackman would split the proceeds. Icahn paid $80
        per share. In April 2004, HRPT Property Trust acquired Hallwood for $136.16 per share. Under the terms of the "contract," Icahn owed Ackman
        and his investors approximately $4.5 million. Icahn refused to pay. Ackman sued. Eight years later, Icahn was forced by court order to pay the
        $4.5 million plus 9% interest per year.
      </p>
      <h3>Pershing Square Capital Management</h3>
      <p>
        In 2004, Ackman launched Pershing Square Capital Management with $54 million from his personal funds and from his former business partner
        Leucadia National.
      </p>
      <p>
        In 2010, Pershing began buying J.C. Penney stock, paying an average of $22 for 39 million shares, or 18% of J.C. Penney. In August 2013, the
        two-year campaign to turn around the department store came to an abrupt end when Ackman resigned from the board after a disagreement with
        other board members.
      </p>
      <p>
        In January 2015, LCH Investments named Ackman one of the world's top 20 hedge fund managers after Pershing Square delivered $4.5 billion in
        net gains for investors in 2014, bringing the fund's lifetime gains to $11.6 billion from its inception in 2004 through the end of 2014.
      </p>
      <h3>Herbalife short</h3>
      <p>
        In December 2012, Ackman published a research report criticizing Herbalife's multi-level marketing business model, calling it a pyramid scheme
        Ackman disclosed that his hedge fund, Pershing Square Capital Management, had been shorting the company's stock directly (not through
        derivatives) since May 2012, causing Herbalife's stock price to fall.
      </p>
      <p>
        A few months after Ackman's initial comments, billionaire investor Carl Icahn challenged Ackman's comments in a public spat on national
        television. Shortly thereafter, Icahn began buying shares of Herbalife International (HLF).As Icahn continued to buy HLF shares, the stock
        price continued to show strength. By May 2013, Icahn owned 16.5% of the company. By November 2013, that number had dropped to 6.4%.In 2014,
        Ackman spent $50 million on a public relations campaign against Herbalife.
      </p>
      <p>
        Former Representative Bob Barr (R-GA) called on Congress to investigate Ackman's use of public relations and regulatory pressure in his brief
        campaign, and Harvey Pitt, a former chairman of the U.S. Securities and Exchange Commission (SEC), questioned whether Ackman was aiming to
        move the price rather than spread the truth. In 2014, Senator Ed Markey wrote letters to federal regulators, including the Federal Trade
        Commission (FTC) and the SEC, asking them to open an investigation into Herbalife's business practices.The day the letters were made public,
        the company's stock dropped 14%. Markey later told the Boston Globe that his staff had not informed him that Ackman stood to gain financially
        from his actions, and defended the letters as a matter of consumer rights. The New York Times reported that Ackman had donated $32,000 to the
        Democratic Senatorial Campaign Committee on April 30, 2013, the same day Markey won the Democratic primary for the Massachusetts special
        Senate election.The Democratic Senatorial Campaign Committee then donated a significant amount to Markey's campaign just over a month later.
      </p>
      <p>
        In March 2014, The New York Times reported that Ackman had used tactics to undermine public confidence in Herbalife in order to lower its
        stock price, including pressuring state and federal regulators to investigate the company, paying individuals to travel to and attend rallies
        against the company, and increasing Pershing Square's spending on donations to Latino nonprofit organizations. According to the article,
        groups such as the Hispanic Federation and the National Consumers League sent numerous letters to federal regulators."Each person contacted by
        The Times acknowledged in interviews that they wrote the letters after being lobbied by Pershing Square representatives, or said they did not
        recall writing the letters at all.Mr. Ackman's team also then began making payments totaling about $130,000 to some of these groups, including
        the Hispanic Federation - money he said would be used to help find victims of Herbalife."
      </p>
      <p>
        By December 2, 2014, the stock price had fallen nearly 50% from its January 8 high of $83.48 to $42.08. Later that month, Pershing Square
        Capital released a 2005 Herbalife distributor training session in which an employee described high turnover rates and implied that the
        company's business model was not sustainable. In an interview with Bloomberg, Ackman predicted that the company would experience an
        "implosion" in 2015 or early 2016, citing federal scrutiny and debt.
      </p>
      <p>
        On March 12, 2015, The Wall Street Journal reported that prosecutors in the Manhattan U.S. Attorney's Office and the FBI were investigating
        whether people hired by Ackman "made false statements about Herbalife's business model to regulators and others to spur investigations into
        the company and drive down its stock price." In March 2015, U.S. District Judge Dale Fischer in Los Angeles, California, dismissed a lawsuit
        filed by Herbalife investors alleging that the company operated an illegal pyramid scheme. In response to Fischer's ruling, Herbalife's stock
        rose approximately 13% Herbalife and the FTC reached a settlement agreement in July 2016, ending the agency's investigation of the company. On
        the day of the settlement, Fortune estimated that Ackman had lost $500 million.
      </p>
      <p>
        Ackman's position on Herbalife led to a nearly half-hour live television debate with Herbalife supporter Carl Icahn on CNBC on January 25,
        2013, during which Icahn called Ackman a "schoolyard crybaby" and claimed that going public with his short position would eventually force
        Ackman into the "mother of all short squeezes". On November 22, 2013, Ackman admitted on Bloomberg Television that Pershing Square's open
        short position in Herbalife was "$400 million to $500 million" in the red, but that he wouldn't be squeezed and would hold the short "to the
        end of the earth."
      </p>
      <p>
        In November 2017, Ackman told Reuters that he had covered his short position, but would continue to bet against Herbalife using put options
        with no more than 3% of Pershing Square's funds.On February 28, 2018, Ackman exited his nearly $1 billion bet against Herbalife after the
        company's stock price continued to rise.
      </p>
      <h3>COVID-19 Response</h3>
      <p>
        Ahead of the 2020 stock market crash, Ackman hedged Pershing Square's portfolio by investing $27 million to buy credit protection, insuring
        the portfolio against steep market losses Pershing Square first disclosed the hedge on March 3, 2020. According to Reuters, "Ackman said
        hedging was preferable to selling his portfolio of companies whose businesses were otherwise strong." The hedge was effective, generating $2.6
        billion in less than a month.On March 18, 2020, in an emotional interview with CNBC, Ackman asked President Trump for a "30-day shutdown" of
        the American economy to slow the spread of the coronavirus and minimize the loss of life and subsequent economic destruction resulting from
        the shutdown. Ackman warned that without intervention, hotel stocks would "go to zero" and said America could "end as we know it".He also
        warned U.S. companies to stop stock buyback programs because "hell is coming."
      </p>
      <p>
        Ackman was later criticized for actively buying discounted shares in the very companies he warned could fail; however, Ackman had already
        realized about half of the profits before appearing in the CNBC interview.
      </p>
      <p>
        In a November 2020 interview, Ackman said he became concerned about COVID-19 because he had seen the movie Contagion.Investment style Ackman
        has said that his most successful investments have always been controversial, and that his first rule of activist investing is to "make a bold
        call that nobody believes in." He has also said that his most successful investments have always been controversial.
      </p>
      <p>
        His most notable market plays include shorting MBIA bonds during the 2007-2008 financial crisis, his proxy fight against Canadian Pacific
        Railway, and his stakes in Target Corporation, Valeant Pharmaceuticals, and Chipotle Mexican Grill. From 2012 to 2018, Ackman held a US$1
        billion short against the nutrition company Herbalife, a company he has described as a pyramid scheme designed as a multi-level marketing
        company, and his efforts were chronicled in the documentary Betting on Zero.
      </p>
      <p>
        After weak performance from 2015 to 2018, Ackman told investors in January 2018 that he was going back to basics by cutting staff, ending
        investor visits that were eating up his time, and hunkering down in the office to do research. The following year, Pershing Square returned
        58.1%, qualifying it as "one of the world's best-performing hedge funds" for 2019, according to Reuters.
      </p>
      <p>Ackman has said that he admires short sellers such as Carson Block of Muddy Waters Capital and Andrew Left of Citron Research.</p>
      <h3>Philanthropy</h3>
      <p>
        He is a signatory of The Giving Pledge, which commits him to giving away at least 50% of his wealth to charitable causes by the end of his
        life.
      </p>
      <p>
        Ackman has donated to charitable causes such as the Center for Jewish History, where he led a successful effort to retire $30 million in debt,
        personally donating $6.8 million. The donation, along with those of Bruce Berkowitz, founder of Fairholme Capital Management, and Joseph
        Steinberg, president of Leucadia National, were the three largest individual gifts the Center has ever received.
      </p>
      <p>Ackman's foundation has donated $1.1 million to the Innocence Project in New York City and Centurion Ministries in Princeton, New Jersey.</p>
      <p>
        In 2006, Ackman and his then-wife Karen established the Pershing Square Foundation to support innovation in economic development, education,
        health care, human rights, the arts, and urban development; the foundation is a major donor to Planned Parenthood; since its inception in
        2006, the foundation has awarded more than $400 million in grants; in 2011, the Ackmans were named to The Chronicle of Philanthropy's
        "Philanthropy 50" list of the most generous donors.
      </p>
      <p>
        In July 2014, the Challenged Athletes Foundation, which provides sports equipment to people with physical disabilities, honored Ackman at a
        gala fundraiser at the Waldorf Astoria Hotel in New York City for helping to raise a record $2.3 million. Ackman is a member of the
        foundation's board of directors.
      </p>
      <p>
        On March 15, 2021, he announced that he had donated 26.5 million shares of South Korean e-commerce company Coupang, valued at $1.36 billion,
        to three entities, including his own foundation.
      </p>
      <p>
        In December 2022, Ackman partnered with the David Lynch Foundation to auction off a charity lunch with himself. Proceeds would go to support
        "New York's frontline healthcare workers, police, and veterans who battle anxiety, depression, addiction, and suicidality every day", with
        Ackman matching the winning bid to support the foundation. The highest winning bid on previous occasions was $210,000.Ackman is a supporter of
        David M. Sabatini, a biologist who was previously fired from the Howard Hughes Medical Institute and resigned from the Whitehead Institute and
        MIT due to allegations and investigations of sexual misconduct. On March 1, 2022, he made remarks at a Pershing Square Foundation event about
        Sabatini's unfair treatment In February 2023, Ackman announced that his foundation and an anonymous donor would jointly fund Sabatini $25
        million over five years to establish and run a new research lab.
      </p>
    </div>
  );
};

export default BillAckmanArticle;
