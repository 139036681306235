export const dataChart = [
  {
    date: "1998-01-31",

    value1: 0.81,

    value2: 2.16,

    value3: 1.48,

    value4: 1,
  },

  {
    date: "1998-02-28",

    value1: 7.0804,

    value2: 10.1387,

    value3: 12.3384,

    value4: 8.1,
  },

  {
    date: "1998-03-31",

    value1: 5.9989,

    value2: 9.2576,

    value3: 12.1923,

    value4: 13.5,
  },

  {
    date: "1998-04-30",

    value1: 7.5889,

    value2: 11.6176,

    value3: 15.8834,

    value4: 14.6,
  },

  {
    date: "1998-05-31",

    value1: 1.7683,

    value2: 4.3066,

    value3: 10.8193,

    value4: 12.4,
  },

  {
    date: "1998-06-30",

    value1: 1.7785,

    value2: 1.3756,

    value3: 13.4014,

    value4: 16.8,
  },

  {
    date: "1998-07-31",

    value1: -6.7404,

    value2: -4.9604,

    value3: 9.7726,

    value4: 15.5,
  },

  {
    date: "1998-08-31",

    value1: -25.784,

    value2: -21.6303,

    value3: -7.2202,

    value4: -1.4,
  },

  {
    date: "1998-09-30",

    value1: -20.2178,

    value2: -14.0598,

    value3: -2.7482,

    value4: 4.8,
  },

  {
    date: "1998-10-31",

    value1: -11.721,

    value2: -6.4197,

    value3: 9.1651,

    value4: 13.2,
  },

  {
    date: "1998-11-30",

    value1: -4.1908,

    value2: 1.8902,

    value3: 17.1778,

    value4: 19.9,
  },

  {
    date: "1998-12-31",

    value1: 4.0296,

    value2: 7.433,

    value3: 27.4543,

    value4: 26.7,
  },

  {
    date: "1999-01-31",

    value1: 7.1401,

    value2: 12.6005,

    value3: 37.5997,

    value4: 31.9,
  },

  {
    date: "1999-02-28",

    value1: -3.4453,

    value2: 0.7887,

    value3: 29.729,

    value4: 27.6,
  },

  {
    date: "1999-03-31",

    value1: -5.9654,

    value2: -1.5395,

    value3: 36.9419,

    value4: 32.6,
  },

  {
    date: "1999-04-30",

    value1: 2.714,

    value2: 7.4401,

    value3: 44.3916,

    value4: 37.6,
  },

  {
    date: "1999-05-31",

    value1: 3.8438,

    value2: 10.2658,

    value3: 44.1028,

    value4: 34.1,
  },

  {
    date: "1999-06-30",

    value1: 8.5791,

    value2: 14.5772,

    value3: 50.5298,

    value4: 41.4,
  },

  {
    date: "1999-07-31",

    value1: 10.9787,

    value2: 17.6135,

    value3: 51.3577,

    value4: 36.9,
  },

  {
    date: "1999-08-31",

    value1: 4.3755,

    value2: 11.4153,

    value3: 46.1964,

    value4: 36,
  },

  {
    date: "1999-09-30",

    value1: 7.8199,

    value2: 11.3707,

    value3: 40.6555,

    value4: 32.2,
  },

  {
    date: "1999-10-31",

    value1: 11.0976,

    value2: 12.9521,

    value3: 46.7037,

    value4: 40.4,
  },

  {
    date: "1999-11-30",

    value1: 13.7084,

    value2: 13.8784,

    value3: 53.4228,

    value4: 43.1,
  },

  {
    date: "1999-12-31",

    value1: 22.4867,

    value2: 23.0683,

    value3: 67.5223,

    value4: 51.4,
  },

  {
    date: "2000-01-31",

    value1: 16.889,

    value2: 18.8102,

    value3: 66.5172,

    value4: 43.7,
  },

  {
    date: "2000-02-29",

    value1: 15.5916,

    value2: 17.23,

    value3: 64.0694,

    value4: 40.8,
  },

  {
    date: "2000-03-31",

    value1: 22.4462,

    value2: 28.32,

    value3: 85.0703,

    value4: 54.4,
  },

  {
    date: "2000-04-30",

    value1: 18.3075,

    value2: 17.8619,

    value3: 78.6483,

    value4: 49.6,
  },

  {
    date: "2000-05-31",

    value1: 14.5098,

    value2: 15.54,

    value3: 69.2693,

    value4: 46.4,
  },

  {
    date: "2000-06-30",

    value1: 17.029,

    value2: 15.2743,

    value3: 76.5986,

    value4: 49.9,
  },

  {
    date: "2000-07-31",

    value1: 19.393,

    value2: 18.1215,

    value3: 79.1063,

    value4: 47.4,
  },

  {
    date: "2000-08-31",

    value1: 26.9625,

    value2: 25.5986,

    value3: 87.4169,

    value4: 56.4,
  },

  {
    date: "2000-09-30",

    value1: 23.4711,

    value2: 19.2936,

    value3: 77.8399,

    value4: 48,
  },

  {
    date: "2000-10-31",

    value1: 28.4963,

    value2: 26.7256,

    value3: 84.1354,

    value4: 47.3,
  },

  {
    date: "2000-11-30",

    value1: 23.7291,

    value2: 21.5678,

    value3: 79.8635,

    value4: 35.5,
  },

  {
    date: "2000-12-31",

    value1: 29.7547,

    value2: 27.0992,

    value3: 86.6803,

    value4: 36,
  },

  {
    date: "2001-01-31",

    value1: 43.0416,

    value2: 46.0369,

    value3: 111.3594,

    value4: 40.7,
  },

  {
    date: "2001-02-28",

    value1: 36.8765,

    value2: 38.0925,

    value3: 100.3476,

    value4: 27.7,
  },

  {
    date: "2001-03-31",

    value1: 30.1696,

    value2: 29.7517,

    value3: 92.1534,

    value4: 19.5,
  },

  {
    date: "2001-04-30",

    value1: 49.656,

    value2: 51.1089,

    value3: 113.5401,

    value4: 28.7,
  },

  {
    date: "2001-05-31",

    value1: 50.7634,

    value2: 52.5898,

    value3: 116.4015,

    value4: 29.4,
  },

  {
    date: "2001-06-30",

    value1: 51.1102,

    value2: 49.0802,

    value3: 108.1133,

    value4: 26.1,
  },

  {
    date: "2001-07-31",

    value1: 51.9564,

    value2: 50.0194,

    value3: 115.1684,

    value4: 24.8,
  },

  {
    date: "2001-08-31",

    value1: 44.237,

    value2: 45.0688,

    value3: 103.4417,

    value4: 16.8,
  },

  {
    date: "2001-09-30",

    value1: 21.9812,

    value2: 18.9129,

    value3: 74.7361,

    value4: 7.3,
  },

  {
    date: "2001-10-31",

    value1: 34.5331,

    value2: 33.2181,

    value3: 83.2981,

    value4: 9.2,
  },

  {
    date: "2001-11-30",

    value1: 52.4529,

    value2: 50.4965,

    value3: 101.5363,

    value4: 17.4,
  },

  {
    date: "2001-12-31",

    value1: 63.0789,

    value2: 64.8538,

    value3: 112.6611,

    value4: 18.3,
  },

  {
    date: "2002-01-31",

    value1: 62.4592,

    value2: 63.9142,

    value3: 115.2556,

    value4: 16.5,
  },

  {
    date: "2002-02-28",

    value1: 61.4194,

    value2: 62.8487,

    value3: 115.234,

    value4: 14,
  },

  {
    date: "2002-03-31",

    value1: 71.153,

    value2: 74.8995,

    value3: 129.9991,

    value4: 18.2,
  },

  {
    date: "2002-04-30",

    value1: 72.2313,

    value2: 73.3604,

    value3: 126.8481,

    value4: 11,
  },

  {
    date: "2002-05-31",

    value1: 69.6995,

    value2: 72.8577,

    value3: 121.8348,

    value4: 9.9,
  },

  {
    date: "2002-06-30",

    value1: 65.0158,

    value2: 65.3729,

    value3: 111.2089,

    value4: 2,
  },

  {
    date: "2002-07-31",

    value1: 49.4713,

    value2: 49.7452,

    value3: 97.607,

    value4: -6.1,
  },

  {
    date: "2002-08-31",

    value1: 50.3233,

    value2: 49.8051,

    value3: 96.2633,

    value4: -5.6,
  },

  {
    date: "2002-09-30",

    value1: 37.2151,

    value2: 33.7759,

    value3: 86.6071,

    value4: -16,
  },

  {
    date: "2002-10-31",

    value1: 47.0534,

    value2: 43.8493,

    value3: 101.9462,

    value4: -8.7,
  },

  {
    date: "2002-11-30",

    value1: 53.6414,

    value2: 54.3646,

    value3: 109.6606,

    value4: -3.5,
  },

  {
    date: "2002-12-31",

    value1: 45.3755,

    value2: 45.2417,

    value3: 94.1457,

    value4: -9.3,
  },

  {
    date: "2003-01-31",

    value1: 40.5636,

    value2: 40.2163,

    value3: 90.0492,

    value4: -11.8,
  },

  {
    date: "2003-02-28",

    value1: 36.8667,

    value2: 36.108,

    value3: 87.6736,

    value4: -13.3,
  },

  {
    date: "2003-03-31",

    value1: 41.1644,

    value2: 41.5523,

    value3: 93.0036,

    value4: -12.6,
  },

  {
    date: "2003-04-30",

    value1: 51.2011,

    value2: 56.2879,

    value3: 104.3522,

    value4: -5.5,
  },

  {
    date: "2003-05-31",

    value1: 56.6746,

    value2: 60.4921,

    value3: 110.8301,

    value4: -0.7,
  },

  {
    date: "2003-06-30",

    value1: 61.7196,

    value2: 64.9858,

    value3: 114.6672,

    value4: 0.4,
  },

  {
    date: "2003-07-31",

    value1: 68.156,

    value2: 75.5614,

    value3: 126.2593,

    value4: 2,
  },

  {
    date: "2003-08-31",

    value1: 74.6804,

    value2: 81.5305,

    value3: 134.1557,

    value4: 3.9,
  },

  {
    date: "2003-09-30",

    value1: 70.8375,

    value2: 79.9512,

    value3: 128.4189,

    value4: 2.6,
  },

  {
    date: "2003-10-31",

    value1: 86.1103,

    value2: 95.337,

    value3: 150.1872,

    value4: 8,
  },

  {
    date: "2003-11-30",

    value1: 90.8189,

    value2: 99.3024,

    value3: 159.1689,

    value4: 9,
  },

  {
    date: "2003-12-31",

    value1: 93.1088,

    value2: 99.8006,

    value3: 162.4604,

    value4: 14.6,
  },

  {
    date: "2004-01-31",

    value1: 93.6302,

    value2: 101.7187,

    value3: 163.7727,

    value4: 16.6,
  },

  {
    date: "2004-02-29",

    value1: 104.609,

    value2: 112.7527,

    value3: 170.6835,

    value4: 18,
  },

  {
    date: "2004-03-31",

    value1: 110.4813,

    value2: 116.327,

    value3: 168.0308,

    value4: 16,
  },

  {
    date: "2004-04-30",

    value1: 102.7145,

    value2: 111.1351,

    value3: 171.7029,

    value4: 14.1,
  },

  {
    date: "2004-05-31",

    value1: 105.1065,

    value2: 113.162,

    value3: 176.1588,

    value4: 15.5,
  },

  {
    date: "2004-06-30",

    value1: 106.7269,

    value2: 115.3363,

    value3: 180.9916,

    value4: 17.6,
  },

  {
    date: "2004-07-31",

    value1: 91.9046,

    value2: 100.112,

    value3: 191.6411,

    value4: 13.5,
  },

  {
    date: "2004-08-31",

    value1: 92.0773,

    value2: 104.3344,

    value3: 190.0079,

    value4: 13.8,
  },

  {
    date: "2004-09-30",

    value1: 101.2394,

    value2: 113.8972,

    value3: 195.6341,

    value4: 14.9,
  },

  {
    date: "2004-10-31",

    value1: 100.8771,

    value2: 113.1058,

    value3: 211.3618,

    value4: 16.5,
  },

  {
    date: "2004-11-30",

    value1: 106.9838,

    value2: 123.2283,

    value3: 223.4115,

    value4: 21,
  },

  {
    date: "2004-12-31",

    value1: 118.5128,

    value2: 134.7022,

    value3: 234.9897,

    value4: 24.9,
  },

  {
    date: "2005-01-31",

    value1: 111.7389,

    value2: 129.5623,

    value3: 235.5592,

    value4: 21.7,
  },

  {
    date: "2005-02-28",

    value1: 121.1825,

    value2: 135.0947,

    value3: 237.539,

    value4: 24,
  },

  {
    date: "2005-03-31",

    value1: 115.0999,

    value2: 131.6153,

    value3: 241.0494,

    value4: 21.7,
  },

  {
    date: "2005-04-30",

    value1: 100.215,

    value2: 120.4283,

    value3: 230.852,

    value4: 19.2,
  },

  {
    date: "2005-05-31",

    value1: 113.7896,

    value2: 133.8965,

    value3: 254.4086,

    value4: 22.8,
  },

  {
    date: "2005-06-30",

    value1: 116.3123,

    value2: 138.1534,

    value3: 259.3349,

    value4: 22.8,
  },

  {
    date: "2005-07-31",

    value1: 130.7836,

    value2: 151.609,

    value3: 278.5593,

    value4: 27.2,
  },

  {
    date: "2005-08-31",

    value1: 127.3219,

    value2: 143.3814,

    value3: 264.5905,

    value4: 25.9,
  },

  {
    date: "2005-09-30",

    value1: 130.277,

    value2: 143.9899,

    value3: 262.403,

    value4: 26.6,
  },

  {
    date: "2005-10-31",

    value1: 128.3197,

    value2: 141.0376,

    value3: 257.9092,

    value4: 24.4,
  },

  {
    date: "2005-11-30",

    value1: 144.3934,

    value2: 155.4758,

    value3: 279.8132,

    value4: 28.8,
  },

  {
    date: "2005-12-30",

    value1: 144.6133,

    value2: 156.932,

    value3: 273.0525,

    value4: 28.7,
  },

  {
    date: "2006-01-31",

    value1: 158.6786,

    value2: 166.644,

    value3: 281.6327,

    value4: 31.9,
  },

  {
    date: "2006-02-28",

    value1: 158.1095,

    value2: 166.5107,

    value3: 289.7615,

    value4: 32,
  },

  {
    date: "2006-03-31",

    value1: 168.9759,

    value2: 173.0135,

    value3: 303.3252,

    value4: 33.5,
  },

  {
    date: "2006-04-30",

    value1: 172.4457,

    value2: 178.6922,

    value3: 306.0678,

    value4: 35.1,
  },

  {
    date: "2006-05-31",

    value1: 155.6631,

    value2: 162.6953,

    value3: 285.7238,

    value4: 30.9,
  },

  {
    date: "2006-06-30",

    value1: 156.0721,

    value2: 166.4781,

    value3: 281.1723,

    value4: 30.9,
  },

  {
    date: "2006-07-31",

    value1: 145.522,

    value2: 153.8737,

    value3: 270.8044,

    value4: 31.6,
  },

  {
    date: "2006-08-31",

    value1: 143.877,

    value2: 155.143,

    value3: 274.5124,

    value4: 34.4,
  },

  {
    date: "2006-09-30",

    value1: 148.6082,

    value2: 163.0015,

    value3: 295.1106,

    value4: 37.7,
  },

  {
    date: "2006-10-31",

    value1: 157.6078,

    value2: 172.4169,

    value3: 312.1004,

    value4: 42,
  },

  {
    date: "2006-11-30",

    value1: 167.3711,

    value2: 185.4929,

    value3: 317.2516,

    value4: 44.4,
  },

  {
    date: "2006-12-31",

    value1: 166.0877,

    value2: 179.2977,

    value3: 315.374,

    value4: 46.2,
  },

  {
    date: "2007-01-31",

    value1: 175.2412,

    value2: 187.9559,

    value3: 331.6567,

    value4: 48.3,
  },

  {
    date: "2007-02-28",

    value1: 175.5439,

    value2: 184.4141,

    value3: 328.2897,

    value4: 45,
  },

  {
    date: "2007-03-31",

    value1: 184.8573,

    value2: 194.2833,

    value3: 338.9542,

    value4: 46.5,
  },

  {
    date: "2007-04-30",

    value1: 197.3341,

    value2: 204.9069,

    value3: 353.3958,

    value4: 52.8,
  },

  {
    date: "2007-05-31",

    value1: 205.6891,

    value2: 213.4138,

    value3: 381.8237,

    value4: 57.8,
  },

  {
    date: "2007-06-30",

    value1: 202.7545,

    value2: 211.9721,

    value3: 377.9691,

    value4: 54.9,
  },

  {
    date: "2007-07-31",

    value1: 191.2499,

    value2: 199.5868,

    value3: 355.6479,

    value4: 50,
  },

  {
    date: "2007-08-31",

    value1: 192.3857,

    value2: 203.6612,

    value3: 355.3745,

    value4: 52,
  },

  {
    date: "2007-09-30",

    value1: 199.9293,

    value2: 209.8255,

    value3: 375.8208,

    value4: 57.4,
  },

  {
    date: "2007-10-31",

    value1: 203.0485,

    value2: 215.0615,

    value3: 393.8069,

    value4: 59.7,
  },

  {
    date: "2007-11-30",

    value1: 184.4414,

    value2: 196.3154,

    value3: 369.3634,

    value4: 52.7,
  },

  {
    date: "2007-12-31",

    value1: 181.6254,

    value2: 190.3002,

    value3: 375.0897,

    value4: 51.4,
  },

  {
    date: "2008-01-31",

    value1: 165.9389,

    value2: 177.9915,

    value3: 349.7674,

    value4: 42.1,
  },

  {
    date: "2008-02-29",

    value1: 171.1247,

    value2: 180.6046,

    value3: 361.0566,

    value4: 37.2,
  },

  {
    date: "2008-03-31",

    value1: 173.9173,

    value2: 181.3622,

    value3: 347.4554,

    value4: 36.4,
  },

  {
    date: "2008-04-30",

    value1: 192.6806,

    value2: 199.9884,

    value3: 377.0322,

    value4: 42.8,
  },

  {
    date: "2008-05-31",

    value1: 204.8561,

    value2: 224.2574,

    value3: 414.9085,

    value4: 44.4,
  },

  {
    date: "2008-06-30",

    value1: 189.1255,

    value2: 213.0706,

    value3: 379.9977,

    value4: 32,
  },

  {
    date: "2008-07-31",

    value1: 180.3939,

    value2: 208.9067,

    value3: 382.9257,

    value4: 30.6,
  },

  {
    date: "2008-08-31",

    value1: 186.5346,

    value2: 216.7838,

    value3: 399.7315,

    value4: 32.2,
  },

  {
    date: "2008-09-30",

    value1: 149.8008,

    value2: 183.87,

    value3: 357.1544,

    value4: 20.1,
  },

  {
    date: "2008-10-31",

    value1: 97.8672,

    value2: 126.0173,

    value3: 270.4322,

    value4: -0.1,
  },

  {
    date: "2008-11-30",

    value1: 75.528,

    value2: 101.4266,

    value3: 243.3907,

    value4: -7.6,
  },

  {
    date: "2008-12-31",

    value1: 77.6168,

    value2: 106.3414,

    value3: 252.5592,

    value4: -6.9,
  },

  {
    date: "2009-01-30",

    value1: 69.8905,

    value2: 98.4179,

    value3: 225.3064,

    value4: -14.9,
  },

  {
    date: "2009-02-28",

    value1: 56.2483,

    value2: 82.9016,

    value3: 197.1348,

    value4: -24.2,
  },

  {
    date: "2009-03-31",

    value1: 65.0294,

    value2: 94.4427,

    value3: 221.3513,

    value4: -17.8,
  },

  {
    date: "2009-04-30",

    value1: 89.9489,

    value2: 123.9786,

    value3: 259.2065,

    value4: -10,
  },

  {
    date: "2009-05-31",

    value1: 109.9885,

    value2: 149.4897,

    value3: 277.0232,

    value4: -5.3,
  },

  {
    date: "2009-06-30",

    value1: 113.8733,

    value2: 154.9037,

    value3: 280.6803,

    value4: -5.2,
  },

  {
    date: "2009-07-31",

    value1: 137.5704,

    value2: 187.149,

    value3: 302.6455,

    value4: 1.8,
  },

  {
    date: "2009-08-31",

    value1: 141.5141,

    value2: 190.6522,

    value3: 312.2688,

    value4: 5.2,
  },

  {
    date: "2009-09-30",

    value1: 165.2308,

    value2: 220.444,

    value3: 338.2005,

    value4: 9,
  },

  {
    date: "2009-10-31",

    value1: 156.1599,

    value2: 208.9721,

    value3: 319.8399,

    value4: 6.8,
  },

  {
    date: "2009-11-30",

    value1: 162.8969,

    value2: 219.0137,

    value3: 335.7938,

    value4: 12.9,
  },

  {
    date: "2009-12-31",

    value1: 179.7749,

    value2: 246.6403,

    value3: 358.3243,

    value4: 14.9,
  },

  {
    date: "2010-01-31",

    value1: 166.5415,

    value2: 230.1749,

    value3: 337.8372,

    value4: 10.7,
  },

  {
    date: "2010-02-28",

    value1: 180.4017,

    value2: 250.4807,

    value3: 356.8394,

    value4: 13.8,
  },

  {
    date: "2010-03-31",

    value1: 204.0115,

    value2: 281.1828,

    value3: 387.4476,

    value4: 20.5,
  },

  {
    date: "2010-04-30",

    value1: 213.0102,

    value2: 291.8178,

    value3: 404.5083,

    value4: 22.3,
  },

  {
    date: "2010-05-31",

    value1: 195.3252,

    value2: 266.4672,

    value3: 371.0089,

    value4: 12.3,
  },

  {
    date: "2010-06-30",

    value1: 182.1832,

    value2: 248.1438,

    value3: 352.6867,

    value4: 6.2,
  },

  {
    date: "2010-07-31",

    value1: 193.2165,

    value2: 263.8103,

    value3: 385.1896,

    value4: 13.5,
  },

  {
    date: "2010-08-31",

    value1: 174.9785,

    value2: 236.1243,

    value3: 366.5583,

    value4: 8.2,
  },

  {
    date: "2010-09-30",

    value1: 203.9887,

    value2: 274.5097,

    value3: 421.8921,

    value4: 17.6,
  },

  {
    date: "2010-10-31",

    value1: 210.8588,

    value2: 276.6444,

    value3: 447.1517,

    value4: 22,
  },

  {
    date: "2010-11-30",

    value1: 225.9355,

    value2: 293.5181,

    value3: 463.4021,

    value4: 21.7,
  },

  {
    date: "2010-12-31",

    value1: 237.0499,

    value2: 310.282,

    value3: 485.1494,

    value4: 29.6,
  },

  {
    date: "2011-01-31",

    value1: 235.6006,

    value2: 302.8148,

    value3: 486.3197,

    value4: 32.6,
  },

  {
    date: "2011-02-28",

    value1: 246.3062,

    value2: 317.2356,

    value3: 518.3328,

    value4: 36.8,
  },

  {
    date: "2011-03-31",

    value1: 252.609,

    value2: 319.447,

    value3: 524.5779,

    value4: 36.7,
  },

  {
    date: "2011-04-30",

    value1: 261.1422,

    value2: 332.0304,

    value3: 547.7498,

    value4: 40.6,
  },

  {
    date: "2011-05-31",

    value1: 252.6914,

    value2: 322.4393,

    value3: 548.2032,

    value4: 38.7,
  },

  {
    date: "2011-06-30",

    value1: 253.0089,

    value2: 322.0591,

    value3: 550.7312,

    value4: 36.1,
  },

  {
    date: "2011-07-31",

    value1: 254.8445,

    value2: 325.3934,

    value3: 556.5878,

    value4: 33.2,
  },

  {
    date: "2011-08-31",

    value1: 249.8412,

    value2: 316.4176,

    value3: 537.4811,

    value4: 25.6,
  },

  {
    date: "2011-09-30",

    value1: 215.8016,

    value2: 268.0715,

    value3: 477.8128,

    value4: 16.6,
  },

  {
    date: "2011-10-31",

    value1: 251.7715,

    value2: 316.3257,

    value3: 543.2213,

    value4: 29.2,
  },

  {
    date: "2011-11-30",

    value1: 248.8869,

    value2: 304.1689,

    value3: 540.5197,

    value4: 28.5,
  },

  {
    date: "2011-12-31",

    value1: 250.0034,

    value2: 313.1819,

    value3: 533.3459,

    value4: 29.6,
  },

  {
    date: "2012-01-31",

    value1: 282.0637,

    value2: 352.3102,

    value3: 578.5035,

    value4: 35.3,
  },

  {
    date: "2012-02-29",

    value1: 291.9591,

    value2: 351.4508,

    value3: 620.7064,

    value4: 40.8,
  },

  {
    date: "2012-03-31",

    value1: 299.6415,

    value2: 355.1979,

    value3: 648.8139,

    value4: 45.2,
  },

  {
    date: "2012-04-30",

    value1: 303.4381,

    value2: 346.7312,

    value3: 646.4926,

    value4: 44.1,
  },

  {
    date: "2012-05-31",

    value1: 267.5321,

    value2: 307.3295,

    value3: 611.4821,

    value4: 35,
  },

  {
    date: "2012-06-30",

    value1: 279.8812,

    value2: 327.7367,

    value3: 628.7,

    value4: 40.4,
  },

  {
    date: "2012-07-31",

    value1: 282.0845,

    value2: 324.657,

    value3: 624.5464,

    value4: 42.2,
  },

  {
    date: "2012-08-31",

    value1: 293.6617,

    value2: 335.3159,

    value3: 630.2703,

    value4: 45,
  },

  {
    date: "2012-09-30",

    value1: 300.2358,

    value2: 338.4937,

    value3: 648.6001,

    value4: 48.5,
  },

  {
    date: "2012-10-31",

    value1: 292.8715,

    value2: 328.1453,

    value3: 620.6024,

    value4: 45.5,
  },

  {
    date: "2012-11-30",

    value1: 303.2433,

    value2: 336.9651,

    value3: 626.079,

    value4: 46,
  },

  {
    date: "2012-12-31",

    value1: 310.9452,

    value2: 349.4623,

    value3: 615.7687,

    value4: 47,
  },

  {
    date: "2013-01-31",

    value1: 333.7938,

    value2: 375.9805,

    value3: 649.1951,

    value4: 54.4,
  },

  {
    date: "2013-02-28",

    value1: 334.271,

    value2: 374.6002,

    value3: 659.4591,

    value4: 56.1,
  },

  {
    date: "2013-03-31",

    value1: 350.5127,

    value2: 392.1129,

    value3: 701.9128,

    value4: 61.7,
  },

  {
    date: "2013-04-30",

    value1: 359.4779,

    value2: 406.5811,

    value3: 721.4795,

    value4: 64.7,
  },

  {
    date: "2013-05-31",

    value1: 378.776,

    value2: 426.0844,

    value3: 735.4447,

    value4: 68.1,
  },

  {
    date: "2013-06-30",

    value1: 369.0568,

    value2: 407.8819,

    value3: 724.5003,

    value4: 65.6,
  },

  {
    date: "2013-07-31",

    value1: 392.9318,

    value2: 428.5019,

    value3: 778.0929,

    value4: 73.8,
  },

  {
    date: "2013-08-31",

    value1: 393.474,

    value2: 434.3154,

    value3: 754.6478,

    value4: 68.3,
  },

  {
    date: "2013-09-30",

    value1: 422.9344,

    value2: 465.2523,

    value3: 790.372,

    value4: 73.3,
  },

  {
    date: "2013-10-31",

    value1: 443.4335,

    value2: 479.101,

    value3: 823.2268,

    value4: 81.1,
  },

  {
    date: "2013-11-30",

    value1: 461.6928,

    value2: 496.6477,

    value3: 847.6923,

    value4: 86.1,
  },

  {
    date: "2013-12-31",

    value1: 468.5455,

    value2: 510.5496,

    value3: 869.0154,

    value4: 90.5,
  },

  {
    date: "2014-01-31",

    value1: 444.5529,

    value2: 476.6641,

    value3: 814.6536,

    value4: 83.7,
  },

  {
    date: "2014-02-28",

    value1: 470.3647,

    value2: 503.133,

    value3: 871.5451,

    value4: 91.7,
  },

  {
    date: "2014-03-31",

    value1: 478.9772,

    value2: 513.9894,

    value3: 877.18,

    value4: 93,
  },

  {
    date: "2014-04-30",

    value1: 461.2605,

    value2: 494.7715,

    value3: 846.3988,

    value4: 94.2,
  },

  {
    date: "2014-05-30",

    value1: 457.8929,

    value2: 489.9539,

    value3: 852.8344,

    value4: 98.2,
  },

  {
    date: "2014-06-30",

    value1: 483.1097,

    value2: 524.6432,

    value3: 890.5666,

    value4: 102,
  },

  {
    date: "2014-07-31",

    value1: 469.3483,

    value2: 508.4649,

    value3: 871.5477,

    value4: 99,
  },

  {
    date: "2014-08-29",

    value1: 481.1338,

    value2: 523.9808,

    value3: 897.7795,

    value4: 106.5,
  },

  {
    date: "2014-09-30",

    value1: 460.6779,

    value2: 501.7671,

    value3: 869.0435,

    value4: 103.3,
  },

  {
    date: "2014-10-31",

    value1: 487.0858,

    value2: 526.4997,

    value3: 926.5077,

    value4: 108,
  },

  {
    date: "2014-11-28",

    value1: 492.0173,

    value2: 534.2683,

    value3: 951.6572,

    value4: 113.1,
  },

  {
    date: "2014-12-31",

    value1: 493.675,

    value2: 529.9553,

    value3: 962.3841,

    value4: 112.2,
  },

  {
    date: "2015-01-30",

    value1: 476.874,

    value2: 501.2923,

    value3: 928.1753,

    value4: 105.6,
  },

  {
    date: "2015-02-27",

    value1: 509.5827,

    value2: 538.0914,

    value3: 989.8658,

    value4: 116.9,
  },

  {
    date: "2015-03-31",

    value1: 509.0341,

    value2: 530.8809,

    value3: 1014.3878,

    value4: 113.1,
  },

  {
    date: "2015-04-30",

    value1: 491.2503,

    value2: 509.6833,

    value3: 1011.379,

    value4: 114.9,
  },

  {
    date: "2015-05-29",

    value1: 497.4584,

    value2: 507.0617,

    value3: 983.7056,

    value4: 117.2,
  },

  {
    date: "2015-06-30",

    value1: 508.8699,

    value2: 524.7879,

    value3: 982.7303,

    value4: 112.6,
  },

  {
    date: "2015-07-31",

    value1: 504.0598,

    value2: 527.7244,

    value3: 1014.8874,

    value4: 116.8,
  },

  {
    date: "2015-08-31",

    value1: 489.3208,

    value2: 517.7436,

    value3: 964.383,

    value4: 103.3,
  },

  {
    date: "2015-09-30",

    value1: 478.8898,

    value2: 502.856,

    value3: 951.0782,

    value4: 97.9,
  },

  {
    date: "2015-10-30",

    value1: 521.3224,

    value2: 563.6841,

    value3: 977.8807,

    value4: 114.3,
  },

  {
    date: "2015-11-30",

    value1: 528.5298,

    value2: 567.9317,

    value3: 970.6589,

    value4: 114.4,
  },

  {
    date: "2015-12-31",

    value1: 508.6054,

    value2: 550.5655,

    value3: 965.1985,

    value4: 110.7,
  },

  {
    date: "2016-01-31",

    value1: 480.7312,

    value2: 541.4576,

    value3: 956.6769,

    value4: 100,
  },

  {
    date: "2016-02-29",

    value1: 469.4651,

    value2: 524.908,

    value3: 941.4608,

    value4: 99.2,
  },

  {
    date: "2016-03-31",

    value1: 514.225,

    value2: 574.2132,

    value3: 1010.1972,

    value4: 112.3,
  },

  {
    date: "2016-04-29",

    value1: 518.4017,

    value2: 596.0577,

    value3: 967.6767,

    value4: 112.9,
  },

  {
    date: "2016-05-31",

    value1: 508.2599,

    value2: 566.4753,

    value3: 997.1445,

    value4: 116.1,
  },

  {
    date: "2016-06-30",

    value1: 503.4547,

    value2: 561.8099,

    value3: 996.9251,

    value4: 116.3,
  },

  {
    date: "2016-07-29",

    value1: 518.8428,

    value2: 577.8257,

    value3: 1034.9884,

    value4: 124,
  },

  {
    date: "2016-08-31",

    value1: 534.6233,

    value2: 593.4157,

    value3: 1041.9118,

    value4: 123.8,
  },

  {
    date: "2016-09-30",

    value1: 540.6522,

    value2: 611.1672,

    value3: 1021.8142,

    value4: 123.5,
  },

  {
    date: "2016-10-31",

    value1: 534.3738,

    value2: 603.771,

    value3: 1023.4969,

    value4: 119.2,
  },

  {
    date: "2016-11-30",

    value1: 568.1225,

    value2: 663.4508,

    value3: 1083.1546,

    value4: 126.7,
  },

  {
    date: "2016-12-31",

    value1: 600.4596,

    value2: 708.2654,

    value3: 1110.1305,

    value4: 130.8,
  },

  {
    date: "2017-01-31",

    value1: 631.1398,

    value2: 739.0603,

    value3: 1140.2628,

    value4: 134.9,
  },

  {
    date: "2017-02-28",

    value1: 655.3405,

    value2: 774.7204,

    value3: 1172.5096,

    value4: 143.7,
  },

  {
    date: "2017-03-31",

    value1: 665.3865,

    value2: 767.985,

    value3: 1198.596,

    value4: 143.6,
  },

  {
    date: "2017-04-28",

    value1: 701.4362,

    value2: 798.0173,

    value3: 1205.089,

    value4: 145.8,
  },

  {
    date: "2017-05-31",

    value1: 684.2855,

    value2: 776.3751,

    value3: 1165.2838,

    value4: 148.6,
  },

  {
    date: "2017-06-30",

    value1: 697.7752,

    value2: 802.754,

    value3: 1190.3364,

    value4: 149.8,
  },

  {
    date: "2017-07-31",

    value1: 705.9923,

    value2: 812.3232,

    value3: 1187.8848,

    value4: 154.6,
  },

  {
    date: "2017-08-31",

    value1: 685.3589,

    value2: 786.1395,

    value3: 1125.036,

    value4: 154.8,
  },

  {
    date: "2017-09-29",

    value1: 720.0717,

    value2: 812.1034,

    value3: 1164.2372,

    value4: 159.7,
  },

  {
    date: "2017-10-31",

    value1: 755.9909,

    value2: 820.6771,

    value3: 1183.58,

    value4: 165.5,
  },

  {
    date: "2017-11-30",

    value1: 783.2114,

    value2: 862.8441,

    value3: 1254.3052,

    value4: 172.9,
  },

  {
    date: "2017-12-31",

    value1: 771.6413,

    value2: 856.1042,

    value3: 1286.944,

    value4: 175.6,
  },

  {
    date: "2018-01-31",

    value1: 816.8795,

    value2: 907.7339,

    value3: 1327.4428,

    value4: 191.1,
  },

  {
    date: "2018-02-28",

    value1: 776.5368,

    value2: 852.8124,

    value3: 1250.9318,

    value4: 179.8,
  },

  {
    date: "2018-03-29",

    value1: 778.7281,

    value2: 860.4349,

    value3: 1257.8216,

    value4: 172.2,
  },

  {
    date: "2018-04-30",

    value1: 765.4594,

    value2: 830.2772,

    value3: 1226.4559,

    value4: 173,
  },

  {
    date: "2018-05-31",

    value1: 777.922,

    value2: 851.1154,

    value3: 1221.8133,

    value4: 178.9,
  },

  {
    date: "2018-06-29",

    value1: 750.1796,

    value2: 842.2701,

    value3: 1218.641,

    value4: 180.2,
  },

  {
    date: "2018-07-31",

    value1: 784.3569,

    value2: 871.292,

    value3: 1281.0127,

    value4: 190.3,
  },

  {
    date: "2018-08-31",

    value1: 782.146,

    value2: 902.2762,

    value3: 1313.8808,

    value4: 199.1,
  },

  {
    date: "2018-09-28",

    value1: 775.3534,

    value2: 860.0804,

    value3: 1283.7651,

    value4: 200.4,
  },

  {
    date: "2018-10-31",

    value1: 679.3272,

    value2: 747.3669,

    value3: 1178.3222,

    value4: 179.5,
  },

  {
    date: "2018-11-30",

    value1: 661.9482,

    value2: 752.9595,

    value3: 1214.8822,

    value4: 184.5,
  },

  {
    date: "2018-12-31",

    value1: 591.6965,

    value2: 679.605,

    value3: 1078.1345,

    value4: 158.4,
  },

  {
    date: "2019-01-31",

    value1: 668.4749,

    value2: 763.4126,

    value3: 1191.471,

    value4: 178.7,
  },

  {
    date: "2019-02-28",

    value1: 686.6877,

    value2: 806.8422,

    value3: 1247.5209,

    value4: 187,
  },

  {
    date: "2019-03-29",

    value1: 688.8904,

    value2: 790.6097,

    value3: 1233.7761,

    value4: 192.2,
  },

  {
    date: "2019-04-30",

    value1: 744.4283,

    value2: 817.239,

    value3: 1298.5977,

    value4: 203.6,
  },

  {
    date: "2019-05-31",

    value1: 662.7721,

    value2: 751.4729,

    value3: 1180.2763,

    value4: 183.7,
  },

  {
    date: "2019-06-28",

    value1: 723.8702,

    value2: 828.2758,

    value3: 1264.9026,

    value4: 203.2,
  },

  {
    date: "2019-07-31",

    value1: 720.9042,

    value2: 830.9678,

    value3: 1289.1978,

    value4: 207.2,
  },

  {
    date: "2019-08-30",

    value1: 679.3665,

    value2: 776.0407,

    value3: 1244.7435,

    value4: 201.6,
  },

  {
    date: "2019-09-30",

    value1: 696.1229,

    value2: 800.9203,

    value3: 1266.1249,

    value4: 206.8,
  },

  {
    date: "2019-10-31",

    value1: 735.3717,

    value2: 856.0566,

    value3: 1285.7971,

    value4: 213.1,
  },

  {
    date: "2019-11-29",

    value1: 786.7471,

    value2: 936.2697,

    value3: 1338.1803,

    value4: 223.7,
  },

  {
    date: "2019-12-31",

    value1: 825.3206,

    value2: 997.7205,

    value3: 1373.5595,

    value4: 233,
  },

  {
    date: "2020-01-31",

    value1: 834.2036,

    value2: 1013.6375,

    value3: 1359.4133,

    value4: 232.4,
  },

  {
    date: "2020-02-28",

    value1: 778.8988,

    value2: 965.5283,

    value3: 1260.757,

    value4: 204.3,
  },

  {
    date: "2020-03-31",

    value1: 604.5253,

    value2: 786.7327,

    value3: 1044.8048,

    value4: 165.7,
  },

  {
    date: "2020-04-30",

    value1: 717.5311,

    value2: 942.6203,

    value3: 1228.3171,

    value4: 199.4,
  },

  {
    date: "2020-05-29",

    value1: 827.162,

    value2: 1066.9006,

    value3: 1337.2391,

    value4: 213.9,
  },

  {
    date: "2020-06-30",

    value1: 886.7786,

    value2: 1142.2824,

    value3: 1381.506,

    value4: 218.5,
  },

  {
    date: "2020-07-31",

    value1: 944.1104,

    value2: 1196.3217,

    value3: 1514.5453,

    value4: 237.1,
  },

  {
    date: "2020-08-31",

    value1: 996.838,

    value2: 1277.3418,

    value3: 1590.2674,

    value4: 260.7,
  },

  {
    date: "2020-09-30",

    value1: 961.9585,

    value2: 1223.9009,

    value3: 1533.6435,

    value4: 245.6,
  },

  {
    date: "2020-10-30",

    value1: 942.9495,

    value2: 1210.6619,

    value3: 1545.4057,

    value4: 236.9,
  },

  {
    date: "2020-11-30",

    value1: 1101.9993,

    value2: 1442.2559,

    value3: 1708.7945,

    value4: 273.7,
  },

  {
    date: "2020-12-31",

    value1: 1176.7636,

    value2: 1619.4611,

    value3: 1798.8725,

    value4: 286.1,
  },

  {
    date: "2021-01-29",

    value1: 1232.1752,

    value2: 1654.1942,

    value3: 1839.6982,

    value4: 282.1,
  },

  {
    date: "2021-02-26",

    value1: 1280.2667,

    value2: 1732.4312,

    value3: 1879.462,

    value4: 292.6,
  },

  {
    date: "2021-03-31",

    value1: 1359.77,

    value2: 1856.1203,

    value3: 2036.2354,

    value4: 310.5,
  },

  {
    date: "2021-04-30",

    value1: 1418.8907,

    value2: 1914.9996,

    value3: 2160.778,

    value4: 332.3,
  },

  {
    date: "2021-05-28",

    value1: 1438.7882,

    value2: 1970.2106,

    value3: 2261.1565,

    value4: 335.1,
  },

  {
    date: "2021-06-30",

    value1: 1426.1701,

    value2: 1998.9865,

    value3: 2271.5456,

    value4: 344.9,
  },

  {
    date: "2021-07-30",

    value1: 1450.7415,

    value2: 2080.6371,

    value3: 2324.1939,

    value4: 355.8,
  },

  {
    date: "2021-08-31",

    value1: 1435.2341,

    value2: 2007.8038,

    value3: 2379.7079,

    value4: 369.6,
  },

  {
    date: "2021-09-30",

    value1: 1336.365,

    value2: 1895.8794,

    value3: 2227.2059,

    value4: 345.9,
  },

  {
    date: "2021-10-29",

    value1: 1364.0868,

    value2: 1945.3772,

    value3: 2266.7684,

    value4: 377.1,
  },

  {
    date: "2021-11-30",

    value1: 1353.9846,

    value2: 1897.9245,

    value3: 2251.1477,

    value4: 373.6,
  },

  {
    date: "2021-12-31",

    value1: 1401.8207,

    value2: 1949.8705,

    value3: 2342.6074,

    value4: 393.6,
  },

  {
    date: "2022-01-31",

    value1: 1260.199,

    value2: 1765.1772,

    value3: 2129.3678,

    value4: 367.5,
  },

  {
    date: "2022-02-28",

    value1: 1221.8414,

    value2: 1756.0378,

    value3: 2074.3024,

    value4: 354,
  },

  {
    date: "2022-03-31",

    value1: 1201.0885,

    value2: 1722.8147,

    value3: 2101.2637,

    value4: 369.5,
  },

  {
    date: "2022-04-29",

    value1: 1116.5178,

    value2: 1622.7422,

    value3: 1917.6783,

    value4: 327.9,
  },

  {
    date: "2022-05-31",

    value1: 1152.77,

    value2: 1715.598,

    value3: 1915.0553,

    value4: 329.3,
  },

  {
    date: "2022-06-30",

    value1: 1060.1903,

    value2: 1579.2466,

    value3: 1767.7548,

    value4: 292.3,
  },

  {
    date: "2022-07-29",

    value1: 1186.419,

    value2: 1763.6279,

    value3: 2001.4109,

    value4: 328.1,
  },

  {
    date: "2022-08-31",

    value1: 1129.0447,

    value2: 1679.5782,

    value3: 1950.9771,

    value4: 310.4,
  },

  {
    date: "2022-09-30",

    value1: 990.7772,

    value2: 1482.7569,

    value3: 1767.0044,

    value4: 270.6,
  },

  {
    date: "2022-10-31",

    value1: 1060.696,

    value2: 1580.2547,

    value3: 1923.8328,

    value4: 300.7,
  },

  {
    date: "2022-11-30",

    value1: 1177.5781,

    value2: 1764.4106,

    value3: 2065.7035,

    value4: 300.5,
  },

  {
    date: "2022-12-30",

    value1: 1103.0953,

    value2: 1671.0037,

    value3: 1914.9705,

    value4: 276,
  },

  {
    date: "2023-01-31",

    value1: 1236.5185,

    value2: 1878.9195,

    value3: 2072.5412,

    value4: 299.5,
  },

  {
    date: "2023-02-28",

    value1: 1244.2703,

    value2: 1885.6478,

    value3: 2072.7585,

    value4: 289.4,
  },

  {
    date: "2023-03-31",

    value1: 1228.5424,

    value2: 1859.2387,

    value3: 2121.211,

    value4: 302.2,
  },

  {
    date: "2023-04-28",

    value1: 1217.7812,

    value2: 1855.3202,

    value3: 2121.4331,

    value4: 308.7,
  },

  {
    date: "2023-05-31",

    value1: 1184.4413,

    value2: 1785.1242,

    value3: 2043.2386,

    value4: 312.9,
  },

  {
    date: "2023-06-30",

    value1: 1357.584,

    value2: 2039.239,

    value3: 2207.8394,

    value4: 338.8,
  },

  {
    date: "2023-07-31",

    value1: 1409.3282,

    value2: 2095.501,

    value3: 2310.769,

    value4: 352.4,
  },

  {
    date: "2023-08-31",

    value1: 1364.5012,

    value2: 2027.0013,

    value3: 2323.7872,

    value4: 345,
  },

  {
    date: "2023-09-29",

    value1: 1280.4388,

    value2: 1912.1433,

    value3: 2245.2565,

    value4: 322.2,
  },

  {
    date: "2023-10-31",

    value1: 1165.8624,

    value2: 1740.105,

    value3: 2128.9317,

    value4: 312.8,
  },

  {
    date: "2023-11-30",

    value1: 1280.1698,

    value2: 1904.2424,

    value3: 2286.5172,

    value4: 350.7,
  },

  {
    date: "2023-12-29",

    value1: 1451.3108, // buffettology_eps_growth

    value2: 2184.8363, // buffettology_sustainable_growth

    value3: 2481.4957, // buffettology_hagstrom

    value4: 369.5,
  },
];
