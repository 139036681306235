import React from "react";

const KirkPatrickValueScreen = () => {
  return (
    <div className="screener-article">
      <h3>Discover the Value Screen by Kirkpatrick </h3>
      <p>
        Despite the success of his growth model, Kirkpatrick was concerned that its performance had occurred during one of the strongest bull markets
        in history. To protect against the inevitable market reversal, he wanted to strengthen the system against capital losses. His belief was that
        relative price strength would not be effective in a market downturn and could be the source of significant capital losses. Kirkpatrick's
        alternative was to reduce portfolio risk by starting with a group of low-valued stocks. His reasoning was that since the low valuations were
        likely due to price declines, the downside risk had been reduced. Kirkpatrick arbitrarily selected only stocks in the 30th percentile or lower
        using relative price-to-sales percentiles.
      </p>

      <p>He tested his value model from 1998 to 2007. It outperformed the growth model and the S&P 500 Index.</p>

      <p>
        Kirkpatrick's Value List mimics the Growth List. However, instead of using a chart pattern stop, it uses the relative price-to-sales ratio to
        reduce risk.{" "}
      </p>

      <h4 className="text-primary font-semibold">Rule #1 Price Strength</h4>
      <p>
        In Beat the Market, Kirkpatrick calculates relative strength by dividing the current weekly closing price by the 26-week moving average of
        closing prices. We only keep the company in the top 10%.
      </p>
      <p className="font-semibold ml-10">{"Top 10% of Relative Price Strength"}</p>
      <h4 className="text-primary font-semibold">Rule #2 Reported Earnings Growth</h4>
      <p>
        Kirkpatrick uses a non-standard calculation for earnings growth, which compares operating income over the last four fiscal quarters to the
        four-quarter total of operating income one quarter earlier. His goal is to eliminate the effect of seasonality on a company's earnings.
      </p>

      <p className="font-semibold ml-10">{"Growth in Operating Income"}</p>
      <h4 className="text-primary font-semibold">Rule #3 Price and Market Cap</h4>
      <p>
        For the Value List, Kirkpatrick relaxed his market cap requirements to include companies with a market capitalization of $500 million or more.
        However, he maintained the minimum share price of $10.
      </p>
      <p className="font-semibold ml-10">{"Share Price > $10"}</p>
      <p className="font-semibold ml-10">{"Market Cap > $500 million"}</p>
      <h4 className="text-primary font-semibold">Rule #4 Relative Valuation</h4>
      <p> For his Value List, Kirkpatrick selects stocks with relative price-to-sales ratios in the 30th percentile or lower.</p>
      <p className="font-semibold ml-10">Top 30% for Relative Price/Sales</p>
      <h4 className="text-primary font-semibold">Rule #5 Sell Criteria</h4>
      <p>After buying a stock for his value list, Kirkpatrick used these rules to determine when to remove a stock from the portfolio:</p>
      <ul className="list-disc">
        <li>Relative price strength percentile of 30 or less,</li>
        <li>Relative reported earnings growth percentile below 50, and</li>
        <li>Stocks NOT removed for extraordinarily high relative price-to-sales ratios.</li>
      </ul>
    </div>
  );
};

export default KirkPatrickValueScreen;
