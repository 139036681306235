import { useQuery } from "@tanstack/react-query";
import { getFavorites } from "../functionsBackEnd/functions";

export const useFavorites = (user) => {
  return useQuery({
    queryKey: ["favorites"],
    queryFn: () => getFavorites(user),
    enabled: Boolean(user),
    staleTime: 60000,
    refetchOnWindowFocus: true,
    // staleTime: Infinity,
  });
};


