import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { menuDocumentation } from "./data/navigationDocumentation";
import { MathJaxContext } from "better-react-mathjax";
import fondEcranDark from "../../assets/image/fond-ecran-dark.webp";
import fondEcranLight from "../../assets/image/fond-ecran-light.webp";
const DocsPage = () => {
  const params = useParams();
  const [pageContent, setPageContent] = useState("");
  const [infoPage, setInfoPage] = useState({ menu: "", submenu: "" });

  useEffect(() => {
    menuDocumentation.forEach((menu) => {
      menu.content.forEach((submenu) => {
        if (submenu.url === params.document) {
          setInfoPage({ menu: menu.name, submenu: submenu.name });
          setPageContent(submenu.component);
        }
      });
    });
  }, [params]);

  return (
    <>
      <MathJaxContext>
        <div className="text-sm breadcrumbs">
          <ul>
            <li>Docs</li>
            <li>{infoPage.menu}</li>
            <li>{infoPage.submenu}</li>
          </ul>
        </div>

        <img
          src={fondEcranDark}
          alt=""
          className="hidden dark:block fixed h-[500px] w-full sm:h-[750px] sm:w-full sm:inset-x-0 -top-20 sm:-top-40 -z-10 transform-gpu overflow-hidden"
        />
        <img
          src={fondEcranLight}
          alt=""
          className="dark:hidden block fixed h-[500px] w-full sm:h-[500px] sm:w-full sm:inset-x-0 -top-20 sm:-top-40 -z-10 transform-gpu overflow-hidden "
        />
        <div>{pageContent}</div>
      </MathJaxContext>
    </>
  );
};

export default DocsPage;
