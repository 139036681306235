import React from "react";
import { MathJax } from "better-react-mathjax";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const GrossMargin = () => {
  return (
    <>
      <Helmet>
        <title>{`Gross Profit Margin Definition & Interpretation `}</title>‍
        <meta
          name="description"
          content={`What is Gross Profit Margin, how to calculate and interpret it ? Get the definition and the average in all Industry`}
        />
      </Helmet>
      <div className="screener-article">
        <h1>Gross Profit Margin</h1>
        <p>
          The Gross Profit Margin is a measure of how much income a company has left after paying all direct production expenses. It is calculated as
          Gross Profit divided by Revenue. According the source, the Gross Profit margin could also be called GP, Gross Margin or Gross Profit Rate.
        </p>
        <h2>Formula of the Gross Profit Margin</h2>
        <div className="overflow-x-auto w-full">
          <p className="text-2xl mb-10 mt-10 text-center ">
            <MathJax>{"\\( \\text{Gross Margin}=\\frac{\\text{Gross Profit}}{\\text{Total Revenue}} \\)"}</MathJax>
          </p>
          <p className="text-2xl  mb-10 text-center ">
            <MathJax>{"\\( \\text{Gross Margin}=\\frac{\\text{Total Revenue}-\\text{Cost of Revenue}}{\\text{Total Revenue}} \\)"}</MathJax>
          </p>
        </div>
        <h2>Interpretation of the Gross Profit Margin</h2>
        <p className="mb-5">
          Gross Margin is calculated as annual Total Revenue minus annual Cost of Goods Sold (COGS) divided by annual Total Revenue and multiplied by
          100. The gross margin doesn't include operating expenses like salaries, advertising costs or taxes. This is the most fundamental level.
        </p>
        <p className="mb-5">
          It is a good indication of how profitable a company is at the most fundamental level. Companies with higher gross margins will have more
          money left over to spend on other business operations, such as research and development or marketing. A higher profit margin is always
          desirable since it means the company generates more profits from its sales, but the Gross Margin could never exceed 100%. Nevertheless, a
          negative gross margin is possible when the cost of production exceed total sales. It can be an indication of a company's inability to
          control costs
        </p>
        <ul className="ml-5 mt-5 mb-5">
          <li className="font-semibold mb-1">- Gross Margin lower than 0% : Inability to control costs</li>
          <li className="font-semibold mb-1">- Gross Margin lower than industry's average : Less profitable than competitors</li>
          <li className="font-semibold mb-1">- Gross Margin greater than industry's average : More profitable than competitors</li>
          <li className="font-semibold mb-1">- Gross Margin greater than 100% : Impossible</li>
        </ul>

        <p>
          As shown in the table below, it is important to compare the gross profit margin withing the same industry. The formula of Gross Margin is
          directly linked to the COGS, the COGS depends on the products delivered by the company. The industries with the highest gross profit marge
          are in the Finance Sector, Intenet & Software Sector. At the inverse the lowest gross profit margin could be find for Farming, Construction
          or Automobile Manufacturers. But looking at the Gross Profit Margin isn't enough, you should also look the{" "}
          <Link className="link-content" to={`${process.env.PUBLIC_URL}/net-margin`}>
            Net Profit Margin{" "}
          </Link>{" "}
          and{" "}
          <Link className="link-content" to={`${process.env.PUBLIC_URL}/operating-margin`}>
            Operating Profit Margin{" "}
          </Link>{" "}
          to understand the financial flux of an asset.
        </p>

        <h2>Average Gross Profit Margin by Industry in the United States</h2>
        <div className="overflow-x-auto w-full">
          <table className="table table-striped mt-3 mb-3 ">
            <tbody>
              <tr>
                <th>Industry</th>
                <th>Average Gross Profit Margin</th>
                <th>Average Net Profit Margin</th>
                <th>Number of companies</th>
              </tr>
              <tr>
                <td>Advertising Agencies</td>
                <td>45.9%</td>
                <td>-9.7%</td>
                <td>22</td>
              </tr>
              <tr>
                <td>Aerospace &amp; Defense</td>
                <td>27.6%</td>
                <td>5.1%</td>
                <td>47</td>
              </tr>
              <tr>
                <td>Agricultural Inputs</td>
                <td>31.8%</td>
                <td>6.9%</td>
                <td>11</td>
              </tr>
              <tr>
                <td>Airlines</td>
                <td>48.1%</td>
                <td>1.2%</td>
                <td>13</td>
              </tr>
              <tr>
                <td>Apparel Manufacturing</td>
                <td>45.4%</td>
                <td>3.5%</td>
                <td>17</td>
              </tr>
              <tr>
                <td>Apparel Retail</td>
                <td>39.9%</td>
                <td>3.7%</td>
                <td>29</td>
              </tr>
              <tr>
                <td>Asset Management</td>
                <td>82.6%</td>
                <td>23.5%</td>
                <td>74</td>
              </tr>
              <tr>
                <td>Auto Manufacturers</td>
                <td>11.5%</td>
                <td>5.7%</td>
                <td>17</td>
              </tr>
              <tr>
                <td>Auto Parts</td>
                <td>21.8%</td>
                <td>1.7%</td>
                <td>45</td>
              </tr>
              <tr>
                <td>Auto &amp; Truck Dealerships</td>
                <td>27.3%</td>
                <td>5.2%</td>
                <td>14</td>
              </tr>
              <tr>
                <td>Banks - Diversified</td>
                <td>88.7%</td>
                <td>26.3%</td>
                <td>6</td>
              </tr>
              <tr>
                <td>Banks - Regional</td>
                <td>99.8%</td>
                <td>27.7%</td>
                <td>278</td>
              </tr>
              <tr>
                <td>Beverages - Non-Alcoholic</td>
                <td>42.5%</td>
                <td>11.3%</td>
                <td>10</td>
              </tr>
              <tr>
                <td>Beverages - Wineries &amp; Distilleries</td>
                <td>40.2%</td>
                <td>8.6%</td>
                <td>9</td>
              </tr>
              <tr>
                <td>Biotechnology</td>
                <td>86.2%</td>
                <td>-266.5%</td>
                <td>517</td>
              </tr>
              <tr>
                <td>Broadcasting</td>
                <td>40.1%</td>
                <td>-2.9%</td>
                <td>17</td>
              </tr>
              <tr>
                <td>Building Materials</td>
                <td>28.7%</td>
                <td>15%</td>
                <td>7</td>
              </tr>
              <tr>
                <td>Building Products &amp; Equipment</td>
                <td>30.4%</td>
                <td>8.9%</td>
                <td>31</td>
              </tr>
              <tr>
                <td>Business Equipment &amp; Supplies</td>
                <td>32.1%</td>
                <td>2.9%</td>
                <td>7</td>
              </tr>
              <tr>
                <td>Capital Markets</td>
                <td>84.5%</td>
                <td>12%</td>
                <td>32</td>
              </tr>
              <tr>
                <td>Chemicals</td>
                <td>18.7%</td>
                <td>5.5%</td>
                <td>17</td>
              </tr>
              <tr>
                <td>Coking Coal</td>
                <td>35.7%</td>
                <td>N/A</td>
                <td>4</td>
              </tr>
              <tr>
                <td>Communication Equipment</td>
                <td>40.1%</td>
                <td>-6.2%</td>
                <td>53</td>
              </tr>
              <tr>
                <td>Computer Hardware</td>
                <td>35.6%</td>
                <td>-2.4%</td>
                <td>28</td>
              </tr>
              <tr>
                <td>Conglomerates</td>
                <td>29%</td>
                <td>1.8%</td>
                <td>11</td>
              </tr>
              <tr>
                <td>Consulting Services</td>
                <td>41.6%</td>
                <td>5.4%</td>
                <td>16</td>
              </tr>
              <tr>
                <td>Consumer Electronics</td>
                <td>29.2%</td>
                <td>-12%</td>
                <td>12</td>
              </tr>
              <tr>
                <td>Credit Services</td>
                <td>84%</td>
                <td>22.4%</td>
                <td>44</td>
              </tr>
              <tr>
                <td>Department Stores</td>
                <td>33.4%</td>
                <td>2.9%</td>
                <td>5</td>
              </tr>
              <tr>
                <td>Diagnostics &amp; Research</td>
                <td>46.1%</td>
                <td>-117.6%</td>
                <td>68</td>
              </tr>
              <tr>
                <td>Discount Stores</td>
                <td>26%</td>
                <td>2.3%</td>
                <td>9</td>
              </tr>
              <tr>
                <td>Drug Manufacturers - General</td>
                <td>71.7%</td>
                <td>17.3%</td>
                <td>12</td>
              </tr>
              <tr>
                <td>Drug Manufacturers - Specialty &amp; Generic</td>
                <td>48.5%</td>
                <td>-104.6%</td>
                <td>52</td>
              </tr>
              <tr>
                <td>Education &amp; Training Services</td>
                <td>52.1%</td>
                <td>5.2%</td>
                <td>16</td>
              </tr>
              <tr>
                <td>Electrical Equipment &amp; Parts</td>
                <td>27.9%</td>
                <td>6%</td>
                <td>41</td>
              </tr>
              <tr>
                <td>Electronic Components</td>
                <td>31.3%</td>
                <td>3.4%</td>
                <td>29</td>
              </tr>
              <tr>
                <td>Electronic Gaming &amp; Multimedia</td>
                <td>62.8%</td>
                <td>-10.3%</td>
                <td>7</td>
              </tr>
              <tr>
                <td>Electronics &amp; Computer Distribution</td>
                <td>18.9%</td>
                <td>2.1%</td>
                <td>6</td>
              </tr>
              <tr>
                <td>Engineering &amp; Construction</td>
                <td>16.7%</td>
                <td>2.8%</td>
                <td>30</td>
              </tr>
              <tr>
                <td>Entertainment</td>
                <td>46.2%</td>
                <td>-6.3%</td>
                <td>36</td>
              </tr>
              <tr>
                <td>Farm &amp; Heavy Construction Machinery</td>
                <td>21.8%</td>
                <td>6.9%</td>
                <td>21</td>
              </tr>
              <tr>
                <td>Farm Products</td>
                <td>13.4%</td>
                <td>5.3%</td>
                <td>18</td>
              </tr>
              <tr>
                <td>Financial Data &amp; Stock Exchanges</td>
                <td>70.5%</td>
                <td>27.7%</td>
                <td>10</td>
              </tr>
              <tr>
                <td>Food Distribution</td>
                <td>14.1%</td>
                <td>0.6%</td>
                <td>9</td>
              </tr>
              <tr>
                <td>Footwear &amp; Accessories</td>
                <td>44.5%</td>
                <td>5.5%</td>
                <td>11</td>
              </tr>
              <tr>
                <td>Furnishings, Fixtures &amp; Appliances</td>
                <td>35.4%</td>
                <td>3%</td>
                <td>19</td>
              </tr>
              <tr>
                <td>Gambling</td>
                <td>55.5%</td>
                <td>4.9%</td>
                <td>12</td>
              </tr>
              <tr>
                <td>Gold</td>
                <td>27.4%</td>
                <td>3.6%</td>
                <td>26</td>
              </tr>
              <tr>
                <td>Grocery Stores</td>
                <td>27.2%</td>
                <td>2%</td>
                <td>10</td>
              </tr>
              <tr>
                <td>Healthcare Plans</td>
                <td>18.8%</td>
                <td>-13.5%</td>
                <td>12</td>
              </tr>
              <tr>
                <td>Health Information Services</td>
                <td>48.5%</td>
                <td>-47.2%</td>
                <td>33</td>
              </tr>
              <tr>
                <td>Home Improvement Retail</td>
                <td>41.9%</td>
                <td>1.4%</td>
                <td>7</td>
              </tr>
              <tr>
                <td>Household &amp; Personal Products</td>
                <td>54.8%</td>
                <td>5%</td>
                <td>24</td>
              </tr>
              <tr>
                <td>Industrial Distribution</td>
                <td>29.4%</td>
                <td>5.4%</td>
                <td>16</td>
              </tr>
              <tr>
                <td>Information Technology Services</td>
                <td>37.5%</td>
                <td>1%</td>
                <td>53</td>
              </tr>
              <tr>
                <td>Insurance Brokers</td>
                <td>89.2%</td>
                <td>6.6%</td>
                <td>12</td>
              </tr>
              <tr>
                <td>Insurance - Diversified</td>
                <td>62.4%</td>
                <td>10.7%</td>
                <td>11</td>
              </tr>
              <tr>
                <td>Insurance - Life</td>
                <td>59.9%</td>
                <td>10.9%</td>
                <td>16</td>
              </tr>
              <tr>
                <td>Insurance - Property &amp; Casualty</td>
                <td>52.1%</td>
                <td>7.1%</td>
                <td>36</td>
              </tr>
              <tr>
                <td>Insurance - Specialty</td>
                <td>83.5%</td>
                <td>18.5%</td>
                <td>15</td>
              </tr>
              <tr>
                <td>Integrated Freight &amp; Logistics</td>
                <td>36.6%</td>
                <td>4.5%</td>
                <td>15</td>
              </tr>
              <tr>
                <td>Internet Content &amp; Information</td>
                <td>60.9%</td>
                <td>-5.3%</td>
                <td>37</td>
              </tr>
              <tr>
                <td>Internet Retail</td>
                <td>45.4%</td>
                <td>0.7%</td>
                <td>22</td>
              </tr>
              <tr>
                <td>Leisure</td>
                <td>40.2%</td>
                <td>-0.2%</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Lodging</td>
                <td>33%</td>
                <td>8%</td>
                <td>9</td>
              </tr>
              <tr>
                <td>Luxury Goods</td>
                <td>45.2%</td>
                <td>4.8%</td>
                <td>5</td>
              </tr>
              <tr>
                <td>Marine Shipping</td>
                <td>47.6%</td>
                <td>6.2%</td>
                <td>23</td>
              </tr>
              <tr>
                <td>Medical Care Facilities</td>
                <td>34%</td>
                <td>-29.2%</td>
                <td>39</td>
              </tr>
              <tr>
                <td>Medical Devices</td>
                <td>61.3%</td>
                <td>-92.3%</td>
                <td>103</td>
              </tr>
              <tr>
                <td>Medical Distribution</td>
                <td>19.6%</td>
                <td>0%</td>
                <td>7</td>
              </tr>
              <tr>
                <td>Medical Instruments &amp; Supplies</td>
                <td>52.5%</td>
                <td>-72.1%</td>
                <td>44</td>
              </tr>
              <tr>
                <td>Metal Fabrication</td>
                <td>23.7%</td>
                <td>5.3%</td>
                <td>12</td>
              </tr>
              <tr>
                <td>Mortgage Finance</td>
                <td>92.1%</td>
                <td>17.3%</td>
                <td>17</td>
              </tr>
              <tr>
                <td>Oil &amp; Gas Drilling</td>
                <td>37.7%</td>
                <td>15.1%</td>
                <td>6</td>
              </tr>
              <tr>
                <td>Oil &amp; Gas E&amp;P</td>
                <td>66%</td>
                <td>24.2%</td>
                <td>63</td>
              </tr>
              <tr>
                <td>Oil &amp; Gas Equipment &amp; Services</td>
                <td>26.4%</td>
                <td>3.3%</td>
                <td>43</td>
              </tr>
              <tr>
                <td>Oil &amp; Gas Integrated</td>
                <td>30.5%</td>
                <td>10.9%</td>
                <td>7</td>
              </tr>
              <tr>
                <td>Oil &amp; Gas Midstream</td>
                <td>49.4%</td>
                <td>19.9%</td>
                <td>36</td>
              </tr>
              <tr>
                <td>Oil &amp; Gas Refining &amp; Marketing</td>
                <td>11.3%</td>
                <td>0.7%</td>
                <td>18</td>
              </tr>
              <tr>
                <td>Other Industrial Metals &amp; Mining</td>
                <td>23.4%</td>
                <td>0.5%</td>
                <td>13</td>
              </tr>
              <tr>
                <td>Other Precious Metals &amp; Mining</td>
                <td>29.4%</td>
                <td>N/A</td>
                <td>11</td>
              </tr>
              <tr>
                <td>Packaged Foods</td>
                <td>26.6%</td>
                <td>4.4%</td>
                <td>41</td>
              </tr>
              <tr>
                <td>Packaging &amp; Containers</td>
                <td>24.9%</td>
                <td>3.2%</td>
                <td>22</td>
              </tr>
              <tr>
                <td>Paper &amp; Paper Products</td>
                <td>14%</td>
                <td>-4%</td>
                <td>5</td>
              </tr>
              <tr>
                <td>Personal Services</td>
                <td>41.7%</td>
                <td>6.4%</td>
                <td>11</td>
              </tr>
              <tr>
                <td>Pharmaceutical Retailers</td>
                <td>40.2%</td>
                <td>-24.2%</td>
                <td>8</td>
              </tr>
              <tr>
                <td>Pollution &amp; Treatment Controls</td>
                <td>32.4%</td>
                <td>5.2%</td>
                <td>7</td>
              </tr>
              <tr>
                <td>Publishing</td>
                <td>59.1%</td>
                <td>5.4%</td>
                <td>7</td>
              </tr>
              <tr>
                <td>Railroads</td>
                <td>34.7%</td>
                <td>8.4%</td>
                <td>8</td>
              </tr>
              <tr>
                <td>Real Estate - Development</td>
                <td>39.2%</td>
                <td>11%</td>
                <td>10</td>
              </tr>
              <tr>
                <td>Real Estate - Diversified</td>
                <td>24.2%</td>
                <td>N/A</td>
                <td>4</td>
              </tr>
              <tr>
                <td>Real Estate Services</td>
                <td>38.4%</td>
                <td>1.2%</td>
                <td>24</td>
              </tr>
              <tr>
                <td>Recreational Vehicles</td>
                <td>21.9%</td>
                <td>6.1%</td>
                <td>15</td>
              </tr>
              <tr>
                <td>REIT - Diversified</td>
                <td>68.6%</td>
                <td>16.5%</td>
                <td>18</td>
              </tr>
              <tr>
                <td>REIT - Healthcare Facilities</td>
                <td>71%</td>
                <td>8.6%</td>
                <td>16</td>
              </tr>
              <tr>
                <td>REIT - Hotel &amp; Motel</td>
                <td>43%</td>
                <td>5.4%</td>
                <td>15</td>
              </tr>
              <tr>
                <td>REIT - Industrial</td>
                <td>72.7%</td>
                <td>25.7%</td>
                <td>16</td>
              </tr>
              <tr>
                <td>REIT - Mortgage</td>
                <td>92.2%</td>
                <td>24.8%</td>
                <td>35</td>
              </tr>
              <tr>
                <td>REIT - Office</td>
                <td>62.7%</td>
                <td>-4.6%</td>
                <td>24</td>
              </tr>
              <tr>
                <td>REIT - Residential</td>
                <td>58.3%</td>
                <td>15.5%</td>
                <td>18</td>
              </tr>
              <tr>
                <td>REIT - Retail</td>
                <td>72.1%</td>
                <td>21.9%</td>
                <td>21</td>
              </tr>
              <tr>
                <td>REIT - Specialty</td>
                <td>55.7%</td>
                <td>9.4%</td>
                <td>15</td>
              </tr>
              <tr>
                <td>Rental &amp; Leasing Services</td>
                <td>44.5%</td>
                <td>11.8%</td>
                <td>20</td>
              </tr>
              <tr>
                <td>Residential Construction</td>
                <td>24.4%</td>
                <td>11%</td>
                <td>21</td>
              </tr>
              <tr>
                <td>Resorts &amp; Casinos</td>
                <td>50.3%</td>
                <td>4.7%</td>
                <td>19</td>
              </tr>
              <tr>
                <td>Restaurants</td>
                <td>39.5%</td>
                <td>6%</td>
                <td>41</td>
              </tr>
              <tr>
                <td>Scientific &amp; Technical Instruments</td>
                <td>43.1%</td>
                <td>1.2%</td>
                <td>24</td>
              </tr>
              <tr>
                <td>Security &amp; Protection Services</td>
                <td>38%</td>
                <td>4.9%</td>
                <td>14</td>
              </tr>
              <tr>
                <td>Semiconductor Equipment &amp; Materials</td>
                <td>47.6%</td>
                <td>9.2%</td>
                <td>26</td>
              </tr>
              <tr>
                <td>Semiconductors</td>
                <td>43.5%</td>
                <td>-4.3%</td>
                <td>64</td>
              </tr>
              <tr>
                <td>Software - Application</td>
                <td>62.5%</td>
                <td>-10%</td>
                <td>190</td>
              </tr>
              <tr>
                <td>Software - Infrastructure</td>
                <td>63.1%</td>
                <td>-5%</td>
                <td>87</td>
              </tr>
              <tr>
                <td>Solar</td>
                <td>24.8%</td>
                <td>-15.4%</td>
                <td>13</td>
              </tr>
              <tr>
                <td>Specialty Business Services</td>
                <td>29%</td>
                <td>4.1%</td>
                <td>26</td>
              </tr>
              <tr>
                <td>Specialty Chemicals</td>
                <td>26.6%</td>
                <td>5.9%</td>
                <td>46</td>
              </tr>
              <tr>
                <td>Specialty Industrial Machinery</td>
                <td>34.3%</td>
                <td>9%</td>
                <td>73</td>
              </tr>
              <tr>
                <td>Specialty Retail</td>
                <td>37.5%</td>
                <td>1%</td>
                <td>42</td>
              </tr>
              <tr>
                <td>Staffing &amp; Employment Services</td>
                <td>34.2%</td>
                <td>4.1%</td>
                <td>23</td>
              </tr>
              <tr>
                <td>Steel</td>
                <td>16.8%</td>
                <td>4.1%</td>
                <td>15</td>
              </tr>
              <tr>
                <td>Telecom Services</td>
                <td>58.5%</td>
                <td>3.3%</td>
                <td>33</td>
              </tr>
              <tr>
                <td>Textile Manufacturing</td>
                <td>18%</td>
                <td>-4.3%</td>
                <td>4</td>
              </tr>
              <tr>
                <td>Thermal Coal</td>
                <td>38%</td>
                <td>28%</td>
                <td>9</td>
              </tr>
              <tr>
                <td>Tobacco</td>
                <td>39%</td>
                <td>8.4%</td>
                <td>6</td>
              </tr>
              <tr>
                <td>Tools &amp; Accessories</td>
                <td>34%</td>
                <td>7%</td>
                <td>11</td>
              </tr>
              <tr>
                <td>Travel Services</td>
                <td>53.9%</td>
                <td>3.6%</td>
                <td>14</td>
              </tr>
              <tr>
                <td>Trucking</td>
                <td>49.9%</td>
                <td>6.5%</td>
                <td>12</td>
              </tr>
              <tr>
                <td>Uranium</td>
                <td>21.4%</td>
                <td>N/A</td>
                <td>4</td>
              </tr>
              <tr>
                <td>Utilities - Diversified</td>
                <td>37.6%</td>
                <td>12.5%</td>
                <td>15</td>
              </tr>
              <tr>
                <td>Utilities - Regulated Electric</td>
                <td>36.1%</td>
                <td>10.5%</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Utilities - Regulated Gas</td>
                <td>39.7%</td>
                <td>10.7%</td>
                <td>13</td>
              </tr>
              <tr>
                <td>Utilities - Regulated Water</td>
                <td>50.5%</td>
                <td>16.4%</td>
                <td>12</td>
              </tr>
              <tr>
                <td>Utilities - Renewable</td>
                <td>35%</td>
                <td>6%</td>
                <td>12</td>
              </tr>
              <tr>
                <td>Waste Management</td>
                <td>29%</td>
                <td>2.7%</td>
                <td>11</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default GrossMargin;
