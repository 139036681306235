import React from "react";
import { MathJax } from "better-react-mathjax";
const AltmanZScore = () => {
  return (
    <div className="screener-article">
      <h1>Altman Z-Score</h1>
      <h2>Brief Introduction to Altman Z-Score</h2>
      <p>The Altman Z-score is a combination of five weighted company ratios used to estimate the likelihood of financial distress.</p>
      <p>
        The Altman Z-score, a variation of the traditional z-score in statistics, is based on five financial ratios that can be calculated from data
        found on a company's annual 10-K report. It uses profitability, leverage, liquidity, solvency, and activity to predict whether a company has a
        high probability of becoming insolvent.
      </p>
      <p>
        NYU Stern Finance Professor Edward Altman developed the Altman Z-score formula in 1967, and it was published in 1968. Over the years, Altman
        has continued to reevaluate his Z-score. From 1969 until 1975, Altman looked at 86 companies in distress, then 110 from 1976 to 1995, and
        finally 120 from 1996 to 1999, finding that the Z-score had an accuracy of between 82% and 94%.
      </p>
      <p>
        In 2012, he released an updated version called the Altman Z-score Plus that one can use to evaluate public and private companies,
        manufacturing and non-manufacturing companies, and U.S. and non-U.S. companies. One can use Altman Z-score Plus to evaluate corporate credit
        risk. The Altman Z-score has become a reliable measure of calculating credit risk.
      </p>
      <h2>Formula of the Altman Z-Score</h2>
      <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
        <MathJax>{"\\( \\text{Z-Score}=1.2A + 1.4B + 3.3C + 0.6D + 1.0E   \\)"}</MathJax>
      </p>
      <p>With:</p>
      <p className="text-xl mb-10 ">
        <MathJax>{"\\( \\text{A}=\\frac{\\text{Working Capital}}{\\text{Total Assets}}    \\)"}</MathJax>
      </p>{" "}
      <p className="text-xl mb-10">
        <MathJax>{"\\( \\text{B}=\\frac{\\text{Retained Earning}}{\\text{Total Assets}}    \\)"}</MathJax>
      </p>{" "}
      <p className="text-xl mb-10">
        <MathJax>{"\\( \\text{C}=\\frac{\\text{EBIT}}{\\text{Total Assets}}    \\)"}</MathJax>
      </p>{" "}
      <p className="text-xl mb-10">
        <MathJax>{"\\( \\text{D}=\\frac{\\text{Market Value of Equity}}{\\text{Total Assets}}    \\)"}</MathJax>
      </p>{" "}
      <p className="text-xl mb-10">
        <MathJax>{"\\( \\text{E}=\\frac{\\text{Total Revenue}}{\\text{Total Assets}}    \\)"}</MathJax>
      </p>
      <p>Let's deep-dived into each parameter to understand how to calculate the Altman Z-Score from the financial statement of a company.</p>
      <h3>Working Capital:</h3>
      <p>By definition, the working capital is difference between the total current assets and the total current liabilities</p>
      <p className="text-2xl mb-10 mt-10  pb-3 overflow-x-auto w-full ">
        <MathJax>{"\\( \\text{A}=\\frac{\\text{Total Current Assets}-\\text{Total Current Liabilities}}{\\text{Total Assets}}\\)"}</MathJax>
      </p>{" "}
      <h3>Retained Earning:</h3>
      <p>
        Retained earnings are the amount of profit a company has left over after paying all its direct costs, indirect costs, income taxes and its
        dividends to shareholders.
      </p>
      <h3>Market Value of Equity:</h3>
      <p>
        Market value of equity is the same as market capitalization and both are calculated by multiplying the total shares outstanding by the current
        price per share. Market value of equity changes throughout the trading day as the stock price fluctuates.
      </p>
      <p className="text-2xl mb-10 mt-10 pb-3 overflow-x-auto w-full ">
        <MathJax>{"\\( \\text{D}=\\frac{\\text{Total Outstanding Shares} \\times \\text{Price per Share}}{\\text{Total Assets}}\\)"}</MathJax>
      </p>{" "}
      <h2>Interpretation of the Altman Z-Score</h2>
      <p>
        Investors can use Altman Z-score Plus to evaluate corporate credit risk. A score below 1.8 signals the company is likely headed for
        bankruptcy, while companies with scores above 3 are not likely to go bankrupt. Investors may consider purchasing a stock if its Altman Z-Score
        value is closer to 3 and selling, or shorting, a stock if the value is closer to 1.8. In more recent years, Altman has stated a score closer
        to 0 rather than 1.8 indicates a company is closer to bankruptcy.
      </p>
      <p className="text-2xl mb-10 mt-10  pb-3 overflow-x-auto w-full ">
        <MathJax>{"\\( Z_{Score}<1.8 \\rightarrow\\text{High Risk of Bankruptcy}\\)"}</MathJax>
        <MathJax>{"\\( Z_{Score}>3\\rightarrow\\text{Low Risk of Bankruptcy}\\)"}</MathJax>
      </p>{" "}
      <h2>Success of the Altman Z-Score</h2>
      <p>
        In 2007, Altman's Z-score indicated that the companies' risks were increasing significantly. The median Altman Z-score of companies in 2007
        was 1.81, which is very close to the threshold that would indicate a high probability of bankruptcy. Altman's calculations led him to believe
        a crisis would occur that would stem from corporate defaults, but the meltdown, which brought about the 2008 financial crisis, began with
        mortgage-backed securities (MBS); however, corporations soon defaulted in 2009 at the second-highest rate in history.
      </p>
    </div>
  );
};

export default AltmanZScore;
