import React, { useEffect, useState } from "react";
import useBacktestingStore from "../../../store/backtesting/store";

const LogBuyAndSell = () => {
  const { results, years } = useBacktestingStore();

  const [log, setLog] = useState([]);

  useEffect(() => {
    if (Object.keys(results).length > 0) {
      let log = [];
      years.forEach((year, index) => {
        log.push(
          <pre data-prefix="$">
            <code>{}</code>
          </pre>
        );

        log.push(
          <pre data-prefix="$">
            <code>{`--- Backtest for Year ${year} --- `}</code>
          </pre>
        );
        log.push(
          <pre data-prefix="$">
            <code>{}</code>
          </pre>
        );
        results[year].forEach((element) => {
          log.push(
            <pre data-prefix="$">
              <code>{`BUY $${element.document_id} @ ${element.price[0]}`}</code>
            </pre>
          );
          log.push(
            <pre data-prefix="$">
              <code>{`SELL $${element.document_id} @ ${element.price[element.price.length - 1]} with profit of ${
                parseInt((10000 * (element.price[element.price.length - 1] - element.price[0])) / element.price[0]) / 100
              }%`}</code>
            </pre>
          );
          log.push(
            <pre data-prefix="$">
              <code>{}</code>
            </pre>
          );
        });
      });

      setLog(log);
    }
  }, [results]);

  return (
    <div>
      {" "}
      <div className="mockup-code h-96 overflow-y-scroll">
        <pre data-prefix="$">
          <code>Start...</code>
        </pre>
        <pre data-prefix="$">
          <code></code>
        </pre>
        {log.map((e) => e)}
        <pre data-prefix="$">
          <code></code>
        </pre>
        <pre data-prefix="$">
          <code>End</code>
        </pre>
      </div>
    </div>
  );
};

export default LogBuyAndSell;
