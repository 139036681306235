import React from "react";

const MichaelBurryArticle = () => {
  return (
    <div className="screener-article">
      <p>
        Michael James Burry is an American investor and hedge fund manager. He founded the hedge fund Scion Capital, which he managed from 2000 to
        2008 before closing the fund to focus on his personal investments. He is best known for being one of the first investors to predict and profit
        from the subprime mortgage crisis that occurred between 2007 and 2010.
      </p>
      <h3>Early life and education</h3>
      <p>
        Burry was born and raised in San Jose, California. He is of Rusyn descent. He lost his left eye to retinoblastoma at the age of two and has
        used an eye prosthesis ever since. As a teenager, he attended Santa Teresa High School.
      </p>
      <p>
        He studied economics and pre-med at the University of California, Los Angeles, earned an M.D. from Vanderbilt University School of Medicine,
        and began, but did not complete, his residency in pathology at Stanford University Medical Center. During his nights off, he worked on his
        hobby: financial investments.
      </p>
      <p>
        Despite not practicing medicine, Burry has kept his medical license active with the Medical Board of California, including continuing
        education requirements.
      </p>
      <h3>Investment career</h3>
      <p>After medical school, Burry worked as a neurology resident at Stanford Hospital, then as a pathology resident at Stanford Hospital.</p>
      <p>
        He then left to start his own hedge fund. He had already developed a reputation as an investor for his success in value investing, which he
        wrote about on the message boards of the stock discussion site Silicon Investor beginning in 1996. He was so successful with his stock picks
        that he attracted the interest of firms such as Vanguard, White Mountains Insurance Group, and prominent investors such as Joel Greenblatt.
        Burry has a strictly traditional view of value. He has said more than once that his investment style is based on Benjamin Graham and David
        Dodd's 1934 book Security Analysis: "All my stock picking is 100% based on the concept of a margin of safety."
      </p>
      <p>
        After shutting down his website in November 2000, Burry started the hedge fund Scion Capital, funded by an inheritance and loans from his
        family. He named it after Terry Brooks' The Scions of Shannara (1990), one of his favorite novels. He quickly generated extraordinary returns
        for his investors. According to author Michael Lewis, "In his first full year, 2001, the S&P 500 fell 11.88%. Scion was up 55%. The next year,
        the S&P 500 fell again, by 22.1%, and Scion was up again: 16%. The next year, 2003, the stock market finally turned around and was up 28.69%,
        but Burry beat it again with a 50% return. By the end of 2004, he was managing $600 million and making money." Burry achieved these returns in
        part by shorting overvalued tech stocks at the height of the Internet bubble.
      </p>
      <p>
        In 2005, Burry began to focus on the subprime mortgage market. His analysis of mortgage lending practices in 2003 and 2004 correctly predicted
        that the housing bubble would burst as early as 2007. His research on residential real estate values convinced him that subprime mortgages,
        especially those with "teaser" interest rates, and the bonds backed by these mortgages would begin to lose value as the original rates were
        replaced by much higher rates, often in as little as two years after origination. This conclusion led him to short the market by persuading
        Goldman Sachs and other investment firms to sell him credit default swaps on subprime deals he thought were vulnerable.
      </p>
      <p>
        While making payments on the credit default swaps, Burry suffered an investor revolt, with some investors in his fund concerned that his
        predictions were inaccurate and demanding to withdraw their capital. Ultimately, Burry's analysis proved correct: he made a personal profit of
        $100 million and a profit for his remaining investors of more than $700 million. Scion Capital ultimately posted a return of 489.34% (net of
        fees and expenses) between its inception on November 1, 2000, and June 2008. The S&P 500, widely considered the benchmark for the U.S. market,
        returned just under 3%, including dividends, over the same period.
      </p>
      <p>
        According to his website, Burry liquidated his credit default swap short positions by April 2008 and did not benefit from the 2008 and 2009
        bailouts. He then liquidated his firm to focus on his personal investments.
      </p>
      <p>
        In an April 3, 2010 op-ed for the New York Times, Burry argued that anyone who had been paying close attention to the financial markets in
        2003, 2004 and 2005 could have seen the growing risk in the subprime markets, and he blamed federal regulators for failing to heed warnings
        from outside a closed circle of advisors.
      </p>
      <p>
        In 2013, Burry reopened his hedge fund, this time called Scion Asset Management, and filed reports as an exempt reporting adviser (ERA)
        operating in the state of California and approved by the SEC. He has focused much of his attention on investing in water, gold, and farmland.
        He has said, "Fresh, clean water is not a given. And it is not - water is political and litigious."
      </p>
      <p>
        {" "}
        Insights into Scion's portfolio were provided by the quarterly 13Fs filed from the 4th quarter of 2015 through the 3rd quarter of 2016, as
        required by the SEC when fund holdings exceed $100 million. After more than two years, on February 14, 2019, Scion Asset Management filed
        another 13F showing Burry holding numerous large-cap stocks and $103,528,000 in 13F assets under management, just above the filing threshold
        In August 2019, Bloomberg News quoted an email from Burry in which he said there was a bubble in U.S. large-cap stocks due to the popularity
        of passive investing, which had "orphaned smaller value securities globally." In 2020, the fund's largest investments were Alphabet Inc.
        (worth $121 million) and Facebook (worth $24.4 million).
      </p>
      <p></p>
      Burry initiated one or more short positions in Tesla before or around early December 2020, according to a now-deleted tweet, and likely added to
      his short positions after Tesla's market capitalization surpassed that of Facebook. Burry predicted that Tesla stock would collapse like the
      housing bubble, saying that "my last Big Short got bigger and bigger and BIGGER" and taunting Tesla bulls to "enjoy it while it lasts." In May
      2021, it was reported that he held put options on over 800,000 shares of Tesla. In October 2021, after a 100% increase in the value of Tesla's
      stock, he revealed that he was no longer shorting it In the second quarter of 2021, he reportedly held put options worth nearly $31 million on
      the ARKK ETF innovation index managed by Ark Invest.
      <p>
        In August 2023, it was widely reported that Burry's hedge fund, Scion Asset Management, had placed a $1.6 billion bet on a U.S. stock market
        crash. Securities filings reportedly showed that Burry held put options on both the S&P 500 and the Nasdaq-100 at the end of the second
        quarter of 2023. While the put options were widely reported to represent 93% of Scion's total portfolio, this is misleading because the $1.6
        billion figure is based on the maximum possible value to which the put options could rise, not the amount for which they were actually
        purchased. In addition, Scion's assets under management are $237,971,170, significantly less than $1.6 billion.
      </p>
      <h3>Personal life</h3>
      <p>
        Burry is married with children and lived in Saratoga, California in 2010 His son was diagnosed with Asperger's Syndrome, and Burry believes he
        has Asperger's himself after reading about the disorder. When he was younger, he noticed that it took him a lot of energy to look people in
        the eye, saying, "If I'm looking at you, that's the only time I know I'm not listening to you.". He is a heavy metal musician.
      </p>
      <p>He is a fan of heavy metal music, including bands such as Obituary, Lamb of God, Amon Amarth, Slipknot, King Diamond, and Pantera.</p>
      <p>Burry was highly critical of the lockdowns during the COVID-19 pandemic in the United States.</p>
    </div>
  );
};

export default MichaelBurryArticle;
