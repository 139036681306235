import { useMutation, useQueryClient } from "@tanstack/react-query";
import backtestService from "../services/backtestService";

export const useBacktest = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables) => {
      console.log("Hello", variables);
      return backtestService.post(variables);
    },
    onSuccess: (result, variables, context) => {
      console.log(result, variables, context);
      queryClient.setQueryData(["backtest"], () => result);
    },
  });
};
