import React from "react";

const ONeilsCANSLIMArticle = () => {
  return (
    <div className="screener-article">
      <h3>Introduction</h3>

      <p>
        In 2009, in the wake of the financial crisis, William O'Neal published "How to Make Money in Stocks", a revolutionary guide that has sold 2
        million copies to date. Contrary to popular belief, William O'Neal advocates market timing, trading and interpreting stock charts to improve
        your investment strategy.
      </p>

      <p>
        Drawing on his research of the best-performing stocks from 1953 to 1985, O'Neil identified a set of similar features that each of these stocks
        had. The abbreviation CANSLIM represents the important features to focus on:
      </p>

      <ul className="list-disc">
        <li>
          <span className="text-xl font-bold">C:</span> Current quarterly earnings per share
        </li>
        <li>
          <span className="text-xl font-bold">A:</span> Annual earnings growth
        </li>
        <li>
          <span className="text-xl font-bold">N:</span> New products, New Management, New Highs
        </li>
        <li>
          <span className="text-xl font-bold">S</span> Supply and Demand
        </li>
        <li>
          <span className="text-xl font-bold">L:</span> Leader or Laggard
        </li>
        <li>
          <span className="text-xl font-bold">I:</span> Institutional sponsorship
        </li>
        <li>
          <span className="text-xl font-bold">M:</span> Market direction
        </li>
      </ul>

      <h3>Current quarterly earnings per share</h3>

      <p>
        The CAN SLIM approach emphasizes the importance of current quarterly earnings per share (EPS) in identifying promising stocks. William
        O'Neil's strategy focuses on companies with a proven track record of earnings growth, particularly during an acceleration phase. When
        reviewing quarterly earnings increases, comparisons are made with the equivalent quarter of the previous year to account for seasonal
        variations. <br />
      </p>

      <p className="text-primary font-semibold">Rule C : Minimum quarterly earnings increase of 18% to 20% over the same period last year.</p>

      <h3>Annual earnings growth</h3>

      <p>
        In the CAN SLIM system, annual earnings growth is an essential element in identifying winning stocks, as William O'Neil's analysis underlines.
        Beyond current earnings, Mr. O'Neil emphasizes the importance of steady and substantial annual earnings growth for market leaders.
      </p>

      <p>
        Mr. O'Neil's main selection method is to scrutinize earnings per share (EPS) over the past five years, applying a rigorous criterion that EPS
        from continuing operations must exceed the previous year's figures for each of the past five years. This guarantees a sustained upward
        trajectory, with an additional criterion that earnings over the last 12 months must equal or exceed earnings for the last fiscal year.
      </p>

      <p>
        In addition, O'Neil recommends identifying companies whose earnings per share from continuing operations have grown at{" "}
        <b>a minimum annual rate of 25% </b>over the past five years. This essential criterion is in line with O'Neil's findings that high-performing
        stocks often show a median growth rate of 21%.
      </p>

      <p className="text-primary font-semibold">Rule A : Quarterly growth rate greater than or equal to 25% </p>
      <p className="text-primary font-semibold">Rule A : Positive EPS from Continuing Operations for the current quarter</p>

      <h3>New products, New Management, New Highs</h3>

      <p>
        According to William O'Neil, winning stocks often need a catalyst to trigger a sharp rise in share price. His analysis of winning stocks
        reveals that 95% were propelled forward by a fundamental spark: <b>a new product, a revamped management team </b> or a structural change in
        the sector. Although these qualitative factors are difficult to pin down, O'Neil suggests studying companies that pass the first checks to
        identify potential catalysts.
      </p>

      <p className="text-primary font-semibold">Rule N : Stocks within 10% of their 52-week high </p>

      <h3>Supply and Demand</h3>

      <p>
        Within the CAN SLIM framework, the 'S' stands for Supply and Demand, focusing on the impact of a company's stock float and outstanding shares
        on its price movement.
      </p>
      <p>
        William O'Neil's research indicates that stocks with<b> fewer than 25 million shares outstanding </b>, in particular, tend to experience
        faster price rises. A study by Marc Reinganum follows suit, setting the threshold at 20 million shares outstanding. To refine the analysis,
        Mr. O'Neil suggests taking into account the "free float", i.e. shares held by the public. The selection criterion requires shares with less
        than 20 million available shares in the free float, which is a less restrictive filter.
      </p>

      <p className="text-primary font-semibold">Rule S : Less than 20 million available float shares</p>
      <h3>Leader or Laggard</h3>

      <p>
        Rather than waiting for the market to evolve, William O'Neil recommends seeking out the best-performing stocks in fast-growing sectors. He
        recommends investing in a group's top two or three stocks, and believes that the potential premium paid for these leaders is justified by the
        significantly higher returns they can offer.
      </p>
      <p>
        O'Neil stresses the importance of relative strength in identifying market leaders, using it to compare a stock's performance with that of the
        market as a whole. He suggests taking into account only those stocks whose percentage is equal to or greater than 70%, indicating superior
        performance to that of 70% of all stocks. For a more rigorous approach, O'Neil recommends considering stocks with a relative strength of 80%
        or 90%, possibly combined with a base chart.
      </p>

      <p className="text-primary font-semibold">Rule L : Stocks with a relative strength of 80% or 90% </p>

      <h3>Institutional Sponsorship</h3>

      <p>
        The 'I' underscores the importance of Institutional Sponsorship, and William O'Neil advises against low-priced stocks with minimal
        institutional ownership due to potential liquidity issues and lower-grade ratings. O'Neil suggests a moderate threshold of{" "}
        <b> 3 to 10 institutional owners as a minimum </b>for a stock to exhibit above-market performance.
      </p>
      <p>
        It's crucial to focus not just on the quantity but also on the track record of these institutional investors. Examining the holdings of
        successful mutual funds provides valuable insights, and Stock Investor's screening includes a criterion for stocks to have at least five
        institutional owners.
      </p>

      <p className="text-primary font-semibold">Rule I : 3 to 10 institutional owners as a minimum </p>
      <h3>Market Direction</h3>

      <p>
        While not directly influencing stock selection, the overall market trend significantly impacts your investment outcomes. William O'Neil
        underscores the importance of technical analysis for gauging market direction, emphasizing the need to understand whether you are in a bull or
        bear market.
        <br />
        O'Neil's advice includes closely monitoring market averages daily and swiftly moving a portion of the portfolio to cash during market peaks
        and major reversals
      </p>
      <p>
        He highlights the importance of recognizing warning signs such as stocks failing to show a profit and heavy volume without substantial price
        progress.Additionally, O'Neil suggests keeping an eye on the actions of the Federal Reserve as a fundamental confirmation of market trends,
        particularly noting increases in the discount rate as potential precursors to economic and market declines.
      </p>

      <p className="text-primary font-semibold">
        Rule M : Fundamental confirmation of the market action : the discount rate is often a precursor to both an economic and market decline.
      </p>
    </div>
  );
};

export default ONeilsCANSLIMArticle;
