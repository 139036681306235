import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark, faArrowRight, faRightToBracket, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { menuDocumentation } from "../data/navigationDocumentation";

const NavBarDoc = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [pathMenu, setPathMenu] = useState({ menu: "", submenu: "" });
  const location = useLocation();
  const path = location.pathname.split("/").slice(-1)[0];

  const [searchString, setSearchString] = useState("");
  const [menuDoc, setmenuDoc] = useState([...menuDocumentation]);

  const handleChange = (event) => {
    const search = event.target.value;
    let newMenu = [];
    if (search !== "") {
      let content = [];

      menuDocumentation.forEach((menu) => {
        menu.content.forEach((submenu) => {
          if (submenu.name.toLowerCase().includes(search.toLowerCase())) {
            content.push(submenu);
          }
        });
      });

      newMenu = [{ name: "Results", content }];

      setSearchString(search);
      setmenuDoc([...newMenu]);
    } else {
      setmenuDoc([...menuDocumentation]);
      setSearchString("");
    }
  };

  useEffect(() => {
    menuDocumentation.forEach((menu) => {
      menu.content.forEach((submenu) => {
        if (submenu.url === path) {
          setPathMenu({
            menu: menu.name,
            submenu: submenu.name,
          });
        }
      });
    });
  }, [path]);

  return (
    <>
      <div className="flex items-center p-4 border-b border-slate-900/10 lg:hidden dark:border-slate-50/[0.06]">
        <button
          type="button"
          className="text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Navigation</span>
          <svg width="24" height="24">
            <path d="M5 6h14M5 12h14M5 18h14" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"></path>
          </svg>
        </button>
        <ol className="ml-4 flex text-sm leading-6 whitespace-nowrap min-w-0">
          <li className="flex items-center">
            {pathMenu.menu}
            <svg width="3" height="6" aria-hidden="true" className="mx-3 overflow-visible text-slate-400">
              <path d="M0 0L3 3L0 6" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path>
            </svg>
          </li>
          <li className="font-semibold text-slate-900 truncate dark:text-slate-200">{pathMenu.submenu}</li>
        </ol>
      </div>

      {/* Menu Glissant  */}
      <div
        className={`lg:hidden z-100 top-0 left-0 w-[100vw] p-10 pl-20 fixed h-full z-40 transition-transform ease-in-out duration-500 transform ${
          mobileMenuOpen ? "translate-y-0 " : "translate-y-full"
        }`}
      >
        <div className="fixed inset-0 z-100" />
        <div className="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-base-100 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-neutral">
          <div className="flex items-center justify-between">
            {/* <a href="#" className="-m-1.5 p-1.5">
              <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="" />
            </a> */}
            <button type="button" className="-m-2.5 rounded-md p-2.5 text-neutral" onClick={() => setMobileMenuOpen(false)}>
              <span className="sr-only">Close menu</span>
              <FontAwesomeIcon icon={faXmark} className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <ul>
            <li className="mt-12 lg:mt-8">
              <input type="text" placeholder="Search..." className="input input-bordered w-full max-w-xs" onChange={handleChange} />
            </li>
          </ul>

          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {menuDoc.map((menu) => {
                  return (
                    <li className="mt-5 lg:mt-8 list-none" key={"menu_" + menu.name}>
                      <h5 className="mb-5 lg:mb-3 font-semibold text-slate-900 dark:text-slate-200">{menu.name}</h5>
                      <ul className="space-y-5 lg:space-y-2 border-l border-slate-100 dark:border-slate-800 mb-5">
                        {menu.content.map((submenu) => {
                          let name = submenu.name;
                          if (searchString !== "") {
                            let names = submenu.name.toLowerCase().split(searchString);
                            name = (
                              <p>
                                {names[0].charAt(0).toUpperCase() + names[0].slice(1)}
                                <span className="link-content">
                                  {names[0].length === 0 ? searchString.charAt(0).toUpperCase() + searchString.slice(1) : searchString}
                                </span>
                                {names[1]}
                              </p>
                            );
                          }
                          return (
                            <li key={"menu_" + submenu.url} data-active="true" className="list-none">
                              <Link
                                className={
                                  path === submenu.url
                                    ? "block border-l pl-4 -ml-px text-sky-500 border-current font-semibold dark:text-sky-400"
                                    : "block border-l pl-4 -ml-px border-transparent hover:border-slate-400 dark:hover:border-slate-500 text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300"
                                }
                                to={`${process.env.PUBLIC_URL}/docs/${submenu.url}`}
                                onClick={() => setMobileMenuOpen(false)}
                              >
                                {name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBarDoc;
