import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { hydrate, render } from "react-dom";

// Import Route
import HomePage from "./routes/HomePage/HomePage";
import Login from "./routes/Authentification/Login/Login";
import { UserContext } from "./context/usercontext";
import { auth } from "./firebase/firebase";
import Signup from "./routes/Authentification/Signup/signup";
import PasswordReset from "./routes/Authentification/PasswordReset/PasswordReset";
import TermsOfUse from "./routes/StaticPages/Terms/TermsOfUse";
import Privacy from "./routes/StaticPages/Terms/Privacy";
import ScreenerPage from "./routes/ScreenerPage/ScreenerPage";
import Pricing from "./routes/Pricing/Pricing";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import ScreenerPageHome from "./routes/ScreenerPage/ScreenerPageHome";
import ProfilePage from "./routes/Profile/ProfilePage";
import DocsHomePage from "./routes/Docs/DocsHomePage";
import DocsPage from "./routes/Docs/DocsPage";
import ContactPage from "./routes/StaticPages/Contact/ContactPage";
import AboutUsPage from "./routes/StaticPages/AboutUs/AboutUsPage";
import Admintool from "./routes/adminTool/Admintool";
import ScreenerPageHomeAlt from "./routes/ScreenerPage/ScreenerPageHomeAlt";
import BacktestingPage from "./routes/Backtesting/BacktestingPage";

const queryClient = new QueryClient();

const getSubscription = async ({ user }) => {
  const uid = user.uid;
  const res = await fetch("https://guruscreener.io/api/stripe/subscription?uid=" + uid);
  return res.json();
};

const Root = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [hasSubscription, setHasSubscription] = useState(null);
  const [hasEbook, setHasEbook] = useState(null);
  const querySubscription = useQuery({ queryKey: ["subscription"], queryFn: () => getSubscription({ user: currentUser }), enabled: !!currentUser });

  useEffect(() => {
    if (!querySubscription.isPending) {
      if (querySubscription.data.subscription !== hasSubscription) {
        setHasSubscription(querySubscription.data.subscription);
        setHasEbook(querySubscription.data.ebook);
      }
    }
  }, [querySubscription.isPending]);

  useEffect(() => {
    auth.onAuthStateChanged(setCurrentUser);
  }, []);

  return (
    <UserContext.Provider value={{ user: currentUser, hasSubscription, hasEbook }}>
      <BrowserRouter>
        <App>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/passwordreset" element={<PasswordReset />} />
            <Route path="/termsofuse" element={<TermsOfUse />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/screener/:screenerName" element={<ScreenerPage />} />
            <Route path="/backtest/" element={<BacktestingPage />} />
            <Route path="/screener2/" element={<ScreenerPageHome />} />
            <Route path="/screener/" element={<ScreenerPageHomeAlt />} />
            <Route path="/pricing/" element={<Pricing />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/profile/" element={<ProfilePage />} />
            <Route path="/docs/" element={<DocsHomePage />} />
            <Route path="/docs/:document" element={<DocsPage />} />

            <Route path="/admin" element={<Admintool />} />
          </Routes>
        </App>
      </BrowserRouter>
    </UserContext.Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
const rootElement = document.getElementById("root");

// root.render(
//   <QueryClientProvider client={queryClient}>
//     <Root />
//     <ReactQueryDevtools initialIsOpen={false} />
//   </QueryClientProvider>
// );

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(
    rootElement,
    <QueryClientProvider client={queryClient}>
      <Root />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
} else {
  root.render(
    <QueryClientProvider client={queryClient}>
      <Root />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
