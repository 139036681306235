import React, { useState } from "react";
import { menuDocumentation } from "../data/navigationDocumentation";
import { Link, useLocation } from "react-router-dom";

const SidebarDocumentation = () => {
  const location = useLocation();
  const path = location.pathname.split("/").slice(-1)[0];
  const [searchString, setSearchString] = useState("");
  const [menuDoc, setmenuDoc] = useState([...menuDocumentation]);

  const handleChange = (event) => {
    const search = event.target.value;
    let newMenu = [];
    if (search !== "") {
      let content = [];

      menuDocumentation.forEach((menu) => {
        menu.content.forEach((submenu) => {
          if (submenu.name.toLowerCase().includes(search.toLowerCase())) {
            content.push(submenu);
          }
        });
      });

      newMenu = [{ name: "Results", content }];

      setSearchString(search);
      setmenuDoc([...newMenu]);
    } else {
      setmenuDoc([...menuDocumentation]);
      setSearchString("");
    }
  };

  return (
    <nav id="nav" className="lg:text-sm lg:leading-6 relative mb-10">
      <ul>
        <li className="mt-12 lg:mt-8">
          <p className="text-xl mb-8 lg:mb-3 ms-2 tracking-tighter font-semibold ">
            <span className=" dark:text-blue1 text-primary"> GuruScreener</span>
            <span className="">{" DOCS"}</span>
          </p>
        </li>
        <li className="mt-12 lg:mt-8">
          <input type="text" placeholder="Search..." className="input input-bordered w-full max-w-xs" onChange={handleChange} />
        </li>
        {menuDoc.map((menu) => {
          return (
            <li className="mt-12 lg:mt-8" key={"menu_" + menu.name}>
              <h5 className="mb-8 lg:mb-3 font-semibold text-slate-900 dark:text-slate-200">{menu.name}</h5>
              <ul className="space-y-6 lg:space-y-2 border-l border-slate-100 dark:border-slate-800">
                {menu.content.map((submenu) => {
                  let name = submenu.name;
                  if (searchString !== "") {
                    let names = submenu.name.toLowerCase().split(searchString.toLowerCase());
                    name = (
                      <p>
                        {names[0].charAt(0).toUpperCase() + names[0].slice(1)}

                        <span className="link-content">
                          {names[0].length === 0 ? searchString.charAt(0).toUpperCase() + searchString.slice(1) : searchString}
                        </span>
                        {names[1]}
                      </p>
                    );
                  }
                  return (
                    <li key={"submenu_" + submenu.name} data-active="true">
                      <Link
                        className={
                          path === submenu.url
                            ? "block border-l pl-4 -ml-px text-primary border-current font-semibold dark:text-sky-400"
                            : "block border-l pl-4 -ml-px border-transparent hover:border-slate-400 dark:hover:border-slate-500 text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300"
                        }
                        to={`${process.env.PUBLIC_URL}/docs/${submenu.url}`}
                      >
                        {name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default SidebarDocumentation;
