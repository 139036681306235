import React from "react";

const GuaranteePage = () => {
  return (
    <div className="screener-article">
      <h1>Warranty Policy - What to expect with GuruScreener ?</h1>
      <p>
        At GuruScreener, we believe that our community has the right to be satisfied. We strongly believe in the financial books we calculate and our
        ability to screen the market, that's why we offer a warranty on our solution.{" "}
      </p>
      <p>
        In a way, GuruScreener is a data provider. We use financial knowledge and market data as input to provide a ranking. This ranking provides you
        with potential investment opportunities.
      </p>
      <h2>Performance-based warranty</h2>
      <p>
        Guruscreener offers a performance-based warranty. We strongly believe in the ability of the famous strategies we implement to beat the market.
      </p>
      <p>But what does the performance-based warranty mean? </p>
      <p>
        It's hard to define performance because it's based on multiple factors like money management, timing. Our warranty works when some actions are
        respected:{" "}
      </p>

      <ul className="list-disc">
        <li>Investing in the Top 10 of a screener</li>
        <li>The screener is book-inspired and not custom-made</li>
        <li>{"Minimum holding periods (>6 months)"}</li>
        <li>No leveraged positions</li>
        <li>Follow "when to sell" rules</li>
      </ul>

      <p>
        But we also believe in a relationship of trust, so everyone could explain their case and why they think GuruScreener doesn't provide good
        services.
      </p>

      <h2>How to request the warranty? </h2>
      <p>
        As explained on the previous chapter, the notion of "Beat the market" is hard to evaluate without proper information. The warranty is applied
        when you submit your request at the contact@guruscreener.io. You should provide an explanation and the list of transactions you made (date of
        the transaction, name of the asset and buy/sell price, name of the screener you follow.
      </p>

      <p>The request must be asked after at least 6 months of use.</p>

      <p>Once submitted, our service will send you back a positive or negative detailed response. </p>
      <p>There is no refund on past payments, we offer free subscription for a period of time (up to 12 months).</p>
    </div>
  );
};

export default GuaranteePage;
