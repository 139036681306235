import React, { useEffect, useState, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import { dataScreener } from "../routes/ScreenerPage/data/dataScreenerV2";
import badgeColor from "../utils/badgeColor";
import BadgeFavorite from "../utils/BadgeFavorite";
// import LoadingCard from "./LoadingCard";

const LazyApexChartsBasic = lazy(() => import("./ApexChartsBasic"));

const CardScreener = ({ screenerName, favorites }) => {
  const { name, performances, description, filters, plotValue, dataEndPoint, author } = dataScreener[screenerName];
  const [currentTheme, setCurrentTheme] = useState(localStorage.getItem("theme") ? localStorage.getItem("theme") : "light");

  useEffect(() => {
    window.addEventListener("theme", handleEvent, false);
  }, []);

  function handleEvent() {
    const localTheme = localStorage.getItem("theme");
    setCurrentTheme(localTheme);
    // Apply color to chart wia State
  }
  const isDarkMode = () => {
    return currentTheme === "dark";
  };

  return (
    <Link className="m-0 p-0 " to={`/screener/${dataEndPoint["url"]}`}>
      <div className="relative bg-base-100 dark:bg-slate-800 rounded-md py-6 border border-base-300 dark:border-base-200  ring-slate-900/5 dark:ring-slate-900/60  dark:shadow-xl hover:border-primary  hover:shadow-lg hover:shadow-primary/5 duration-300 transition-all">
        <BadgeFavorite name={screenerName} favorites={favorites} />

        <div className="flex flex-row px-5">
          <h5 className="basis-3/4 mb-2 text-lg font-semibold tracking-tighter line-clamp-2 h-16">{name}</h5>

          <div className="flex flex-row-reverse basis-1/4   ">
            <div className="text-center tracking-tighter ml-4">
              <div className="text-primary font-bold">{performances["risk"]}%</div>
              <span className="text-xs text-base-content/50 text">Risk</span>
            </div>
            <div className="text-center tracking-tighter">
              <div className="text-secondary font-bold">{performances["15y"]}%</div>
              <span className="text-xs text-base-content/50 text">Perf/year</span>
            </div>
          </div>
        </div>
        {author !== "" && <h5 className=" pl-5 basis-3/4  tracking-tighter line-clamp-2">{author}</h5>}

        <div className="pointer-events-none w-full text-center">
          <Suspense fallback={<div className="h-[250px]"></div>}>
            <LazyApexChartsBasic darkMode={isDarkMode()} plotValue={plotValue} className="py-0" />
          </Suspense>

          {/* <ApexChartsBasic darkMode={isDarkMode()} plotValue={plotValue} className="py-0" /> */}
        </div>

        <div className={`hidden sm:line-clamp-2 text-xs text-base-content/50 text px-5`}>{description}</div>
        <div className="text-xs text-base-content mt-1 sm:mt-3 px-5 flex flex-wrap ">
          {filters.map((filter) => (
            <div key={filter} className={`badge badge-outline mr-2 ${badgeColor(filter)}`}>
              {filter}
            </div>
          ))}
        </div>
        <div className="text-center mt-5 mx-5 ">
          <button className="btn btn-primary btn-sm btn-block">Discover</button>
        </div>
      </div>
    </Link>
  );
};

export default CardScreener;
