import React from "react";
import { menuDocumentation } from "../../data/navigationDocumentation";
import Card from "./../../../../common/Card";
import { Link } from "react-router-dom";

const DocIntroduction = () => {
  return (
    <div className="screener-article">
      <h1 className="text-4xl font-bold tracking-tight sm:text-4xl">Welcome to Guru Screenner</h1>
      <p className="mt-4 text-lg text-slate-700 dark:text-slate-400">
        Welcome to GuruScreener, your gateway to informed investing inspired by financial gurus and timeless principles. Dive into a curated stock
        market experience, where legendary wisdom meets cutting-edge technology. Discover investment opportunities aligned with the teachings of
        renowned gurus. Join us on a journey where the spirit of financial giants converges with a powerful stock screener, guiding you towards
        financial success. Start your journey with GuruScreener today!
      </p>
      <h2>Start with GuruScreener</h2>
      <p>
        To enjoy a maximal experience,{" "}
        <Link to={`${process.env.PUBLIC_URL}/signup`} className="link-content">
          create an account.
        </Link>{" "}
        The documentation will guide you through the GuruScreener solution. If you do not find your answer, feel free to contact our team on Social
        Media or by email at <span className="link-content">contact@guruscreener.io</span>
      </p>

      <h2>What GuruScreener is ? </h2>
      <p>
        GuruScreener is a financial buddy that helps you find the right investments that match your style. Forget about complicated technical analysis
        and make it easy. We believe that investing is an art and needs education, but in a time when there is too much information, every investor
        needs to use the right tool. In a way, GuruScreener is a library of famous strategies from famous investors that we calculate for you. Our job
        is to ensure the integrity of the data and the consistency of the strategies we compute.
      </p>

      <h2>What GuruScreener is not ? </h2>

      <p>
        GuruScreener is definitely not a miracle solution to make millions in hours. We strongly believe that the market is a place of opportunity,
        but always respect the risk/reward ratio. We trust the financial strategies we share, but we try to judge them objectively.
      </p>
      <h2 className="bg-primary-muted">Documentation</h2>

      {menuDocumentation.map((menu) => {
        return (
          <div>
            <h3>{menu.name} topics:</h3>
            <Card>
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-10 text-center">
                {menu.content.map((submenu) => {
                  return (
                    <Link to={`${process.env.PUBLIC_URL}/docs/${submenu.url}`} className="">
                      <span className="link-content">{submenu.name}</span>
                    </Link>
                  );
                })}
              </div>
            </Card>
          </div>
        );
      })}
    </div>
  );
};

export default DocIntroduction;
