import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag, faLock } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const LockWhenToSell = () => {
  return (
    <>
      <h3 className="text-white">Sell Strategy </h3>
      <div className="grid md:grid-cols-2  gap-3 my-3 lg:my-5 ">
        <div className=" relative hover:ring hover:ring-slate-500/60 rounded-lg duration-300 py-5 px-5 group">
          <div className="flex">
            <div className="text-white/70   flex  mx-auto  lg:mb-5 group-hover:text-white duration-300">
              <FontAwesomeIcon className="fa-2x mt-1" icon={faHashtag} /> <span className="text-4xl ml-2 "> 1</span>
            </div>
            <div className="ml-4 ">
              <h4 className="text-xl tracking-tight font-extrabold text-white/70  py-0 blur select-none" aria-hidden="true">
                Well done, you managed to find a way to unblur the text!
              </h4>
              <div className=" blur select-none" aria-hidden="true">
                At Guruscreener, we believe that smart people should be rewarded. That's why we've created a special discount for you. It's like
                you've found a treasure and we're giving you a gift. With the code HACKER30 you will receive a 30% discount on your subscription for
                life.
                <br /> This discount is specific to your account and cannot be applied to any other account.
              </div>
            </div>
          </div>
          <div className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <FontAwesomeIcon className=" fa-3x text-white/50 group-hover:text-white duration-300 " icon={faLock} />
          </div>
          <div className="absolute top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Link to={`${process.env.PUBLIC_URL}/pricing`}>
              <button className=" btn btn-outline text-white/50 btn-accent mt-5">Unlock</button>
            </Link>
          </div>
        </div>

        <div className=" relative hover:ring hover:ring-slate-500/60 rounded-lg duration-300 py-5 px-5 group">
          <div className="flex">
            <div className="text-white/70   flex  mx-auto  lg:mb-5 group-hover:text-white duration-300">
              <FontAwesomeIcon className="fa-2x mt-1" icon={faHashtag} /> <span className="text-4xl ml-2 "> 2</span>
            </div>
            <div className="ml-4 ">
              <h4 className="text-xl tracking-tight font-extrabold text-white/70  py-0 blur select-none" aria-hidden="true">
                Well done! You managed to find a way to make the text blur free!
              </h4>
              <div className=" blur select-none" aria-hidden="true">
                At Guruscreener, we believe that smart people should be rewarded. That's why we've created a special discount for you.
                <br />
                It's like you've found a treasure and we're giving you a gift. With the code HACKER30 you will receive a 30% discount on your
                subscription for life.
                <br /> This discount is specific to your account.
              </div>
            </div>
          </div>
          <div className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <FontAwesomeIcon className=" fa-3x text-white/50 group-hover:text-white duration-300 " icon={faLock} />
          </div>
          <div className="absolute top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Link to={`${process.env.PUBLIC_URL}/pricing`}>
              <button className=" btn btn-outline text-white/50 btn-accent mt-5">Unlock</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="mx-4 mt-2">
        If you don't sell early, you'll be late. Your object is to make and take significant gains and not get excited, optimistic, greedy, or
        emotionally carried away as your stock's advance gets stronger.
        <br />
        Keep in mind the old saying :<b>" Bulls make money and bears make money, but pigs get slaughtered."</b>
      </div>
    </>
  );
};

export default LockWhenToSell;
