import { create } from "zustand";

const useBacktestingStore = create((set) => ({
  conditions: [],
  startSimulation: false,
  results: [],
  years: [
    ...[...Array(23).keys()].map((e) => {
      return String(2000 + e);
    }),
  ],
  addConditions: (condition) =>
    set((store) => ({
      conditions: [...store.conditions, condition],
    })),
  deleteConditions: (id) =>
    set((store) => ({
      conditions: [...store.conditions.filter((e) => e.id !== id)],
    })),

  compute: () => set(() => ({ startSimulation: true })),
  computeDone: () => set(() => ({ startSimulation: false })),
  setResults: (data) => set(() => ({ results: data })),
  reset: () =>
    set(() => ({
      conditions: [],
    })),
}));

export default useBacktestingStore;
