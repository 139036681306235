import React from "react";
import { MathJax } from "better-react-mathjax";
const ReturnOnInvestedCapital = () => {
  return (
    <div className="screener-article">
      <h1>Return On Invested Capital - ROIC - What you need to know ?</h1>
      <h2>What is ROIC</h2>
      <p>
        Return on Invested Capital (ROIC) measures a company's efficiency in allocating capital to profitable investments. It is calculated by
        dividing net operating profit after tax (NOPAT) by invested capital.
      </p>
      <p>
        ROIC gives a sense of how well a company is using its capital to generate profits. Comparing a company's ROIC to its weighted average cost of
        capital (WACC) shows whether invested capital is being used effectively.
      </p>
      <h2>Formula of the ROIC</h2>
      <p className="text-xl mb-10">
        <MathJax>{"\\( \\text{ROIC}=\\frac{\\text{NOPAT}}{\\text{Invested Capital}} \\)"}</MathJax>
      </p>
      <p>Where:</p>
      <p className="text-xl mb-10">
        <MathJax>{"\\( \\text{NOPAT}=\\text{Net Operating Profit After Tax} \\)"}</MathJax>
      </p>
      <p className="text-xl mb-10">
        <MathJax>{"\\( \\text{NOPAT}=\\text{Operating Profit}*(1-\\text{Tax Rate}) \\)"}</MathJax>
      </p>
      <p>Or: </p>
      <p className="text-xl mb-10">
        <MathJax>{"\\( \\text{ROIC}=\\frac{\\text{Net Income}-\\text{Dividends}}{\\text{Debt} +\\text{Equity}} \\)"}</MathJax>
      </p>

      <h2>Interpretation of the ROIC</h2>
      <p>
        Return on Invested Capital (ROIC) measures how efficiently a company allocates the capital it controls to profitable investments or projects.
        The ROIC ratio gives a sense of how well a company is using externally raised funds to generate returns. Comparing a company's return on
        invested capital to its weighted average cost of capital (WACC) shows whether invested capital is being used effectively.
      </p>
      <p>
        ROIC is a popular financial measure. It tells us how well a company is using its capital and whether it is creating value from its
        investments. At a minimum, a company's ROIC should be higher than its cost of capital. If it's not consistently higher, the business model is
        not sustainable.
      </p>

      <p>
        ROIC is particularly useful when examining companies that invest large amounts of capital. In addition, like many ratios, it is more
        meaningful when used to compare similar companies in the same industry. Often, the companies in a sector with the highest ROICs trade at a
        premium.
      </p>
    </div>
  );
};

export default ReturnOnInvestedCapital;
