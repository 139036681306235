import { useQuery } from "@tanstack/react-query";

import indicatorService from "../services/indicatorService";

export const useIndicators = ({ symbol }) => {
  return useQuery({
    queryKey: ["indicators", symbol],

    queryFn: () =>
      indicatorService.get({
        params: { symbol: symbol, indicators: ["rank_profitability", "rank_gf_value", "rank_balancesheet", "rank_growth", "rank_momentum"] },
      }),
    refetchOnWindowFocus: false,
    staleTime: 600000,
  });
};
