import { faMoneyBillWave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import CardDark from "../../../common/CardDark";
import TitleCard from "../../../common/TitleCard";
import LockWhenToSell from "./LockWhenToSell";

const WhenToSellComponent = ({ hasSubscription, whentosell }) => {
  return (
    <div className="col-start-1 col-end-10">
      <TitleCard>
        <FontAwesomeIcon className="text-lg mr-1" icon={faMoneyBillWave} /> When to Sell
      </TitleCard>
      <CardDark>{!hasSubscription ? <LockWhenToSell /> : whentosell}</CardDark>
    </div>
  );
};

export default WhenToSellComponent;
