import React, { Suspense, lazy, useContext, useState } from "react";
import CTASection from "../../components/CTASection";
import { plans } from "./dataPricing";
import EBook from "./eBook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faGift } from "@fortawesome/free-solid-svg-icons";
import FAQ1 from "../../components/FaqLanding";
import { UserContext } from "../../context/usercontext";
import { Link } from "react-router-dom";

const LazyButtonStripe = lazy(() => import("./stripe/oneTimePaimentStripe"));

const Pricing = () => {
  const { user, hasSubscription } = useContext(UserContext);

  console.log(user);

  const [loading, setLoading] = useState(false);

  return (
    <>
      <div>
        {loading && (
          <div className="fixed left-0 top-0 z-50 block h-full w-full bg-white opacity-50 dark:opacity-20">
            {" "}
            <span className="r-4 relative top-1/2 mx-auto my-0 block h-0 w-0  opacity-75">
              <div role="status">
                <svg
                  aria-hidden="true"
                  className=" h-16 w-16 animate-spin dark:text-blue1 fill-blue-800 text-gray-200 "
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />{" "}
                </svg>{" "}
              </div>{" "}
              <p className="font-bold  ">Loading...</p>
            </span>{" "}
          </div>
        )}
        <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#34aadc] to-[#ff80b5] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>

        <div className="mx-auto max-w-screen-xl pb-10 pt-5 lg:pb-30 lg:pt-15">
          <div className="max-w-xl mx-auto sm:text-center">
            <h1 className="text-3xl font-bold tracking-tight sm:text-3xl">No Sub, pay once and get access for life!</h1>
            <div className="mt-6 text-lg leading-8 text-base-content">
              <p>Get full access to popular screeners and discover amazing companies to invest in.</p>
            </div>
          </div>

          <div className="mt-20 space-y-6 justify-center gap-6 sm:grid md:grid-cols-2 sm:space-y-0 lg:grid-cols-3">
            {plans.map((item, index) => (
              <div
                key={index}
                className={`relative flex-1 flex items-stretch flex-col p-8 rounded-xl border-2 bg-base-100 hover:border-primary border-base-300 dark:border-gray-700  hover:shadow-lg hover:shadow-primary/10 duration-300 transition-all ${
                  item.isMostPop ? "mt-10" : ""
                }`}
              >
                {item.isMostPop ? (
                  <span className="w-32 absolute -top-5 left-0 right-0 mx-auto px-3 py-2 rounded-full border shadow-md bg-base-100 text-center text-sm font-semibold">
                    Most popular
                  </span>
                ) : (
                  ""
                )}

                <div>
                  <span className="text-primary font-medium">{item.name}</span>

                  {item.price === "Custom" && <div className="mt-4 text-3xl font-bold">{item.price}</div>}

                  {item.price === 0 && <div className="mt-4 text-4xl font-bold">${item.price}</div>}

                  {item.price === 69 && (
                    <div className="mt-4 text-4xl font-bold ">
                      $<span className="line-through decoration-4">{item.price}</span>{" "}
                      <span className="from-secondary to-primary bg-gradient-to-r bg-clip-text text-transparent text-5xl">$49</span>
                      <span className="text-xl text-base-content/50 font-normal "> One-time payment</span>
                    </div>
                  )}

                  {item.realPlan && <div className="text-l mt-5 text-base-content font-normal">NEW: Checkout in your local currency</div>}
                </div>

                <ul className="py-8 space-y-3">
                  {item.features.map((featureItem, index) => (
                    <li key={index} className="flex items-center gap-5">
                      <div className="flex items-center justify-center w-5 h-5 bg-blue-100 dark:bg-blue-900 rounded-full">
                        <FontAwesomeIcon icon={faCheck} className="text-primary text-sm" />
                      </div>
                      <span className="dark:text-white">{featureItem}</span>
                    </li>
                  ))}
                  {/* 
                  {item.isGuarantee ? (
                    <>
                      <div className="font-medium text-accent pt-5">Guarantees</div>
                      <li className="items-center gap-5 ">
                        <FontAwesomeIcon icon={faSmile} className="text-accent mr-3 " />
                        <span className="dark:text-white">
                          {" "}
                          <b>30 days</b> No questions ask money back
                        </span>
                      </li>
                      <li className="items-center gap-5 ">
                        <FontAwesomeIcon icon={faShield} className="text-accent mr-3" />{" "}
                        <span className="dark:text-white">
                          <b>Performance:</b> Beat the SP500 or get free access.
                        </span>
                      </li>
                    </>
                  ) : (
                    ""
                  )} */}
                  {item.isBonus ? (
                    <>
                      <div className="font-medium text-secondary pt-5">Bonus</div>
                      <li className="items-center gap-5 ">
                        <FontAwesomeIcon icon={faGift} className="text-secondary mr-3" />{" "}
                        <span className="dark:text-white">
                          <b>eBook:</b> 51 Tips for Investing in Stocks
                        </span>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                </ul>
                <div className="flex-1 flex items-end">
                  {item.price === 0 && hasSubscription && <button className="px-3 py-3 rounded-lg w-full btn btn-primary">Start</button>}
                  {item.price === 0 && !hasSubscription && <button className="px-3 py-3 rounded-lg w-full btn btn-primary">Current Plan</button>}
                  {item.realPlan && user && (
                    <Suspense fallback={<button className="px-3 py-3 rounded-lg w-full btn btn-primary">Start Now !</button>}>
                      <LazyButtonStripe onLoading={setLoading} />
                    </Suspense>
                  )}
                  {item.realPlan && user === null && (
                    <Link className="px-3 py-3 rounded-lg w-full btn btn-primary" to={`${process.env.PUBLIC_URL}/signup`}>
                      Create Account
                    </Link>
                  )}
                  {item.price === "Custom" && <button className="px-3 py-3 rounded-lg w-full btn btn-primary">Contact Us</button>}
                </div>
              </div>
            ))}
          </div>
          <EBook />
        </div>
      </div>
      <CTASection />
      <FAQ1 />
    </>
  );
};

export default Pricing;
