import React from "react";

import Signup from "../routes/Authentification/Signup/signup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const PopUpCreateAccount = ({ onClose }) => {
  // Il faut utiliser onClose pour fermer le composants : traiter à un niveau au dessus par App.js

  // const handleRedirectionSignUp = () => {
  //   onClose();
  //   navigate(`${process.env.PUBLIC_URL}/signup`);
  // };

  return (
    <div className="  fixed z-20 backdrop-blur-sm m-auto inset-x-0 inset-y-0 p-4 rounded-sm ">
      <div
        onClick={onClose}
        className="rounded-full text-2xl absolute right-7 top-7 md:right-20 md:top-10 cursor-pointer flex justify-center items-center h-10 w-10 bg-base-100 "
      >
        <FontAwesomeIcon icon={faClose} />
      </div>
      <Signup />
    </div>
  );
};

export default PopUpCreateAccount;
