import React from "react";

const ContactPage = () => {
  return (
    <div>
      <h1>Contact Us:</h1>

      <p>
        Our team is reachable at the address <span className="link-content">contact@guruscreener.io</span> or on the social media.
      </p>
    </div>
  );
};

export default ContactPage;
