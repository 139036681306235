import React from "react";
import { MathJaxContext, MathJax } from "better-react-mathjax";

const GrahamEnterprising = () => {
  return (
    <MathJaxContext>
      <div className="screener-article">
        <h3>5 Essential Rules to Master Graham’s Enterprising Investment : The Complete Guide</h3>
        <p>
          When it comes to the world of investment, the name Benjamin Graham is widely recognized, particularly through the endorsement of Warren
          Buffet. Even though Graham’s books were written decades ago, they continue to serve as a valuable and insightful guide for selecting stocks.
          In his writings, Graham outlined an algorithm consisting of eight key points that can be utilized to identify what he referred to as
          “Defensive” Stocks.
        </p>
        <p>
          According to Graham, defensive stocks are considered to be the highest quality stocks and are strongly recommended by him. On the other
          hand, stocks with greater risks are classified as “Enterprising.” In order to qualify as a defensive stock, certain requirements need to be
          met.
        </p>

        <p>
          <b>These requirements include the following:</b>
        </p>

        <ul className="mb-5">
          {/* <li>1. Not less than USD 100 million of annual sales. (Corrected to USD 500M with inflation)</li> */}
          <li>1-A. Current assets should be at least 1.5 current liabilities.</li>
          <li>1-B. Long-term debt should not exceed 110% of net current assets.</li>
          <li>2. Earnings stability: No deficit in the last five years covered in the Stock Guide..</li>
          <li>3. Dividend record: Some current dividend.</li>
          <li>4. Price: Less than 120% net tangible assets - Serenity Number</li>
        </ul>

        <h4 className="text-primary font-semibold">Graham’s Rule N°1 : Asset/Liabilities</h4>
        <p>
          Rule number 2 is closely connected to the Book Value, which determines whether the balance favors Assets or Liabilities. This rule has been
          formulated in two different ways, depending on the sector.
        </p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">
            {"\\( \\text{Rule 1A}=\\frac{\\text{Current Assets}}{2*\\text{Current Liabilities}} >0.75\\)"}
          </MathJax>{" "}
        </p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">
            {"\\( \\text{Rule 1B}=\\frac{\\text{Net Current Assets}}{\\text{Long-Term Debt}}>0.9 \\)"}
          </MathJax>{" "}
        </p>
        {/* <p>Example for Apple (AAPL) : </p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
        <MathJax className="mb-5 mt-5 text-primary ml-5">{"\\( \\text{Size in Sales}=\\frac{\\text{Total Revenu}}{\\text{500M}} \\)"}</MathJax> </p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
        <MathJax className="mb-5 mt-5 text-primary ml-5">{"\\( \\text{Size in Sales}=\\frac{\\text{Total Revenu}}{\\text{500M}} \\)"}</MathJax> </p>
        */}

        <p>The alternative for Utilities and Financials is : </p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">{"\\( \\text{Rule 1B}=\\frac{2*\\text{Equity}}{\\text{Debt}}>0.9 \\)"}</MathJax>{" "}
        </p>
        <h4 className="text-primary font-semibold">Graham’s Rule N°2 : Earning Stability</h4>
        <p>
          When it comes to Defensive companies, Graham suggests that they should have a track record of consistently making profits over the last ten
          years. This means that they've managed to maintain a positive earnings trend over a significant period of time.
        </p>

        <p>
          By adhering to this criterion, Defensive companies demonstrate their ability to weather economic storms and remain stable in terms of
          financial performance. It's like having a strong shield against market uncertainties.
        </p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">
            {"\\( \\text{Earning Stability}=\\text{10%}*\\text{Continuous Years of Positive Earning} >\\text{50%}\\)"}
          </MathJax>{" "}
        </p>
        {/* <p>Example for Apple (AAPL) : For the last 12 years, Apple show a positive earning, so well that : </p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
        <MathJax className="mb-5 mt-5 text-primary ml-5">{"\\( \\text{Size in Sales}=\\frac{\\text{Total Revenu}}{\\text{500M}} \\)"}</MathJax> </p> */}

        <h4 className="text-primary font-semibold">Graham’s Rule N°3 : Dividend Record</h4>
        <p>
          Just like Rule N°3, another important criterion for a defensive company is its consistent payment of dividends over a period of at least 20
          years. This means that year after year, the company has been sharing its profits with its shareholders through dividend payments.
        </p>
        <p>
          By fulfilling this requirement, a defensive company showcases its commitment to providing a steady income stream to its investors. It
          demonstrates financial stability and reliability over an extended period of time.
        </p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">
            {"\\( \\text{Dividend Record}=\\text{5%}*\\text{Continuous Years of Paid Dividend} >\\text{5%}\\)"}
          </MathJax>{" "}
        </p>
        {/* <p>Example for Apple (AAPL) : For the last 11 years, Apple has paid dividends.</p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
        <MathJax className="mb-5 mt-5 text-primary ml-5">{"\\( \\text{Size in Sales}=\\frac{\\text{Total Revenu}}{\\text{500M}} \\)"}</MathJax> </p> */}

        <h4 className="text-primary font-semibold">Graham’s Rule n°5: The Serenity Number</h4>
        <p>
          In addition to the criteria of financial stability and growth, Graham also emphasized the importance of considering the price of a company.
          He believed that a comprehensive analysis must take into account not only the company's fundamentals but also its valuation.
        </p>

        <p>
          Graham's quantitative criteria for Enterprising investment are the lower of 120% of Tangible Book Value Per Share (TBVPS), or a
          Price-to-Earnings (P/E) ratio of 10. With a derivation similar to the Graham Number, we get the following Intrinsic Value calculation.
        </p>

        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">{"\\( \\text{Serenity Number}=\\sqrt{12*\\text{EPS}*\\text{TBVPS}} \\)"}</MathJax>{" "}
        </p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
          <MathJax className="mb-5 mt-5 text-primary ml-5">
            {"\\( \\text{Serenity Number (%)}=\\frac{\\text{Serenity Number}}{\\text{Stock Price}} \\)"}
          </MathJax>{" "}
        </p>

        {/* <p>For Apple (AAPL), the Graham Number is the following : </p>

<p className="text-xl mb-10 pb-3 overflow-x-auto w-full">        
<MathJax className="mb-5 mt-5 text-primary ml-5">{"\\( \\text{Size in Sales}=\\frac{\\text{Total Revenu}}{\\text{500M}} \\)"}</MathJax> </p>
        <p className="text-xl mb-10 pb-3 overflow-x-auto w-full">
        <MathJax className="mb-5 mt-5 text-primary ml-5">{"\\( \\text{Size in Sales}=\\frac{\\text{Total Revenu}}{\\text{500M}} \\)"}</MathJax> </p> */}

        <p>
          The Graham Number holds an intriguing significance. It can be defined as the intrinsic value of a company, determined by considering both
          its book value (assets) and its earnings. In the case of Apple, Graham indicates an intrinsic value of 18.91 USD. If the stock price exceeds
          this value, it suggests the company may be overvalued
        </p>
      </div>
    </MathJaxContext>
  );
};

export default GrahamEnterprising;
