import React from "react";
import image1 from "../../../assets/image/customer/1.png";
import image2 from "../../../assets/image/customer/2.png";
import image3 from "../../../assets/image/customer/3.png";
import image4 from "../../../assets/image/customer/4.png";
import image5 from "../../../assets/image/customer/5.png";
import image7 from "../../../assets/image/customer/7.png";

const Testimonials = () => {
  const CardTest = ({ name, job, image, text }) => {
    return (
      <div className="bg-base-100 dark:bg-slate-800 rounded-lg px-5 md:px-6 py-5 md:py-8 md:mx-5 mb-10 mt-5 ring-1 ring-slate-900/5 dark:ring-slate-900/60 shadow-xl shadow-slate-300/10 dark:shadow-xl ">
        <div className="flex gap-4">
          <img className="w-12 h-12 rounded-full" src={image} alt="" />
          <div>
            <h6 className="text-lg font-medium ">{name}</h6>
            <p className="text-sm">{job} </p>
          </div>
        </div>
        <p className="mt-8">{text}</p>
      </div>
    );
  };

  const testimonials = [
    {
      name: "Thomas M",
      job: "Owner of a blog on Financial Independence ",
      image: image2,
      text: `After using several stock screening programs, GuruScreener.io is a cut above the rest! I feel more confident about investing. But the true measure of any investment tool is its ability to deliver profitable results.`,
    },
    {
      name: `Rodrigo A.`,
      job: "Maker @ Fineo",
      image: "https://pbs.twimg.com/profile_images/1372441824560771075/DTcuXT0Z_400x400.jpg",
      text: `It's not just about making decisions; it's about making the right decisions at the right time, and GuruScreener.io has played an important role in achieving that goal. It's not just a tool; it's a ticket to unlock legends' strategies. Trust me, you won't want to miss out on this journey.`,
    },
    {
      name: `Adam N.`,
      job: `Investor`,
      image: image5,
      text: `Excellent! GuruScreener.io has transformed the way I approach investing. If you're serious about making smarter moves in the stock
      market, this is the tool for you. Don't just invest; invest wisely with GuruScreener.io!`,
    },
    {
      name: `Fannie S.`,
      job: `+15 years SP500 Investor `,
      image: image7,
      text: ` GuruScreener.io addresses the core challenge faced by investors – the overwhelming task of screening stocks. No longer do you need to
      spend endless hours deciphering market trends; GuruScreener.io brings the strategies of investment legends to your fingertips.`,
    },
    {
      name: `Alex D.`,
      job: `Private Investor`,
      image: image3,
      text: `It simplifies stock screening with strategies inspired by Wall Street Legends. It may not revolutionize the process, but it does offer
      a straightforward approach for those looking to navigate the market.`,
    },
    {
      name: `@rowenbab`,
      job: `Influencer FIRE`,
      image: image4,
      text: `Investors looking for a no-frills option, without suffering through the complexities of financial tools. GuruScreener.io
                provides a solution without pain.`,
    },
  ];

  const testimonials2 = [
    {
      name: "Thomas M",
      job: "Owner of a blog on Financial Independence ",
      image: image2,
      text: `Having used various stock screeners, GuruScreener.io stands head and shoulders above the rest. It's like having a personal mentor
      whispering the strategies of the legends in your ear. My investment decisions have become more precise, informed, and, most
      importantly, profitable. But the true measure of any investment tool lies in its ability to yield profitable outcomes. In this regard,
      GuruScreener.io has surpassed my expectations. The informed decisions facilitated by this
      platform have translated into tangible financial gains.`,
    },
  ];

  return (
    <div className="py-16">
      <div className="container m-auto px-6 md:px-12 xl:px-6">
        <div className="mb-20 space-y-4 px-6 md:px-0">
          <h2 className="text-center text-2xl font-bold md:text-4xl">We have some fans.</h2>
        </div>
        <div className="md:columns-2 lg:columns-3 gap-8 space-y-8">
          {testimonials.map((e, index) => {
            return <CardTest key={index} name={e.name} job={e.job} image={e.image} text={e.text} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
