import React from "react";
import { Link } from "react-router-dom";

const BuffetologyHagstromArticle = () => {
  return (
    <div className="screener-article">
      <h3> Robert Hagstrom's Books on Understanding Warren Buffett Investing</h3>
      <p>
        Each share of a company's common stock represents direct ownership of a company. Ownership of even a single share entitles the holder to a
        fractional share of the company's net assets, which hopefully will generate a reasonable flow of earnings and dividends. Shareholders
        typically do not run the company directly, but rather elect a board of directors that selects the management team that actually runs the
        business.
      </p>
      <p>
        Although most novice investors understand the basic principles of corporate ownership, the day-to-day activity of the stock market tends to
        attract attention and there is a tendency to think that a company succeeds or fails based on the movement of its stock price. While periods of
        optimism and pessimism about the market, the industry, or even the company itself can have an impact on the company's stock price, over the
        long term, the stock price will more likely follow the economic value of the company.
      </p>
      <p>
        A fundamental approach to investing begins with understanding the value of a company. Then, you determine whether the current share price
        represents an attractive buying opportunity.
      </p>

      <h4>The spirit of Warren Buffet: </h4>
      <p>
        Buffett believes that speculators focus primarily on a company's share price, while investors focus on how the business operates. Buffett is a
        firm believer that knowledge is the key to increasing investment returns and reducing risk.{" "}
      </p>
      <p>
        It is also important to control your emotions. We should not let our emotions override our good judgment. Each person has to take into account
        his or her own psychology. Some losses are inevitable in investing. If you cannot emotionally handle the volatility, you should consider a
        more conservative investment style.
      </p>
      <p>Buy Great Companies, Not Great Stocks, is a way to sum up all of this thinking.</p>

      <h4>The rules to Buy Great Companies, Not Great Stocks</h4>
      <p>
        To find these great stocks, Hagstrom outlines 12 rules to follow in his book. Each rule is complementary and acts as a filter in the vast
        world of investment opportunities.
      </p>

      <h5 className="text-primary font-semibold">Rule Number 1 : Understand what you invest in</h5>
      <p>
        Knowledge is the key to investment return and risk reduction. Buffett warns that if you buy a company for superficial reasons, the tendency is
        to dump the stock at the first sign of weakness. Investors must be able to understand company factors like cash flow, labor, pricing, capital
        requirements, revenue growth and cost control.
      </p>
      <p className="font-semibold ml-10">Knowledge brings confidence : understand the product the company sells </p>
      <h5 className="text-primary font-semibold">Rule Number 2 : Does the company have a stable and steady operating history?</h5>
      <p>
        Buffett avoids companies that are either trying to solve difficult business problems or that are fundamentally changing their direction
        because their previous plans have been unsuccessful. <span className="font-semibold">Turnarounds rarely succeed.</span> In Buffett's view, the
        best returns are from companies that have been in business for years with the same product or service.
      </p>

      <p>While this principle is qualitative, we look for:</p>
      <p className="font-semibold ml-10">Positive operating income in each of the last 7 years </p>

      <h5 className="text-primary font-semibold">Rule Number 3 : Is the long-term business forecast promising?</h5>
      <p>
        Buffett believes the business world is divided into a small group of "franchises" and a large group of commodities. Companies with a franchise
        produce a good or service that is needed or desired, does not have a close substitute, and is not heavily regulated. Franchises should have a
        strong, sustainable competitive advantage that protects sales and profits from competing. Franchises have pricing flexibility. They can raise
        prices without fear of losing market share or volume. <span className="font-semibold">Strong franchises can survive a major failure.</span>{" "}
      </p>

      <p>
        It is important for the company to have a sustainable business advantage that acts as a barrier to make it difficult for others to compete.
        Ideally, it will discourage companies from even trying to compete.
      </p>

      <p>While this principle is qualitative, we look for:</p>
      <p className="font-semibold ml-10">
        Positive and above-market{" "}
        <Link className="link-content" to={`${process.env.PUBLIC_URL}/docs/return-on-equity`}>
          Return on Equity (ROE)
        </Link>{" "}
      </p>
      <h5 className="text-primary font-semibold">Rule Number 4 : Is the management team rational?</h5>
      <p>
        When looking at a company, Buffett evaluates managers for their rationality and independent thinking. Companies need to be run by honest,
        competent people.
      </p>
      <p>
        Hagstrom believes that the most important management action is the allocation of a company's capital. The effective use and reinvestment of a
        company's cash flow ultimately determines its growth and long-term value. This issue becomes critical as a company matures and begins to
        generate excess cash flow that cannot be reinvested in the primary business at a high rate of return.{" "}
      </p>
      <p>
        A company with excess cash flow and below-average investment returns can ignore the problem, try to buy growth, or return the cash to
        shareholders. Buffet prefers companies that use excess cash to buy back shares. The buybacks help support the stock price by increasing
        demand.{" "}
      </p>

      <h5 className="text-primary font-semibold">Rule Number 5 : Is the management team honest with its shareholders?</h5>
      <p>
        Buffett appreciates managers who fully disclose the company's performance, including both mistakes and successes. Buffett respects managers
        who report information beyond the requirements of generally accepted accounting principles (GAAP). Buffett looks for financial reports that
        enable the financially literate investor to determine the approximate value of a company, to determine the likelihood that a company will be
        able to meet its financial obligations, and to understand how well managers are running the business. A good example of Buffett's disclosure
        requirements is Berkshire Hathaway's financial reports.
      </p>
      <h5 className="text-primary font-semibold">Rule Number 6 : Does management think independently ? </h5>
      <p>
        Buffett looks for companies run by managers who are willing to think independently. Hagstrom identifies three factors that strongly influence
        how managers behave:
      </p>
      <ul className="list-disc ">
        <li className="ml-10 mb-5">Managers cannot control their desire to be active, which leads to harmful decisions such as acquisitions. </li>
        <li className="ml-10 mb-5">
          Managers tend to constantly compare their company's sales, profits, and compensation not only with real competitors, but also with companies
          far outside their industry. This contributes to "corporate hyperactivity.{" "}
        </li>
        <li className="ml-10 mb-5">Most managers exaggerate their own abilities.</li>
      </ul>
      <h5 className="text-primary font-semibold">Rule Number 7 : The focus is on return on equity rather than on earnings per share.</h5>
      <p>
        Buffett does not take quarterly or annual results too seriously when studying a company's financials. He prefers to focus on three- to
        five-year averages to get a sense of a company's financial strength.{" "}
      </p>

      <p>
        While Wall Street typically measures a company's performance by looking at its earnings per share, Buffett looks for a strong and consistent
        return on equity that is achieved without excessive leverage or accounting tricks.{" "}
      </p>

      <p>
        By increasing asset turnover, widening profit margins, or increasing financial leverage, companies can boost their return on equity. Buffett
        is not opposed to the use of debt - financial leverage. However, he cautions against excessive use of debt. Acceptable levels of debt vary
        from industry to industry
      </p>
      <p className="font-semibold ml-10">
        <Link className="link-content" to={`${process.env.PUBLIC_URL}/docs/return-on-equity`}>
          Return on Equity (ROE)
        </Link>{" "}
        greater than 15% for the trailing 4 quarters and each of the trailing 3 fiscal years.{" "}
      </p>
      <p className="font-semibold ml-10">Debt-to-Equity ratios to be below the industry average</p>
      <h5 className="text-primary font-semibold">Rule Number 8 : Uses "owner earnings" to judge performance</h5>
      <p>
        Buffett looks beyond earnings and even cash flow to measure company performance. Buffett judges performance by "owner earnings," which
        Hagstrom defines as:
      </p>
      <p className="font-semibold ml-10">
        {" "}
        Owner earnings <span className="text-secondary ">equal to</span> Net income <span className="text-secondary ">plus</span> non-cash charges for
        depreciation and amortization <span className="text-secondary ">less</span> capital expenditures <span className="text-secondary ">less</span>{" "}
        any additional working capital that may be required.
      </p>
      <p>This is similar to calculating free cash flow, which also subtracts dividend payments. Free cash flow is used to value the company.</p>
      <h5 className="text-primary font-semibold">Rule Number 9 : Look for companies with consistent and high profit margins. </h5>
      <p>
        Buffett seeks franchises that sell goods or services without effective competition, either because of a patent, brand name, or similar
        intangible that makes the product unique. These companies tend to have high profit margins due to their unique niche; however, simple screens
        for high profit margins may only highlight companies in industries that traditionally have high profit margins.{" "}
      </p>

      <p className="font-semibold ml-10">Operating and net profit margins above their industry norms. </p>
      <p>
        The operating margin looks at the costs directly associated with producing goods and services, while the net margin takes into account all of
        the company's activities and operations.{" "}
      </p>
      <p>Further research should examine the company's position in the industry and how it may change over time.</p>
      <h5 className="text-primary font-semibold">
        Rule Number 10 : For every dollar of retained earnings, the company should have created at least one dollar of market value.
      </h5>
      <p>
        The market's perception of companies with unproductive uses of earnings is reflected in their poor share price performance. Buffett believes
        that companies with good long-term prospects, run by shareholder-oriented managers, will attract market attention, resulting in a higher
        market price.
      </p>
      <p>
        {" "}
        We screen companies that requires at least a dollar-for-dollar increase in share price for every dollar added to retained earnings over the
        past 5 years.
      </p>

      <p className="font-semibold ml-10"> Free Cash Flow Growth (5years) greater than Share Price Growth (Adjusted Close)</p>

      <h5 className="text-primary font-semibold">Rule Number 11 : What is the company worth?</h5>
      <p>
        Even if you've identified a good company, it doesn't necessarily mean it's a good place to invest if it can't be purchased at a reasonable
        price.
      </p>
      <h5 className="text-primary font-semibold">Rule Number 12 : Buy stocks when they can be purchased at significant discounts.</h5>

      <p>
        Many investors turn to simple multiples, such as price-to-earnings multiples, to set a preliminary bar before conducting detailed analysis.
        Buffett likes to focus on free cash flow. Therefore, the price-to-free-cash-flow ratio is used in the screen.{" "}
      </p>
      <p className="font-semibold ml-10">The lower the price-to-free-cash-flow ratio, the better. </p>
      <p>
        However, a faster growing company deserves to trade at a higher multiple than a slower growing one. The price-to-free-cash-flow ratio was
        divided by the free-cash-flow growth rate to adjust for different growth rates. The companies with the lowest ratios of the free cash flow
        multiple to the growth rate ratio are presented in the table of passing companies.{" "}
      </p>
      <h5>Ressources</h5>
      <ul className="list-disc">
        <li>"The Warren Buffett Way" by Robert Hagstrom</li>
        <li>"The Essential Buffett: Timeless Principles for the New Economy" by Robert Hagstrom</li>
      </ul>
    </div>
  );
};

export default BuffetologyHagstromArticle;
