import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faBook, faChartLine, faFileExcel, faStar } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import feature9 from "../../../assets/image/hero1.png";
import heroCustomer1 from "../../../assets/image/customer/hero_customer_1.webp";
import heroCustomer2 from "../../../assets/image/customer/hero_customer_2.webp";
import heroCustomer3 from "../../../assets/image/customer/hero_customer_3.webp";
import heroCustomer4 from "../../../assets/image/customer/hero_customer_5.webp";
import heroCustomer5 from "../../../assets/image/customer/hero_customer_6.webp";

const HeroWelcome = () => {
  return (
    <>
      <div className="mx-auto pb-5  ">
        {/* <div className="hidden sm:mb-3 sm:flex sm:justify-center">
					<div className="border relative border-blue-200 border-whit/50 inline-flex items-center p-0.5 pl-2.5 rounded-full gap-x-2 border-white/40 dark:border-white/30 bg-gray-950/20 dark:bg-white/10 before:scale-y-110 before:absolute before:inset-x-4 before:-bottom-px before:bg-gradient-to-r before:from-transparent before:via-yellow-50 before:to-transparent before:h-px before:w-3/5">
						<span className="text-sm tracking-wide text-white">Your shortcut to make money in stocks</span>
						<span className="px-2 py-0.5 text-white text-xs font-semibold leading-5 tracking-wide bg-gray-950/20 dark:bg-white/20 border border-white/30 rounded-full">
							Starts Here
						</span>
					</div>
				</div> */}

        <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-center justify-center gap-16 lg:gap-20 lg:items-start px-8 py-5 lg:py-10">
          <div className="flex flex-col gap-10 lg:gap-14 items-center justify-center text-center lg:text-left lg:items-start">
            <h1 class="font-extrabold text-4xl md:text-6xl tracking-tight md:-mb-4 flex flex-col gap-3 items-center lg:items-start">
              <span class="whitespace-nowrap">Get winning stocks,</span>
              {/* Grow wealth with */}
              {/* Say goodbye to losses, */}
              {/* <span class="whitespace-nowrap">
								<span class="mr-3 sm:mr-4 md:mr-5"></span>
							</span> */}
              <span class="whitespace-nowrap relative ">
                <span class="relative mr-3 sm:mr-4 md:mr-5"> invest like</span>
                <span class=" relative ">
                  <span class="absolute bg-slate-500 dark:bg-slate-00 -left-2 -top-1 -bottom-1 -right-2 md:-left-3 md:top-2 md:-bottom-0 md:-right-3 -rotate-2"></span>
                  <span class="relative text-slate-100">the bests.</span>
                </span>
              </span>
            </h1>
            <p className="mt-5 text-xl text-base-content/70 leading-tight font-medium ">
              We process +10,000 pages of financial books and 40,000 financial statements, in easy-to-use{" "}
              <span className="text-base-content"> stock screeners</span>.
              {/* Get the power of +10,000 pages of financial books in one click to beat the market. */}
              <br />
              <br />
              Forget complicated<span className="text-base-content"> spreadsheets</span> and
              <span className="text-base-content"> hours of reading.</span>
            </p>

            <div className=" flex items-center justify-center gap-x-6">
              <Link
                to={`${process.env.PUBLIC_URL}/screener`}
                className="text-base-100 btn btn-primary hover:shadow-lg hover:shadow-primary/40 lg:btn-wide group"
              >
                <FontAwesomeIcon icon={faChartLine} /> Get strategies
              </Link>
              {/* <Link to={`${process.env.PUBLIC_URL}/docs`} className="btn btn-outline">
								Learn more <span aria-hidden="true">→</span>
							</Link> */}
            </div>
            <div className="flex flex-col md:flex-row justify-center align-center gap-3">
              <div className="avatar-group -space-x-6  justify-center">
                <div className="avatar">
                  <div className="w-12">
                    <img src={heroCustomer1} alt="customer" />
                  </div>
                </div>
                <div className="avatar">
                  <div className="w-12">
                    <img src={heroCustomer2} alt="customer" />
                  </div>
                </div>
                <div className="avatar">
                  <div className="w-12">
                    <img src={heroCustomer3} alt="customer" />
                  </div>
                </div>
                <div className="avatar">
                  <div className="w-12">
                    <img src={heroCustomer4} alt="customer" />
                  </div>
                </div>
                <div className="avatar">
                  <div className="w-12">
                    <img src={heroCustomer5} alt="customer" />
                  </div>
                </div>
              </div>

              <div className="flex flex-col justify-center items-center md:items-start gap-1">
                <div class="rating items-center">
                  <FontAwesomeIcon icon={faStar} className="text-orange-400"></FontAwesomeIcon>
                  <FontAwesomeIcon icon={faStar} className="text-orange-400"></FontAwesomeIcon>
                  <FontAwesomeIcon icon={faStar} className="text-orange-400"></FontAwesomeIcon>
                  <FontAwesomeIcon icon={faStar} className="text-orange-400"></FontAwesomeIcon>
                  <FontAwesomeIcon icon={faStar} className="text-orange-400"></FontAwesomeIcon>
                  <span className="font-semibold ml-2">4,8</span>/5
                </div>
                <div class="text-base text-base-content/80">
                  <span class="font-semibold text-base-content">Trusted</span> by hundreds of investors
                </div>
              </div>
            </div>

            {/* <div class="text-sm md:text-base flex justify-center items-center gap-2 md:text-sm">
							<span class="text-accent">$100 off</span> for the first 2970 customers (11 left)
						</div> */}
          </div>

          {/* image à droite */}

          <div className="relative max-md:-m-4 lg:w-full ">
            <img
              alt="Get winning stocks, invest like The Intelligent Investor or CANSLIM"
              width="1080"
              height="1080"
              className=" w-full max-w-xl ml-auto"
              src={feature9}
            />
          </div>
        </div>

        <div className="flex flex-col  items-center font-semibold text-xl mt-5 md:mt-10">
          <div className="text-base-content/70">Featured on</div>
          <div className="flex flex-col md:flex-row md:space-x-16 items-center justify-center pt-5 max-md:space-y-10">
            <div className="grayscale opacity-50 hover:grayscale-0 hover:opacity-100 transition-all duration-300 w-32 max-md:mt-5  ">
              <img className="" src="https://upload.wikimedia.org/wikipedia/commons/8/89/Product_Hunt_Logo.svg" />
            </div>
            <div className="flex grayscale opacity-50 hover:grayscale-0 hover:opacity-100 transition-all duration-300 w-20">
              <img className="" src="https://upload.wikimedia.org/wikipedia/commons/2/2f/Reddit_wordmark.svg" />
            </div>
            <div className="grayscale opacity-50 hover:grayscale-0 hover:opacity-100 transition-all duration-300 w-32 ">
              <img className="" src="https://upload.wikimedia.org/wikipedia/commons/1/16/Y-combinator-logo.gif" />
            </div>
          </div>
        </div>

        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight sm:text-5xl">
            {/* <div className="slidingVertical h-[80px] sm:h-[70px] flex justify-center  ">
							<span className=" from-secondary to-primary bg-gradient-to-r bg-clip-text text-transparent h-[105px]  sm:h-[70px]">
								The Intelligent Investor
							</span>
							<span className="  from-secondary to-primary bg-gradient-to-r bg-clip-text text-transparent h-[105px] sm:h-[70px] ">Magic Formula</span>
							<span className=" from-secondary to-primary bg-gradient-to-r bg-clip-text text-transparent h-[105px]  sm:h-[70px]">CANSLIM</span>
							<span className=" from-secondary to-primary bg-gradient-to-r bg-clip-text text-transparent h-[105px]  sm:h-[70px]">Tiny Titans</span>
							<span className=" from-secondary to-primary bg-gradient-to-r bg-clip-text text-transparent h-[105px]  sm:h-[70px]">
								The Warren Buffet Way
							</span>
						</div> */}
          </h1>
        </div>
      </div>
    </>
  );
};

export default HeroWelcome;
