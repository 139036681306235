import React from "react";
import Card from "../../../common/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faChartBar, faDownLong, faGaugeMed, faMoneyBill, faUpLong } from "@fortawesome/free-solid-svg-icons";
const WinAndLossPosition = () => {
	return (
		<div className="">
			<div className="grid grid-cols-4 place-items-center">
				<div className="col-span-1 bg-gray-100 w-10 h-10 rounded-lg text-lg flex justify-center items-center">
					<FontAwesomeIcon icon={faMoneyBill} className="">
						{" "}
					</FontAwesomeIcon>{" "}
				</div>
				<div className="col-span-3">
					<h4 className="pb-2 font-medium tracking-tight"> Total Return</h4>
					<div className="text-accent">+ 150%</div>
				</div>
			</div>
			<div className="grid grid-cols-4 place-items-center">
				<div className="col-span-1 bg-gray-100 w-10 h-10 rounded-lg text-lg flex justify-center items-center">
					<FontAwesomeIcon icon={faArrowDown} className="">
						{" "}
					</FontAwesomeIcon>{" "}
				</div>
				<div className="col-span-3">
					<h4 className="pb-2 font-medium tracking-tight"> Max Drawdown</h4>
					<div>+ 150%</div>
				</div>
			</div>

			{/* <div>
				<h4 className="pb-2 font-medium tracking-tight">Winner VS Loser</h4>
				<div className="grid grid-cols-2 ">
					<div className=" mx-auto">
						<div className="flex flex-row ">
							<div className="text-secondary text-3xl mx-1">10</div>
							<div className="text-secondary text-3xl mx-1">
								<FontAwesomeIcon icon={faDownLong} />
							</div>
						</div>
					</div>
					<div className=" mx-auto">
						<div className="flex flex-row ">
							<div className="text-primary text-3xl mx-1">
								<FontAwesomeIcon icon={faUpLong} />
							</div>
							<div className="text-primary text-3xl mx-1">30</div>
						</div>
					</div>
				</div>
			</div> */}

			{/* <div>
				<h4 className="pb-2 font-medium tracking-tight">
					{" "}
					<FontAwesomeIcon icon={faChartBar}> </FontAwesomeIcon> Perf. per year
				</h4>
				<div>Mettre un graphique</div>
			</div>

			<div>
				<h4 className="pb-2 font-medium tracking-tight">
					<FontAwesomeIcon icon={faGaugeMed}> </FontAwesomeIcon> Average perf. per
				</h4>
				<div>+ 150%</div>
			</div> */}
		</div>
	);
};

export default WinAndLossPosition;
