import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const RadarSymbol = ({ data }) => {
  const [globalNote, setGlobalNote] = useState(0);

  useEffect(() => {
    let note = 0;
    ["rank_profitability", "rank_gf_value", "rank_balancesheet", "rank_growth", "rank_momentum"].map((e) => {
      const value = data[e];
      note = note + value * 2;
      console.log(note);
      return value;
    });

    setGlobalNote(note);
  }, [data]);

  const state = {
    series: [
      {
        name: "Value",
        data: ["rank_profitability", "rank_gf_value", "rank_balancesheet", "rank_growth", "rank_momentum"].map((e) => {
          const value = data[e];
          return value;
        }),
      },
    ],
    options: {
      tooltip: {
        theme: true,
        x: {
          format: "dd/MM/yy HH:mm",
        },

        y: {
          formatter: function (value) {
            return value.toFixed(0) + "/10";
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      chart: {
        height: 400,
        type: "radar",
        toolbar: { show: false },
      },

      yaxis: {
        stepSize: 2,
        min: -2,
        max: 10,
        show: false,
      },
      xaxis: {
        categories: ["Profitability", "Valuation", "Earning", "Growth", "Momentum"],
      },
    },
  };
  // https://guruscreener.io/api/indicators?symbol=MSFT&indicators[0]=rank_profitability&indicators[1]=rank_gf_value
  return (
    <div className="text-center">
      Global Score : {globalNote}/100
      <div id="chart">
        <ReactApexChart options={state.options} series={state.series} type="radar" height={400} />
      </div>
    </div>
  );
};

export default RadarSymbol;
