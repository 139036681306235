import { dataScreener } from "../data/dataScreenerV2";

export const getPerformancesAndOther = (screenerName) => {
  const offset = 100;
  const data = dataScreener[screenerName].performances;
  const valueList = Object.keys(dataScreener).map((element) => {
    return dataScreener[element].performances["10y"];
  });
  const minValue = Math.min(...valueList);
  const maxValue = Math.max(...valueList);
  const valuePerformance = ((data["10y"] - minValue + offset) / (maxValue - minValue + offset)) * 100;

  return { performance: valuePerformance, risk: data["risk"], holding: data["holding"] };
};
