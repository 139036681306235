import React, { useContext, useEffect, useState } from "react";
import { dataScreener } from "./data/dataScreenerV2";
import { Helmet } from "react-helmet";
import Faq from "../../components/FaqLanding";

import { UserContext } from "../../context/usercontext";
import { useFavorites } from "./../../react-query/hooks/useFavorites";
import CardScreenerAlt from "../../common/CardScreenerAlt";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import CardScreener from "../../common/CardScreener";

const Separator = ({ title, display }) => {
  return (
    <div className="flex flex-col w-full px-10 mb-10">
      <div className="text-2xl font-semibold pb-1 cursor-pointer">
        {display ? <FontAwesomeIcon className="pr-5" icon={faChevronDown} /> : <FontAwesomeIcon className="pr-5" icon={faChevronUp} />}
        <span>{title}</span>
      </div>
      <div className="border border-bottom border-solid  m-0"></div>
    </div>
  );
};

const ScreenerPageHomeAlt = () => {
  const [screenerByCategorie, setScreenerByCategorie] = useState([
    { title: "Favorites", screener: [] },
    { title: "Guru Strategies", screener: [] },
    { title: "Guru Strategies (on eToro)", screener: [] },
    { title: "Guru Strategies (on RobinHood)", screener: [] },
    { title: "People", screener: [] },
  ]);

  const { user } = useContext(UserContext);
  const queryFavorites = useFavorites(user);

  useEffect(() => {
    addCategorisationScreener();
  }, [queryFavorites.isPending]);

  const addCategorisationScreener = () => {
    let screenerPeople = [];
    let screenerGuru = [];
    let screenerGuru_etoro = [];
    let screenerGuru_robinhood = [];
    let screenerFavorites = [];
    let favorites = [];

    if (!queryFavorites.isPending) {
      if (queryFavorites?.data) {
        favorites = queryFavorites.data;
      }
    }
    Object.keys(dataScreener).forEach((key) => {
      if (favorites.includes(key)) {
        screenerFavorites.push(<CardScreener key={key} screenerName={key} favorites={queryFavorites} />);
      }

      if (dataScreener[key].typeOfScreener === "people") {
        screenerPeople.push(<CardScreener key={key} screenerName={key} favorites={queryFavorites} />);
      }
      if (dataScreener[key].typeOfScreener === "book") {
        if (key.includes("etoro")) {
          screenerGuru_etoro.push(<CardScreener key={key} screenerName={key} favorites={queryFavorites} />);
        }
        if (key.includes("robinhood")) {
          screenerGuru_robinhood.push(<CardScreener key={key} screenerName={key} favorites={queryFavorites} />);
        }
        if (!key.includes("robinhood") && !key.includes("etoro")) {
          screenerGuru.push(<CardScreener key={key} screenerName={key} favorites={queryFavorites} />);
        }
      }
    });

    setScreenerByCategorie([
      { id: 0, title: "Favorites", screener: screenerFavorites, display: false },
      { id: 1, title: "Guru Strategies", screener: screenerGuru, display: true },
      { id: 2, title: "Guru Strategies (on eToro)", screener: screenerGuru_etoro, display: false },
      { id: 3, title: "Guru Strategies (on RobinHood)", screener: screenerGuru_robinhood, display: false },
      { id: 4, title: "Famous Portfolio", screener: screenerPeople, display: false },
    ]);
  };

  const handleClickDisplaySeparator = (id) => {
    console.log(id);
    const tmp = screenerByCategorie.map((e) => {
      if (e.id === id) {
        e.display = !e.display;
      }
      return e;
    });
    setScreenerByCategorie(tmp);
  };
  return (
    <>
      {/* SEO Optimisation  */}
      <Helmet>
        <title>{`Discover the list of screener on GuruScreener.io`}</title>‍
        <meta name="description" content={`Discover the list of screener on GuruScreener.io`} />
        <meta property="og:title" content={`Discover the list of screener on GuruScreener.io`} />
        <meta property="og:description" content={`Discover the list of screener on GuruScreener.io`} />
        <meta property="og:image" content="url_to_image" />
        <meta property="og:url" content="guruscreener.io" />
        <meta property="og:site_name" content="GuruScreener.io" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
      </Helmet>

      <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#4cd964] to-[#34aadc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>

      <div className="mx-auto max-w-2xl pb-20 pt-5  lg:pb-20 lg:pt-10">
        <div className="text-center">
          <h1 className="text-3xl font-bold tracking-tight sm:text-3xl">Discover Guru Screeners</h1>
          <p className="mt-6 text-xl leading-8 text-base-content">Don't take time to read complicated books, take time to invest!</p>
        </div>
      </div>

      {screenerByCategorie.map((categorie) => {
        return (
          <>
            <div onClick={() => handleClickDisplaySeparator(categorie.id)}>
              <Separator title={categorie.title} display={categorie.display} />
            </div>
            {categorie.display && (
              <div className="flex justify-center mt-10 ">
                {categorie.screener.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pb-6 gap-8 max-w-screen-xl auto-rows-auto">
                    {categorie.screener.map((e, index) => {
                      return e;
                    })}
                  </div>
                ) : (
                  <div>No Screener to display</div>
                )}
              </div>
            )}
          </>
        );
      })}

      <div className="py-16">
        <div className="container m-auto px-6 md:px-12 xl:px-6">
          <div className="mb-20 space-y-4 px-6 md:px-0">
            <h2 className="text-center text-2xl font-bold md:text-4xl">
              Need Information? <p />
              <Link to={`${process.env.PUBLIC_URL}/docs`} className="mt-5 text-center btn btn-primary">
                Check Documentation
              </Link>
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScreenerPageHomeAlt;
