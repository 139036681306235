import React from "react";
import { Helmet } from "react-helmet";
import ResultSimulation from "./components/ResultSimulation";
import BacktestChoice from "./components/BacktestChoice";
import TitleCard from "./../../common/TitleCard";

const BacktestingPage = () => {
  return (
    <>
      {/* SEO Optimisation  */}
      <Helmet>
        <title>{`Backtest Screener - GuruScreener`}</title>‍
        <meta name="description" content={`Backtest your custom strategy on all the US Market from 2000 to Today`} />
        <meta property="og:title" content={`GuruScreener | Backtest Screener`} />
        <meta property="og:description" content={`Backtest your custom strategy on all the US Market from 2000 to Today`} />
        <meta property="og:url" content={`https://guruscreener.io/}`} />
        <meta property="og:site_name" content="GuruScreener.io" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta name="twitter:title" content={`GuruScreener | Backtest Screener`} />
        <meta name="twitter:description" content={`Backtest your custom strategy on all the US Market from 2000 to Today`} />
      </Helmet>

      <div className="container">
        <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#4cd964] to-[#34aadc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl pb-20 pt-5  lg:pb-40">
          <div className="text-center">
            <h1 className="text-3xl font-bold tracking-tight sm:text-3xl">Backtesting Tool</h1>
            <p className="mt-6 text-xl leading-8 text-base-content">Forget about complicated Spreadsheets, backtest with us!</p>
          </div>
        </div>
        <div className="max-w-screen-xl mx-auto">
          <div role="tablist" className="tabs tabs-lifted">
            <input type="radio" name="my_tabs_2" role="tab" className="tab font-bold tracking-tight" aria-label="Setup" />
            <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
              <BacktestChoice />
            </div>

            <input type="radio" name="my_tabs_2" role="tab" className="tab font-bold tracking-tight" aria-label="Results" />
            <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6 ">
              <ResultSimulation />
            </div>

            <input type="radio" name="my_tabs_2" role="tab" className="tab font-bold tracking-tight" aria-label="Info" />
            <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
              Tab content 3
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BacktestingPage;
