// TradingViewWidget.jsx
import React, { useEffect, useRef, memo, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMultiply } from "@fortawesome/free-solid-svg-icons";

const studyAvailable = [
  { value: "STD;24h%Volume", name: "24-hour Volume" },
  { value: "STD;Accumulation_Distribution", name: "Accumulation/Distribution" },
  { value: "STD;Advance%1Decline%1Line", name: "Advance Decline Line" },
  { value: "STD;Advance%1Decline%1Ratio", name: "Advance Decline Ratio" },
  { value: "STD;Advance_Decline_Ratio_Bars", name: "Advance/Decline Ratio (Bars)" },
  { value: "STD;Arnaud%1Legoux%1Moving%1Average", name: "Arnaud Legoux Moving Average" },
  { value: "STD;Aroon", name: "Aroon" },
  { value: "STD;Average%Day%Range", name: "Average Day Range" },
  { value: "STD;Average%1Directional%1Index", name: "Average Directional Index" },
  { value: "STD;Average_True_Range", name: "Average True Range" },
  { value: "STD;Awesome_Oscillator", name: "Awesome Oscillator" },
  { value: "STD;Balance%1of%1Power", name: "Balance of Power" },
  { value: "STD;Bollinger_Bands", name: "Bollinger Bands" },
  { value: "STD;Bollinger_Bands_B", name: "Bollinger Bands %B" },
  { value: "STD;Bollinger_Bands_Width", name: "Bollinger Bands Width" },
  { value: "STD;Bull%Bear%Power", name: "Bull Bear Power" },
  { value: "STD;Chaikin_Money_Flow", name: "Chaikin Money Flow" },
  { value: "STD;Chaikin_Oscillator", name: "Chaikin Oscillator" },
  { value: "STD;Chande%1Kroll%1Stop", name: "Chande Kroll Stop" },
  { value: "STD;Chande_Momentum_Oscillator", name: "Chande Momentum Oscillator" },
  { value: "STD;Chop%1Zone", name: "Chop Zone" },
  { value: "STD;Choppiness_Index", name: "Choppiness Index" },
  { value: "STD;CCI", name: "Commodity Channel Index" },
  { value: "STD;Connors_RSI", name: "Connors RSI" },
  { value: "STD;Coppock%1Curve", name: "Coppock Curve" },
  { value: "CorrelationCoefficient@tv-basicstudies", name: "CC" },
  { value: "STD;Correlation_Coeff", name: "Correlation Coefficient" },
  { value: "STD;Cumulative%1Volume%1Index", name: "Cumulative Volume Index" },
  { value: "STD;DPO", name: "Detrended Price Oscillator" },
  { value: "STD;DMI", name: "Directional Movement Index" },
  { value: "STD;Donchian_Channels", name: "Donchian Channels" },
  { value: "STD;DEMA", name: "Double EMA" },
  { value: "STD;EOM", name: "Ease of Movement" },
  { value: "STD;EFI", name: "Elder Force Index" },
  { value: "STD;ENV", name: "Envelope" },
  { value: "STD;Fisher_Transform", name: "Fisher Transform" },
  { value: "STD;Gaps", name: "Gaps" },
  { value: "STD;Historical_Volatility", name: "Historical Volatility" },
  { value: "STD;Hull%1MA", name: "Hull Moving Average" },
  { value: "STD;Ichimoku%1Cloud", name: "Ichimoku Cloud" },
  { value: "STD;Keltner_Channels", name: "Keltner Channels" },
  { value: "STD;Klinger%1Oscillator", name: "Klinger Oscillator" },
  { value: "STD;Know_Sure_Thing", name: "Know Sure Thing" },
  { value: "STD;Least%1Squares%1Moving%1Average", name: "Least Squares Moving Average" },
  { value: "STD;Linear_Regression", name: "Linear Regression Channel" },
  { value: "STD;MA%1Cross", name: "MA Cross" },
  { value: "STD;Mass%1Index", name: "Mass Index" },
  { value: "STD;McGinley%1Dynamic", name: "McGinley Dynamic" },
  { value: "STD;Median", name: "Median" },
  { value: "STD;Momentum", name: "Momentum" },
  { value: "STD;Money_Flow", name: "Money Flow Index" },
  { value: "MoonPhases@tv-basicstudies", name: "MP" },
  { value: "STD;Moon%1Phases", name: "Moon Phases" },
  { value: "STD;MACD", name: "Moving Average Convergence Divergence" },
  { value: "STD;EMA", name: "Moving Average Exponential" },
  { value: "STD;MA%Ribbon", name: "Moving Average Ribbon" },
  { value: "STD;SMA", name: "Moving Average Simple" },
  { value: "STD;WMA", name: "Moving Average Weighted" },
  { value: "STD;Multi-Time%Period%Charts", name: "Multi-Time Period Charts" },
  { value: "STD;Net%1Volume", name: "Net Volume" },
  { value: "STD;On_Balance_Volume", name: "On Balance Volume" },
  { value: "STD;Open%Interest", name: "Open Interest" },
  { value: "STD;PSAR", name: "Parabolic SAR" },
  { value: "STD;Pivot%1Points%1High%1Low", name: "Pivot Points High Low" },
  { value: "STD;Pivot%1Points%1Standard", name: "Pivot Points Standard" },
  { value: "STD;Price_Oscillator", name: "Price Oscillator" },
  { value: "PriceVolumeTrend@tv-basicstudies", name: "PVT" },
  { value: "STD;Price_Volume_Trend", name: "Price Volume Trend" },
  { value: "STD;ROC", name: "Rate Of Change" },
  { value: "STD;RSI", name: "Relative Strength Index" },
  { value: "STD;Relative_Vigor_Index", name: "Relative Vigor Index" },
  { value: "STD;Relative_Volatility_Index", name: "Relative Volatility Index" },
  { value: "STD;Relative%1Volume%1at%1Time", name: "Relative Volume at Time" },
  { value: "BookerIntradayPivots@tv-basicstudies", name: "RB_IPP" },
  { value: "BookerKnoxvilleDivergence@tv-basicstudies", name: "RB_KnoxDiv" },
  { value: "BookerMissedPivots@tv-basicstudies", name: "RB_MPP" },
  { value: "BookerReversal@tv-basicstudies", name: "RB_Rev" },
  { value: "STD;Rob%1Booker%1Ghost%1Pivots%1v2", name: "Rob Booker - Ziv Ghost Pivots" },
  { value: "STD;Divergence%1Indicator", name: "RSI Divergence Indicator" },
  { value: "STD;Seasonality", name: "Seasonality" },
  { value: "STD;SMI_Ergodic_Indicator_Oscillator", name: "SMI Ergodic Indicator" },
  { value: "STD;SMI_Ergodic_Oscillator", name: "SMI Ergodic Oscillator" },
  { value: "STD;Smoothed%1Moving%1Average", name: "Smoothed Moving Average" },
  { value: "STD;Stochastic", name: "Stochastic" },
  { value: "STD;SMI", name: "Stochastic Momentum Index" },
  { value: "STD;Stochastic_RSI", name: "Stochastic RSI" },
  { value: "STD;Supertrend", name: "Supertrend" },
  { value: "STD;Technical%1Ratings", name: "Technical Ratings" },
  { value: "STD;Time%1Weighted%1Average%1Price", name: "Time Weighted Average Price" },
  { value: "STD;TEMA", name: "Triple EMA" },
  { value: "STD;TRIX", name: "TRIX" },
  { value: "STD;True%1Strength%1Indicator", name: "True Strength Index" },
  { value: "STD;Ultimate_Oscillator", name: "Ultimate Oscillator" },
  { value: "STD;UP_DOWN_Volume", name: "Up/Down Volume" },
  { value: "STD;Visible%1Average%1Price", name: "Visible Average Price" },
  { value: "STD;Volatility_Stop", name: "Volatility Stop" },
  { value: "Volume@tv-basicstudies", name: "Vol" },
  { value: "STD;Volume%1Oscillator", name: "Volume Oscillator" },
  { value: "STD;VWAP", name: "Volume Weighted Average Price" },
  { value: "STD;VWMA", name: "Volume Weighted Moving Average" },
  { value: "STD;Vortex%1Indicator", name: "Vortex Indicator" },
  { value: "STD;Williams_Alligator", name: "Williams Alligator" },
  { value: "STD;Whilliams_Fractals", name: "Williams Fractals" },
  { value: "STD;Willams_R", name: "Williams Percent Range" },
  { value: "STD;Woodies%1CCI", name: "Woodies CCI" },
  { value: "STD;Zig_Zag", name: "Zig Zag" },
];

function TradingViewWidget2({ symbol }) {
  const container = useRef();

  const [currentTheme, setCurrentTheme] = useState(localStorage.getItem("theme") ? localStorage.getItem("theme") : "light");
  const [studies, setStudies] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    window.addEventListener("theme", handleEvent, false);
  }, []);

  function handleEvent() {
    const localTheme = localStorage.getItem("theme");
    setCurrentTheme(localTheme);
    // Apply color to chart wia State
  }
  const isDarkMode = () => {
    return currentTheme === "dark";
  };

  const handleChangeSelectStudy = (e) => {
    const element = e.target.value;
    if (!studies.includes(element)) {
      setStudies((prev) => [...prev, element]);
    }
  };

  useEffect(() => {
    if (symbol) {
      const element = document.getElementById("myTradingViewScript");
      if (element) {
        element.remove();
      }
      const script = document.createElement("script");

      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      script.id = "myTradingViewScript";
      script.innerHTML = `
        {
          "autosize": true,
          "symbol": "${symbol}",
          "interval": "D",
          "timezone": "Etc/UTC",
          "theme": "${isDarkMode() ? "dark" : "light"}",
          "style": "1",
          "locale": "en",

          ${isDarkMode() ? `"backgroundColor": "rgba(30, 41, 59, 1)",` : ""}
          
          "enable_publishing": false,
          "hide_top_toolbar": true,
          "save_image": false,
          "withdateranges": true,
          "calendar": false,
          "studies": [
         ${studies.map((e) => `"${e}"`)}
          ],
          "support_host": "https://www.tradingview.com"
        }`;
      container.current.appendChild(script);
    }
  }, [symbol, currentTheme, studies]);

  if (symbol) {
    return (
      <div className={fullScreen ? "fixed z-20 m-auto inset-x-0 inset-y-0  " : " h-full "}>
        <div className="flex flex-row mb-5">
          <p className="text-secondary font-semibold  my-auto ">Add Indicator</p>
          <select className="select select-bordered w-full max-w-xs select-sm ml-10" onChange={handleChangeSelectStudy} defaultValue="">
            <option disabled value="">
              Choose Indicator
            </option>
            {studyAvailable.map((study) => {
              return (
                <option value={study.value} key={"_" + study.value}>
                  {study.name}
                </option>
              );
            })}
          </select>
        </div>
        <ul className="my-5">
          {studies.map((study) => {
            return (
              <li
                className="m-2 badge badge-outline font-semibold cursor-pointer hover:text-secondary"
                key={study}
                onClick={() => setStudies((prev) => prev.filter((e) => e !== study))}
              >
                {studyAvailable.filter((e) => e.value === study)[0].name}
                <FontAwesomeIcon className="ml-2  " icon={faMultiply} />
              </li>
            );
          })}
        </ul>

        <div className={fullScreen ? "h-screen w-screen bg-base-100 px-10 pt-28 pb-28" : "h-[500px] col-start-1 col-end-10"}>
          <div className="tradingview-widget-container " ref={container} style={{ height: "100%", width: "100%" }}>
            <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
          </div>

          {/* Map options selected  */}
        </div>
      </div>
    );
  }
}

export default memo(TradingViewWidget2);
