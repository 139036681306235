import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import ScrollToTop from "./utils/ScrollToTop";
import { useLocation } from "react-router-dom";
import SidebarDocumentation from "./routes/Docs/components/SidebarDocumentation";
import NavBarDoc from "./routes/Docs/components/NavBarDoc";
import { useContext, useEffect, useState } from "react";
import PopUpCreateAccount from "./components/PopUpCreateAccount";
import { UserContext } from "./context/usercontext";
// import Banners from "./components/Banners";

const App = (props) => {
  const [countNavigation, setCountNavigation] = useState(0);
  const [activePopUp, setActivePopUp] = useState(true);
  const currentPath = useLocation();
  const { user } = useContext(UserContext);

  const isDocumentation = currentPath.pathname.includes("/docs");
  const pageViewBeforePopUp = 4;

  useEffect(() => {
    if (activePopUp) {
      if (!user) {
        console.log(currentPath.pathname);
        if (currentPath.pathname == "/signup" || currentPath.pathname == "/login") {
          setCountNavigation(0);
        } else {
          setCountNavigation((prev) => prev + 1);
        }
      }
    }
  }, [currentPath]);

  const handleClose = () => {
    setActivePopUp(false);
  };

  if (!isDocumentation) {
    return (
      <div className="App ">
        <div className="flex flex-col min-h-screen">
          {/* <Banners /> */}
          <NavBar />
          {activePopUp && countNavigation > pageViewBeforePopUp ? <PopUpCreateAccount onClose={handleClose} /> : <></>}

          {/* <PopUpCreateAccount onClose={handleClose} /> */}
          <main className="flex-1 ">
            <div className="container mx-auto px-4 py-8 sm:py-12 lg:px-12">
              <div className="relative isolate ">{props.children}</div>
            </div>
          </main>
          <Footer />
        </div>
        <ScrollToTop />
        <ToastContainer />
      </div>
    );
  } else {
    // Version desktop
    return (
      <div>
        <div className="drawer lg:drawer-open">
          <input id="drawer" type="checkbox" className="drawer-toggle" />
          <div className="drawer-content">
            <NavBar />
            <NavBarDoc />

            <div className="max-w-[100vw] px-6 pb-16 xl:pr-2">
              <div className="flex flex-col-reverse justify-between gap-6 xl:flex-row">
                <div className="prose prose-sm md:prose-base w-full max-w-4xl flex-grow pt-10">{props.children}</div>
              </div>
            </div>
          </div>

          {/* // Sidebar Desktop */}
          <div className="drawer-side z-40">
            <label className="drawer-overlay" aria-label="Close menu"></label>
            <aside className="bg-base-100 min-h-screen w-80">
              <div className=" px-4">
                <SidebarDocumentation />
              </div>
            </aside>
          </div>
        </div>
      </div>
    );
  }
};

export default App;
