const badgeColor = (tag) => {
  if (tag === "yield") return "badge-warning capitalize ";
  if (tag === "value") return "badge-accent capitalize ";
  if (tag === "quality") return "badge-secondary capitalize ";
  if (tag === "momentum") return "badge text-blue1 capitalize ";
  if (tag === "growth") return "badge-error capitalize ";
  if (tag === "book") return "badge-primary capitalize";
  if (tag === "people") return "badge-primary capitalize";

  return "";
};

export default badgeColor;
