import React from "react";
import ToolTip from "../../../utils/ToolTip";
import { getPerformancesAndOther } from "../functions/utilsCalcRiskPerf";

const BarPerformance = ({ screenerName }) => {
  const { performance, risk, holding } = getPerformancesAndOther(screenerName);

  return (
    <div>
      <div className="divider"></div>
      <label className="form-control mt-1">
        <div className="flex ">
          <div className="label">
            <div className="label-text">Performance</div>{" "}
          </div>
          <ToolTip
            text={
              "Investment performance is the return on an investment portfolio.At each period when the portfolio is updated, we sell the stocks that are no longer in the list, and calculate the performance relative to the beginning. This method is based on Time-Weighted Returns. "
            }
          />
        </div>
        <progress className="progress progress-primary w-full" value={performance} max="100"></progress>
      </label>
      <label className="form-control mt-1">
        <div className="flex ">
          <div className="label">
            <div className="label-text">Risk</div>{" "}
          </div>
          <ToolTip
            text={
              "The standard deviation of a fund’s return divided by the standard deviation of return for the average fund. Example: a risk 30% indicates that it is 30% more volatile than typical fund in its category. "
            }
          />
        </div>

        <progress className="progress progress-secondary w-full" value={risk} max="100"></progress>
      </label>
      <label className="form-control mt-1 mb-1">
        <div className="flex ">
          <div className="label">
            <div className="label-text">Holding Time</div>{" "}
          </div>
          <ToolTip
            text={
              "This gives you an indication of how long you should hold the screener's shares. It's an indicator of how active you should be if you choose to invest according to this strategy."
            }
          />
        </div>
        <progress className="progress progress-accent w-full" value={holding} max="100"></progress>
      </label>
    </div>
  );
};

export default BarPerformance;
