import React from "react";
import { dataChart } from "./chart";

const Admintool = () => {
  const saveFirebase = () => {
    let data = [];

    data = dataChart.map((e) => {
      return { date: e.date, screen: e.value3, market: e.value4 };
    });
    const name = "buffettology_hagstrom";

    // db.collection("performances").doc(name).set({ data: data });
  };

  return (
    <div>
      <button className="btn" onClick={saveFirebase}>
        Add Data
      </button>
    </div>
  );
};

export default Admintool;
